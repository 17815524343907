/* global kendo $ */
const kendoSetLang = {
  ru: () => {
    /* Filter menu operator messages */
    if (kendo.ui.FilterCell) {
      kendo.ui.FilterCell.prototype.options.operators = $.extend(
        true,
        kendo.ui.FilterCell.prototype.options.operators,
        {
          date: {
            eq: 'равна',
            gte: 'после или равна',
            gt: 'после',
            lte: 'до или равна',
            lt: 'до',
            neq: 'не равна'
          },
          number: {
            eq: 'равно',
            gte: 'больше или равно',
            gt: 'больше',
            lte: 'меньше или равно',
            lt: 'меньше',
            neq: 'не равно'
          },
          string: {
            endswith: 'оканчивается на',
            eq: 'равно',
            neq: 'не равно',
            startswith: 'начинающимися на',
            contains: 'содержащими',
            doesnotcontain: 'не содержит'
          },
          enums: {
            eq: 'равно',
            neq: 'не равно'
          }
        }
      )
    }
    /* Filter menu operator messages */
    if (kendo.ui.FilterMenu) {
      kendo.ui.FilterMenu.prototype.options.operators = $.extend(
        true,
        kendo.ui.FilterMenu.prototype.options.operators,
        {
          date: {
            eq: 'равна',
            gte: 'после или равна',
            gt: 'после',
            lte: 'до или равна',
            lt: 'до',
            neq: 'не равна'
          },
          number: {
            eq: 'равно',
            gte: 'больше или равно',
            gt: 'больше',
            lte: 'меньше или равно',
            lt: 'меньше',
            neq: 'не равно'
          },
          string: {
            endswith: 'оканчивается на',
            eq: 'равно',
            neq: 'не равно',
            startswith: 'начинающимися на',
            contains: 'содержащими',
            doesnotcontain: 'не содержит'
          },
          enums: {
            eq: 'равно',
            neq: 'не равно'
          }
        }
      )
    }
    /* ColumnMenu messages */
    if (kendo.ui.ColumnMenu) {
      kendo.ui.ColumnMenu.prototype.options.messages = $.extend(
        true,
        kendo.ui.ColumnMenu.prototype.options.messages,
        {
          columns: 'Столбцы',
          sortAscending: 'Сортировка по возрастанию',
          sortDescending: 'Сортировка по убыванию',
          settings: 'Параметры столбцов',
          done: 'Готово',
          lock: 'Заблокировать',
          unlock: 'Разблокировать',
          filter: 'Фильтровать'
        }
      )
    }
    /* DateRangePicker messages */
    if (kendo.ui.DateRangePicker) {
      kendo.ui.DateRangePicker.prototype.options.messages = $.extend(
        true,
        kendo.ui.DateRangePicker.prototype.options.messages,
        {
          startLabel: 'Начало',
          endLabel: 'Конец'
        }
      )
    }
    /* RecurrenceEditor messages */
    if (kendo.ui.RecurrenceEditor) {
      kendo.ui.RecurrenceEditor.prototype.options.messages = $.extend(
        true,
        kendo.ui.RecurrenceEditor.prototype.options.messages,
        {
          daily: {
            interval: 'дней',
            repeatEvery: 'Повторять каждые:'
          },
          end: {
            after: 'После',
            occurrence: 'входит',
            label: 'Конец:',
            never: 'Никогда',
            on: 'В',
            mobileLabel: 'Окончание'
          },
          frequencies: {
            daily: 'Ежедневно',
            monthly: 'Ежемесячно',
            never: 'Никогда',
            weekly: 'Еженедельно',
            yearly: 'Ежегодно'
          },
          monthly: {
            day: 'День',
            interval: 'месяцы',
            repeatEvery: 'Повторять каждый:',
            repeatOn: 'Повторение:'
          },
          offsetPositions: {
            first: 'первый',
            fourth: 'четвертый',
            last: 'последний',
            second: 'второй',
            third: 'третий'
          },
          weekly: {
            repeatEvery: 'Повторять каждую:',
            repeatOn: 'Повторение:',
            interval: 'неделя'
          },
          yearly: {
            of: 'из',
            repeatEvery: 'Повторять каждый:',
            repeatOn: 'Повторение:',
            interval: 'годы'
          },
          weekdays: {
            day: 'день',
            weekday: 'будний',
            weekend: 'выходной'
          }
        }
      )
    }
    /* Grid messages */
    if (kendo.ui.Grid) {
      kendo.ui.Grid.prototype.options.messages = $.extend(
        true,
        kendo.ui.Grid.prototype.options.messages,
        {
          commands: {
            create: 'Добавить',
            destroy: 'Удалить',
            canceledit: 'Отмена',
            update: 'Обновить',
            edit: 'Изменить',
            excel: 'Экспорт в Excel',
            pdf: 'Экспорт в PDF',
            select: 'Выбрать',
            cancel: 'Отменить изменения',
            save: 'Сохранить изменения'
          },
          editable: {
            confirmation: 'Вы уверены, что хотите удалить эту запись?',
            cancelDelete: 'Отмена',
            confirmDelete: 'Удалить'
          },
          noRecords: 'Нет доступных записей.'
        }
      )
    }
    /* Pager messages */
    if (kendo.ui.Pager) {
      kendo.ui.Pager.prototype.options.messages = $.extend(
        true,
        kendo.ui.Pager.prototype.options.messages,
        {
          allPages: 'Все',
          page: 'Страница',
          display: 'Отображены записи {0} - {1} из {2}',
          of: 'из {0}',
          empty: 'Нет записей для отображения',
          refresh: 'Обновить',
          first: 'Вернуться на первую страницу',
          itemsPerPage: 'элементов на странице',
          last: 'К последней странице',
          next: 'Перейдите на следующую страницу',
          previous: 'Перейти на предыдущую страницу',
          morePages: 'Больше страниц'
        }
      )
    }
    /* TreeListPager messages */
    if (kendo.ui.TreeListPager) {
      kendo.ui.TreeListPager.prototype.options.messages = $.extend(
        true,
        kendo.ui.TreeListPager.prototype.options.messages,
        {
          allPages: 'Все',
          page: 'Страница',
          display: 'Отображены записи {0} - {1} из {2}',
          of: 'из {0}',
          empty: 'Нет записей для отображения',
          refresh: 'Обновить',
          first: 'Вернуться на первую страницу',
          itemsPerPage: 'элементов на странице',
          last: 'К последней странице',
          next: 'Перейдите на следующую страницу',
          previous: 'Перейти на предыдущую страницу',
          morePages: 'Больше страниц'
        }
      )
    }
    /* FilterCell messages */
    if (kendo.ui.FilterCell) {
      kendo.ui.FilterCell.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterCell.prototype.options.messages,
        {
          filter: 'фильтровать',
          clear: 'очистить',
          isFalse: 'ложь',
          isTrue: 'истина',
          operator: 'Оператор'
        }
      )
    }
    /* FilterMenu messages */
    if (kendo.ui.FilterMenu) {
      kendo.ui.FilterMenu.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterMenu.prototype.options.messages,
        {
          filter: 'фильтровать',
          and: 'И',
          clear: 'очистить',
          info: 'Строки со значениями',
          title: 'Строки со значениями',
          selectValue: '-выберите-',
          isFalse: 'ложь',
          isTrue: 'истина',
          or: 'или',
          cancel: 'Отмена',
          operator: 'Оператор',
          value: 'Значение'
        }
      )
    }
    /* FilterMultiCheck messages */
    if (kendo.ui.FilterMultiCheck) {
      kendo.ui.FilterMultiCheck.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterMultiCheck.prototype.options.messages,
        {
          search: 'Поиск'
        }
      )
    }
    /* Groupable messages */
    if (kendo.ui.Groupable) {
      kendo.ui.Groupable.prototype.options.messages = $.extend(
        true,
        kendo.ui.Groupable.prototype.options.messages,
        {
          empty:
            'Переместите сюда заголовок столбца, чтобы сгруппировать записи по этому столбцу'
        }
      )
    }
    /* Editor messages */
    if (kendo.ui.Editor) {
      kendo.ui.Editor.prototype.options.messages = $.extend(
        true,
        kendo.ui.Editor.prototype.options.messages,
        {
          bold: 'Полужирный',
          cleanFormatting: 'Очистить формат',
          createLink: 'Вставить гиперссылку',
          fontName: 'Шрифт',
          fontNameInherit: '(шрифт как в документе)',
          fontSize: 'Выбрать размер шрифта',
          fontSizeInherit: '(размер как в документе)',
          formatBlock: 'Текст изображения',
          indent: 'Увеличить отступ',
          insertHtml: 'Вставить HTML',
          insertImage: 'Изображение',
          insertOrderedList: 'Нумерованный список',
          insertUnorderedList: 'Маркированный список',
          italic: 'Курсив',
          justifyCenter: 'По центру',
          justifyFull: 'По ширине',
          justifyLeft: 'Влево',
          justifyRight: 'Вправо',
          outdent: 'Уменьшить отступ',
          strikethrough: 'Зачеркнутый',
          styles: 'Стиль',
          subscript: 'Под строкой',
          superscript: 'Над строкой',
          underline: 'Подчеркнутый',
          unlink: 'Удалить гиперссылку',
          dialogButtonSeparator: 'или',
          dialogCancel: 'Отмена',
          dialogInsert: 'Вставить',
          imageAltText: 'Альтернативный текст',
          imageWebAddress: 'Веб адрес',
          linkOpenInNewWindow: 'Открыть в новом окне',
          linkText: 'Текст',
          linkToolTip: 'Всплывающая подсказка',
          linkWebAddress: 'Веб адрес',
          search: 'Поиск',
          createTable: 'Вставить таблицу',
          addColumnLeft: 'Добавить столбец слева',
          addColumnRight: 'Добавить столбец справа',
          addRowAbove: 'Добавить строку выше',
          addRowBelow: 'Добавить строку ниже',
          deleteColumn: 'Удалить столбец',
          deleteRow: 'Удалить строку',
          backColor: 'Цвет фона',
          deleteFile: 'Вы уверены, что хотите удалить "{0}"?',
          directoryNotFound: 'Каталог с таким именем не найден.',
          dropFilesHere: 'для загрузки перетащите файлы сюда',
          emptyFolder: 'Пустая папка',
          foreColor: 'Цвет',
          invalidFileType:
            'Выбранный файл "{0}" не верен. Поддерживаемые типы файлов {1}.',
          orderBy: 'Упорядочить по:',
          orderByName: 'Имя',
          orderBySize: 'Размер',
          overwriteFile:
            '\'Файл с именем "{0}" уже существует в этой папке. Вы хотите перезаписать его?',
          uploadFile: 'Загрузить',
          formatting: 'Формат',
          viewHtml: 'Просмотр HTML',
          dialogUpdate: 'Обновить',
          insertFile: 'Вставить файл'
        }
      )
    }
    /* Upload messages */
    if (kendo.ui.Upload) {
      kendo.ui.Upload.prototype.options.localization = $.extend(
        true,
        kendo.ui.Upload.prototype.options.localization,
        {
          cancel: 'Отменить загрузку',
          dropFilesHere: 'перетащите сюда файлы для загрузки',
          remove: 'Удалить',
          retry: 'Повторить',
          select: 'Выбрать...',
          statusFailed: 'загрузка прервана',
          statusUploaded: 'загружено',
          statusUploading: 'загружается',
          uploadSelectedFiles: 'Загрузить выбранные файлы',
          headerStatusUploaded: 'Готово',
          headerStatusUploading: 'Загружается...'
        }
      )
    }
    /* Scheduler messages */
    if (kendo.ui.Scheduler) {
      kendo.ui.Scheduler.prototype.options.messages = $.extend(
        true,
        kendo.ui.Scheduler.prototype.options.messages,
        {
          allDay: 'весь день',
          cancel: 'Отмена',
          editable: {
            confirmation: 'Вы уверены, что хотите удалить это событие?'
          },
          date: 'Дата',
          destroy: 'Удалить',
          editor: {
            allDayEvent: 'Событие на весь день',
            description: 'Описание',
            editorTitle: 'Событие',
            end: 'Окончание',
            endTimezone: 'Часовой пояс окончания',
            repeat: 'Повторение',
            separateTimezones:
              'Для начала и окончания используйте свой часовой пояс',
            start: 'Начало',
            startTimezone: 'Часовой пояс начала',
            timezone: ' ',
            timezoneEditorButton: 'Часовой пояс',
            timezoneEditorTitle: 'Часовые пояса',
            title: 'Заголовок',
            noTimezone: 'Без часового пояса'
          },
          event: 'Событие',
          recurrenceMessages: {
            deleteRecurring:
              'Вы хотите удалить только это событие или весь ряд повторяющихся событий?',
            deleteWindowOccurrence: 'Удалить это событие',
            deleteWindowSeries: 'Удалить весь ряд',
            deleteWindowTitle: 'Удалить повторяющееся событие',
            editRecurring:
              'Вы хотите внести изменение только в это событие или изменить весь ряд?',
            editWindowOccurrence: 'Изменить текущее событие',
            editWindowSeries: 'Изменить весь ряд',
            editWindowTitle: 'Изменить одно событие'
          },
          save: 'Сохранить',
          time: 'время',
          today: 'Сегодня',
          views: {
            agenda: 'Повестка',
            day: 'День',
            month: 'Месяц',
            week: 'Неделя',
            workWeek: 'Рабочая неделя'
          },
          deleteWindowTitle: 'Удалить событие',
          showFullDay: 'Показывать весь день',
          showWorkDay: 'Показывать только рабочие часы'
        }
      )
    }
    /* Validator messages */
    if (kendo.ui.Validator) {
      kendo.ui.Validator.prototype.options.messages = $.extend(
        true,
        kendo.ui.Validator.prototype.options.messages,
        {
          required: '{0} обязателен',
          pattern: '{0} не верен',
          min: '{0} должен быть больше или равен {1}',
          max: '{0} должен быть меньше или равен {1}',
          step: '{0} не верен',
          email: '{0} не корректный email',
          url: '{0} не корректный URL',
          date: '{0} не корректная дата'
        }
      )
    }
    /* Dialog */
    if (kendo.ui.Dialog) {
      kendo.ui.Dialog.prototype.options.messages = $.extend(
        true,
        kendo.ui.Dialog.prototype.options.localization,
        {
          close: 'Закрыть'
        }
      )
    }
    /* Alert */
    if (kendo.ui.Alert) {
      kendo.ui.Alert.prototype.options.messages = $.extend(
        true,
        kendo.ui.Alert.prototype.options.localization,
        {
          okText: 'ОК'
        }
      )
    }
    /* Confirm */
    if (kendo.ui.Confirm) {
      kendo.ui.Confirm.prototype.options.messages = $.extend(
        true,
        kendo.ui.Confirm.prototype.options.localization,
        {
          okText: 'ОК',
          cancel: 'Отмена'
        }
      )
    }
    /* Prompt */
    if (kendo.ui.Prompt) {
      kendo.ui.Prompt.prototype.options.messages = $.extend(
        true,
        kendo.ui.Prompt.prototype.options.localization,
        {
          okText: 'ОК',
          cancel: 'Отмена'
        }
      )
    }
    /* DateInput */
    if (kendo.ui.DateInput) {
      kendo.ui.DateInput.prototype.options.messages = $.extend(
        true,
        kendo.ui.DateInput.prototype.options.messages,
        {
          year: 'год',
          month: 'месяц',
          day: 'день',
          weekday: 'день недели',
          hour: 'час',
          minute: 'минута',
          second: 'секунда',
          dayperiod: 'AM/PM'
        }
      )
    }
    /* PivotGrid messages */
    if (kendo.ui.PivotGrid) {
      kendo.ui.PivotGrid.prototype.options.messages = $.extend(
        true,
        kendo.ui.PivotGrid.prototype.options.messages,
        {
          measureFields: 'Перетащите поля с данными сюда',
          columnFields: 'Перетащите колонки сюда',
          rowFields: 'Перетащите строки сюда'
        }
      )
    }
    /* PivotFieldMenu messages */
    if (kendo.ui.PivotFieldMenu) {
      kendo.ui.PivotFieldMenu.prototype.options.messages = $.extend(
        true,
        kendo.ui.PivotFieldMenu.prototype.options.messages,
        {
          info: 'Показать поля такие, что:',
          filterFields: 'Фильтр по полям',
          filter: 'Фильтровать',
          include: 'Включить поля...',
          title: 'Включить поля',
          clear: 'Очистить',
          ok: 'Ok',
          cancel: 'Отмена',
          operators: {
            contains: 'Содержит',
            doesnotcontain: 'Не содержит',
            startswith: 'Начинается с',
            endswith: 'Заканчивается на',
            eq: 'Равно',
            neq: 'Не равно'
          }
        }
      )
    }
    /* Gantt */
    if (kendo.ui.Gantt) {
      kendo.ui.Gantt.prototype.options.messages = $.extend(
        true,
        kendo.ui.Gantt.prototype.options.messages,
        {
          actions: {
            addChild: 'Добавить подзадачу',
            append: 'Добавить задачу',
            insertAfter: 'Добавить после',
            insertBefore: 'Добавить до',
            pdf: 'Экспорт в PDF'
          },
          cancel: 'Отмена',
          deleteDependencyWindowTitle: 'Удалить зависимость',
          deleteTaskWindowTitle: 'Удалить задачу',
          destroy: 'Удалить',
          editor: {
            assingButton: 'Задать',
            editorTitle: 'Задача',
            end: 'Окончание',
            percentComplete: 'Завершено',
            resources: 'Ресурсы',
            resourcesEditorTitle: 'Ресурсы',
            resourcesHeader: 'Ресурсы',
            start: 'Начало',
            title: 'Название',
            unitsHeader: 'Единицы'
          },
          save: 'Сохранить',
          views: {
            day: 'День',
            end: 'Окончание',
            month: 'Месяц',
            start: 'Начало',
            week: 'Неделя',
            year: 'Год'
          }
        }
      )
    }
  },
  en: () => {
    /* FlatColorPicker messages */

    if (kendo.ui.FlatColorPicker) {
      kendo.ui.FlatColorPicker.prototype.options.messages = $.extend(
        true,
        kendo.ui.FlatColorPicker.prototype.options.messages,
        {
          apply: 'Apply',
          cancel: 'Cancel',
          noColor: 'no color',
          clearColor: 'Clear color'
        }
      )
    }

    /* ColorPicker messages */

    if (kendo.ui.ColorPicker) {
      kendo.ui.ColorPicker.prototype.options.messages = $.extend(
        true,
        kendo.ui.ColorPicker.prototype.options.messages,
        {
          apply: 'Apply',
          cancel: 'Cancel',
          noColor: 'no color',
          clearColor: 'Clear color'
        }
      )
    }

    /* ColumnMenu messages */

    if (kendo.ui.ColumnMenu) {
      kendo.ui.ColumnMenu.prototype.options.messages = $.extend(
        true,
        kendo.ui.ColumnMenu.prototype.options.messages,
        {
          sortAscending: 'Sort Ascending',
          sortDescending: 'Sort Descending',
          filter: 'Filter',
          column: 'Column',
          columns: 'Columns',
          columnVisibility: 'Column Visibility',
          clear: 'Clear',
          cancel: 'Cancel',
          done: 'Done',
          settings: 'Edit Column Settings',
          lock: 'Lock',
          unlock: 'Unlock'
        }
      )
    }
    /* DateRangePicker messages */
    if (kendo.ui.DateRangePicker) {
      kendo.ui.DateRangePicker.prototype.options.messages = $.extend(
        true,
        kendo.ui.DateRangePicker.prototype.options.messages,
        {
          startLabel: 'Start',
          endLabel: 'End'
        }
      )
    }
    /* Editor messages */
    if (kendo.ui.Editor) {
      kendo.ui.Editor.prototype.options.messages = $.extend(
        true,
        kendo.ui.Editor.prototype.options.messages,
        {
          bold: 'Bold',
          italic: 'Italic',
          underline: 'Underline',
          strikethrough: 'Strikethrough',
          superscript: 'Superscript',
          subscript: 'Subscript',
          justifyCenter: 'Center text',
          justifyLeft: 'Align text left',
          justifyRight: 'Align text right',
          justifyFull: 'Justify',
          insertUnorderedList: 'Insert unordered list',
          insertOrderedList: 'Insert ordered list',
          indent: 'Indent',
          outdent: 'Outdent',
          createLink: 'Insert hyperlink',
          unlink: 'Remove hyperlink',
          insertImage: 'Insert image',
          insertFile: 'Insert file',
          insertHtml: 'Insert HTML',
          viewHtml: 'View HTML',
          fontName: 'Select font family',
          fontNameInherit: '(inherited font)',
          fontSize: 'Select font size',
          fontSizeInherit: '(inherited size)',
          formatBlock: 'Format',
          formatting: 'Format',
          foreColor: 'Color',
          backColor: 'Background color',
          style: 'Styles',
          emptyFolder: 'Empty Folder',
          uploadFile: 'Upload',
          overflowAnchor: 'More tools',
          orderBy: 'Arrange by:',
          orderBySize: 'Size',
          orderByName: 'Name',
          invalidFileType:
            'The selected file "{0}" is not valid. Supported file types are {1}.',
          deleteFile: 'Are you sure you want to delete "{0}"?',
          overwriteFile:
            'A file with name "{0}" already exists in the current directory. Do you want to overwrite it?',
          directoryNotFound: 'A directory with this name was not found.',
          imageWebAddress: 'Web address',
          imageAltText: 'Alternate text',
          imageWidth: 'Width (px)',
          imageHeight: 'Height (px)',
          fileWebAddress: 'Web address',
          fileTitle: 'Title',
          linkWebAddress: 'Web address',
          linkText: 'Text',
          linkToolTip: 'ToolTip',
          linkOpenInNewWindow: 'Open link in new window',
          dialogUpdate: 'Update',
          dialogInsert: 'Insert',
          dialogButtonSeparator: 'or',
          dialogCancel: 'Cancel',
          cleanFormatting: 'Clean formatting',
          createTable: 'Create table',
          addColumnLeft: 'Add column on the left',
          addColumnRight: 'Add column on the right',
          addRowAbove: 'Add row above',
          addRowBelow: 'Add row below',
          deleteRow: 'Delete row',
          deleteColumn: 'Delete column',
          dialogOk: 'Ok',
          tableWizard: 'Table Wizard',
          tableTab: 'Table',
          cellTab: 'Cell',
          accessibilityTab: 'Accessibility',
          caption: 'Caption',
          summary: 'Summary',
          width: 'Width',
          height: 'Height',
          units: 'Units',
          cellSpacing: 'Cell Spacing',
          cellPadding: 'Cell Padding',
          cellMargin: 'Cell Margin',
          alignment: 'Alignment',
          background: 'Background',
          cssClass: 'CSS Class',
          id: 'ID',
          border: 'Border',
          borderStyle: 'Border Style',
          collapseBorders: 'Collapse borders',
          wrapText: 'Wrap text',
          associateCellsWithHeaders: 'Associate cells with headers',
          alignLeft: 'Align Left',
          alignCenter: 'Align Center',
          alignRight: 'Align Right',
          alignLeftTop: 'Align Left Top',
          alignCenterTop: 'Align Center Top',
          alignRightTop: 'Align Right Top',
          alignLeftMiddle: 'Align Left Middle',
          alignCenterMiddle: 'Align Center Middle',
          alignRightMiddle: 'Align Right Middle',
          alignLeftBottom: 'Align Left Bottom',
          alignCenterBottom: 'Align Center Bottom',
          alignRightBottom: 'Align Right Bottom',
          alignRemove: 'Remove Alignment',
          columns: 'Columns',
          rows: 'Rows',
          selectAllCells: 'Select All Cells',
          print: 'Print'
        }
      )
    }

    /* FileBrowser messages */

    if (kendo.ui.FileBrowser) {
      kendo.ui.FileBrowser.prototype.options.messages = $.extend(
        true,
        kendo.ui.FileBrowser.prototype.options.messages,
        {
          uploadFile: 'Upload',
          orderBy: 'Arrange by',
          orderByName: 'Name',
          orderBySize: 'Size',
          directoryNotFound: 'A directory with this name was not found.',
          emptyFolder: 'Empty Folder',
          deleteFile: 'Are you sure you want to delete "{0}"?',
          invalidFileType:
            'The selected file "{0}" is not valid. Supported file types are {1}.',
          overwriteFile:
            'A file with name "{0}" already exists in the current directory. Do you want to overwrite it?',
          dropFilesHere: 'drop file here to upload',
          search: 'Search'
        }
      )
    }

    /* FilterCell messages */

    if (kendo.ui.FilterCell) {
      kendo.ui.FilterCell.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterCell.prototype.options.messages,
        {
          isTrue: 'is true',
          isFalse: 'is false',
          filter: 'Filter',
          clear: 'Clear',
          operator: 'Operator'
        }
      )
    }

    /* FilterCell operators */

    if (kendo.ui.FilterCell) {
      kendo.ui.FilterCell.prototype.options.operators = $.extend(
        true,
        kendo.ui.FilterCell.prototype.options.operators,
        {
          string: {
            eq: 'Is equal to',
            neq: 'Is not equal to',
            startswith: 'Starts with',
            contains: 'Contains',
            doesnotcontain: 'Does not contain',
            endswith: 'Ends with',
            isnull: 'Is null',
            isnotnull: 'Is not null',
            isempty: 'Is empty',
            isnotempty: 'Is not empty',
            isnullorempty: 'Has no value',
            isnotnullorempty: 'Has value'
          },
          number: {
            eq: 'Is equal to',
            neq: 'Is not equal to',
            gte: 'Is greater than or equal to',
            gt: 'Is greater than',
            lte: 'Is less than or equal to',
            lt: 'Is less than',
            isnull: 'Is null',
            isnotnull: 'Is not null'
          },
          date: {
            eq: 'Is equal to',
            neq: 'Is not equal to',
            gte: 'Is after or equal to',
            gt: 'Is after',
            lte: 'Is before or equal to',
            lt: 'Is before',
            isnull: 'Is null',
            isnotnull: 'Is not null'
          },
          enums: {
            eq: 'Is equal to',
            neq: 'Is not equal to',
            isnull: 'Is null',
            isnotnull: 'Is not null'
          }
        }
      )
    }

    /* FilterMenu messages */

    if (kendo.ui.FilterMenu) {
      kendo.ui.FilterMenu.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterMenu.prototype.options.messages,
        {
          info: 'Show items with value that:',
          title: 'Show items with value that',
          isTrue: 'is true',
          isFalse: 'is false',
          filter: 'Filter',
          clear: 'Clear',
          and: 'And',
          or: 'Or',
          selectValue: '-Select value-',
          operator: 'Operator',
          value: 'Value',
          cancel: 'Cancel',
          done: 'Done',
          into: 'in'
        }
      )
    }

    /* FilterMenu operator messages */

    if (kendo.ui.FilterMenu) {
      kendo.ui.FilterMenu.prototype.options.operators = $.extend(
        true,
        kendo.ui.FilterMenu.prototype.options.operators,
        {
          string: {
            eq: 'Is equal to',
            neq: 'Is not equal to',
            startswith: 'Starts with',
            contains: 'Contains',
            doesnotcontain: 'Does not contain',
            endswith: 'Ends with',
            isnull: 'Is null',
            isnotnull: 'Is not null',
            isempty: 'Is empty',
            isnotempty: 'Is not empty',
            isnullorempty: 'Has no value',
            isnotnullorempty: 'Has value'
          },
          number: {
            eq: 'Is equal to',
            neq: 'Is not equal to',
            gte: 'Is greater than or equal to',
            gt: 'Is greater than',
            lte: 'Is less than or equal to',
            lt: 'Is less than',
            isnull: 'Is null',
            isnotnull: 'Is not null'
          },
          date: {
            eq: 'Is equal to',
            neq: 'Is not equal to',
            gte: 'Is after or equal to',
            gt: 'Is after',
            lte: 'Is before or equal to',
            lt: 'Is before',
            isnull: 'Is null',
            isnotnull: 'Is not null'
          },
          enums: {
            eq: 'Is equal to',
            neq: 'Is not equal to',
            isnull: 'Is null',
            isnotnull: 'Is not null'
          }
        }
      )
    }

    /* FilterMultiCheck messages */

    if (kendo.ui.FilterMultiCheck) {
      kendo.ui.FilterMultiCheck.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterMultiCheck.prototype.options.messages,
        {
          checkAll: 'Select All',
          clearAll: 'Clear All',
          clear: 'Clear',
          filter: 'Filter',
          search: 'Search',
          cancel: 'Cancel',
          selectedItemsFormat: '{0} items selected',
          done: 'Done',
          into: 'in'
        }
      )
    }

    /* Gantt messages */

    if (kendo.ui.Gantt) {
      kendo.ui.Gantt.prototype.options.messages = $.extend(
        true,
        kendo.ui.Gantt.prototype.options.messages,
        {
          actions: {
            addChild: 'Add Child',
            append: 'Add Task',
            insertAfter: 'Add Below',
            insertBefore: 'Add Above',
            pdf: 'Export to PDF'
          },
          cancel: 'Cancel',
          deleteDependencyWindowTitle: 'Delete dependency',
          deleteTaskWindowTitle: 'Delete task',
          destroy: 'Delete',
          editor: {
            assingButton: 'Assign',
            editorTitle: 'Task',
            end: 'End',
            percentComplete: 'Complete',
            resources: 'Resources',
            resourcesEditorTitle: 'Resources',
            resourcesHeader: 'Resources',
            start: 'Start',
            title: 'Title',
            unitsHeader: 'Units'
          },
          save: 'Save',
          views: {
            day: 'Day',
            end: 'End',
            month: 'Month',
            start: 'Start',
            week: 'Week',
            year: 'Year'
          }
        }
      )
    }

    /* Grid messages */

    if (kendo.ui.Grid) {
      kendo.ui.Grid.prototype.options.messages = $.extend(
        true,
        kendo.ui.Grid.prototype.options.messages,
        {
          commands: {
            cancel: 'Cancel changes',
            canceledit: 'Cancel',
            create: 'Add new record',
            destroy: 'Delete',
            edit: 'Edit',
            excel: 'Export to Excel',
            pdf: 'Export to PDF',
            save: 'Save changes',
            select: 'Select',
            update: 'Update'
          },
          editable: {
            cancelDelete: 'Cancel',
            confirmation: 'Are you sure you want to delete this record?',
            confirmDelete: 'Delete'
          },
          noRecords: 'No records available.',
          search: 'Search...',
          expandCollapseColumnHeader: '',
          groupHeader: 'Press ctrl + space to group',
          ungroupHeader: 'Press ctrl + space to ungroup'
        }
      )
    }

    /* TreeList messages */

    if (kendo.ui.TreeList) {
      kendo.ui.TreeList.prototype.options.messages = $.extend(
        true,
        kendo.ui.TreeList.prototype.options.messages,
        {
          noRows: 'No records to display',
          loading: 'Loading...',
          requestFailed: 'Request failed.',
          retry: 'Retry',
          commands: {
            edit: 'Edit',
            update: 'Update',
            canceledit: 'Cancel',
            create: 'Add new record',
            createchild: 'Add child record',
            destroy: 'Delete',
            excel: 'Export to Excel',
            pdf: 'Export to PDF'
          }
        }
      )
    }

    /* Groupable messages */

    if (kendo.ui.Groupable) {
      kendo.ui.Groupable.prototype.options.messages = $.extend(
        true,
        kendo.ui.Groupable.prototype.options.messages,
        {
          empty: 'Drag a column header and drop it here to group by that column'
        }
      )
    }

    /* NumericTextBox messages */

    if (kendo.ui.NumericTextBox) {
      kendo.ui.NumericTextBox.prototype.options = $.extend(
        true,
        kendo.ui.NumericTextBox.prototype.options,
        {
          upArrowText: 'Increase value',
          downArrowText: 'Decrease value'
        }
      )
    }

    /* MediaPlayer messages */

    if (kendo.ui.MediaPlayer) {
      kendo.ui.MediaPlayer.prototype.options.messages = $.extend(
        true,
        kendo.ui.MediaPlayer.prototype.options.messages,
        {
          pause: 'Pause',
          play: 'Play',
          mute: 'Mute',
          unmute: 'Unmute',
          quality: 'Quality',
          fullscreen: 'Full Screen'
        }
      )
    }

    /* Pager messages */

    if (kendo.ui.Pager) {
      kendo.ui.Pager.prototype.options.messages = $.extend(
        true,
        kendo.ui.Pager.prototype.options.messages,
        {
          allPages: 'All',
          display: '{0} - {1} of {2} items',
          empty: 'No items to display',
          page: 'Page',
          of: 'of {0}',
          itemsPerPage: 'items per page',
          first: 'Go to the first page',
          previous: 'Go to the previous page',
          next: 'Go to the next page',
          last: 'Go to the last page',
          refresh: 'Refresh',
          morePages: 'More pages'
        }
      )
    }

    /* TreeListPager messages */

    if (kendo.ui.TreeListPager) {
      kendo.ui.TreeListPager.prototype.options.messages = $.extend(
        true,
        kendo.ui.TreeListPager.prototype.options.messages,
        {
          allPages: 'All',
          display: '{0} - {1} of {2} items',
          empty: 'No items to display',
          page: 'Page',
          of: 'of {0}',
          itemsPerPage: 'items per page',
          first: 'Go to the first page',
          previous: 'Go to the previous page',
          next: 'Go to the next page',
          last: 'Go to the last page',
          refresh: 'Refresh',
          morePages: 'More pages'
        }
      )
    }

    /* PivotGrid messages */

    if (kendo.ui.PivotGrid) {
      kendo.ui.PivotGrid.prototype.options.messages = $.extend(
        true,
        kendo.ui.PivotGrid.prototype.options.messages,
        {
          measureFields: 'Drop Data Fields Here',
          columnFields: 'Drop Column Fields Here',
          rowFields: 'Drop Rows Fields Here'
        }
      )
    }

    /* PivotFieldMenu messages */

    if (kendo.ui.PivotFieldMenu) {
      kendo.ui.PivotFieldMenu.prototype.options.messages = $.extend(
        true,
        kendo.ui.PivotFieldMenu.prototype.options.messages,
        {
          info: 'Show items with value that:',
          filterFields: 'Fields Filter',
          filter: 'Filter',
          include: 'Include Fields...',
          title: 'Fields to include',
          clear: 'Clear',
          ok: 'Ok',
          cancel: 'Cancel',
          operators: {
            contains: 'Contains',
            doesnotcontain: 'Does not contain',
            startswith: 'Starts with',
            endswith: 'Ends with',
            eq: 'Is equal to',
            neq: 'Is not equal to'
          }
        }
      )
    }

    /* RecurrenceEditor messages */

    if (kendo.ui.RecurrenceEditor) {
      kendo.ui.RecurrenceEditor.prototype.options.messages = $.extend(
        true,
        kendo.ui.RecurrenceEditor.prototype.options.messages,
        {
          frequencies: {
            never: 'Never',
            hourly: 'Hourly',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly'
          },
          hourly: {
            repeatEvery: 'Repeat every: ',
            interval: ' hour(s)'
          },
          daily: {
            repeatEvery: 'Repeat every: ',
            interval: ' day(s)'
          },
          weekly: {
            interval: ' week(s)',
            repeatEvery: 'Repeat every: ',
            repeatOn: 'Repeat on: '
          },
          monthly: {
            repeatEvery: 'Repeat every: ',
            repeatOn: 'Repeat on: ',
            interval: ' month(s)',
            day: 'Day '
          },
          yearly: {
            repeatEvery: 'Repeat every: ',
            repeatOn: 'Repeat on: ',
            interval: ' year(s)',
            of: ' of '
          },
          end: {
            label: 'End:',
            mobileLabel: 'Ends',
            never: 'Never',
            after: 'After ',
            occurrence: ' occurrence(s)',
            on: 'On '
          },
          offsetPositions: {
            first: 'first',
            second: 'second',
            third: 'third',
            fourth: 'fourth',
            last: 'last'
          },
          weekdays: {
            day: 'day',
            weekday: 'weekday',
            weekend: 'weekend day'
          }
        }
      )
    }

    /* Scheduler messages */

    if (kendo.ui.Scheduler) {
      kendo.ui.Scheduler.prototype.options.messages = $.extend(
        true,
        kendo.ui.Scheduler.prototype.options.messages,
        {
          allDay: 'all day',
          date: 'Date',
          event: 'Event',
          time: 'Time',
          showFullDay: 'Show full day',
          showWorkDay: 'Show business hours',
          today: 'Today',
          save: 'Save',
          cancel: 'Cancel',
          destroy: 'Delete',
          resetSeries: 'Reset Series',
          deleteWindowTitle: 'Delete event',
          ariaSlotLabel: 'Selected from {0:t} to {1:t}',
          ariaEventLabel: '{0} on {1:D} at {2:t}',
          editable: {
            confirmation: 'Are you sure you want to delete this event?'
          },
          views: {
            day: 'Day',
            week: 'Week',
            workWeek: 'Work Week',
            agenda: 'Agenda',
            month: 'Month'
          },
          recurrenceMessages: {
            deleteWindowTitle: 'Delete Recurring Item',
            resetSeriesWindowTitle: 'Reset Series',
            deleteWindowOccurrence: 'Delete current occurrence',
            deleteWindowSeries: 'Delete the series',
            deleteRecurringConfirmation:
              'Are you sure you want to delete this event occurrence?',
            deleteSeriesConfirmation:
              'Are you sure you want to delete the whole series?',
            editWindowTitle: 'Edit Recurring Item',
            editWindowOccurrence: 'Edit current occurrence',
            editWindowSeries: 'Edit the series',
            deleteRecurring:
              'Do you want to delete only this event occurrence or the whole series?',
            editRecurring:
              'Do you want to edit only this event occurrence or the whole series?'
          },
          editor: {
            title: 'Title',
            start: 'Start',
            end: 'End',
            allDayEvent: 'All day event',
            description: 'Description',
            repeat: 'Repeat',
            timezone: ' ',
            startTimezone: 'Start timezone',
            endTimezone: 'End timezone',
            separateTimezones: 'Use separate start and end time zones',
            timezoneEditorTitle: 'Timezones',
            timezoneEditorButton: 'Time zone',
            timezoneTitle: 'Time zones',
            noTimezone: 'No timezone',
            editorTitle: 'Event'
          }
        }
      )
    }

    /* Spreadsheet messages */

    if (kendo.spreadsheet && kendo.spreadsheet.messages.borderPalette) {
      kendo.spreadsheet.messages.borderPalette = $.extend(
        true,
        kendo.spreadsheet.messages.borderPalette,
        {
          allBorders: 'All borders',
          insideBorders: 'Inside borders',
          insideHorizontalBorders: 'Inside horizontal borders',
          insideVerticalBorders: 'Inside vertical borders',
          outsideBorders: 'Outside borders',
          leftBorder: 'Left border',
          topBorder: 'Top border',
          rightBorder: 'Right border',
          bottomBorder: 'Bottom border',
          noBorders: 'No border',
          reset: 'Reset color',
          customColor: 'Custom color...',
          apply: 'Apply',
          cancel: 'Cancel'
        }
      )
    }

    if (kendo.spreadsheet && kendo.spreadsheet.messages.dialogs) {
      kendo.spreadsheet.messages.dialogs = $.extend(
        true,
        kendo.spreadsheet.messages.dialogs,
        {
          apply: 'Apply',
          save: 'Save',
          cancel: 'Cancel',
          remove: 'Remove',
          retry: 'Retry',
          revert: 'Revert',
          okText: 'OK',
          formatCellsDialog: {
            title: 'Format',
            categories: {
              number: 'Number',
              currency: 'Currency',
              date: 'Date'
            }
          },
          fontFamilyDialog: {
            title: 'Font'
          },
          fontSizeDialog: {
            title: 'Font size'
          },
          bordersDialog: {
            title: 'Borders'
          },
          alignmentDialog: {
            title: 'Alignment',
            buttons: {
              justtifyLeft: 'Align left',
              justifyCenter: 'Center',
              justifyRight: 'Align right',
              justifyFull: 'Justify',
              alignTop: 'Align top',
              alignMiddle: 'Align middle',
              alignBottom: 'Align bottom'
            }
          },
          mergeDialog: {
            title: 'Merge cells',
            buttons: {
              mergeCells: 'Merge all',
              mergeHorizontally: 'Merge horizontally',
              mergeVertically: 'Merge vertically',
              unmerge: 'Unmerge'
            }
          },
          freezeDialog: {
            title: 'Freeze panes',
            buttons: {
              freezePanes: 'Freeze panes',
              freezeRows: 'Freeze rows',
              freezeColumns: 'Freeze columns',
              unfreeze: 'Unfreeze panes'
            }
          },
          confirmationDialog: {
            text: 'Are you sure you want to remove this sheet?',
            title: 'Sheet remove'
          },
          validationDialog: {
            title: 'Data Validation',
            hintMessage: 'Please enter a valid {0} value {1}.',
            hintTitle: 'Validation {0}',
            criteria: {
              any: 'Any value',
              number: 'Number',
              text: 'Text',
              date: 'Date',
              custom: 'Custom Formula',
              list: 'List'
            },
            comparers: {
              greaterThan: 'greater than',
              lessThan: 'less than',
              between: 'between',
              notBetween: 'not between',
              equalTo: 'equal to',
              notEqualTo: 'not equal to',
              greaterThanOrEqualTo: 'greater than or equal to',
              lessThanOrEqualTo: 'less than or equal to'
            },
            comparerMessages: {
              greaterThan: 'greater than {0}',
              lessThan: 'less than {0}',
              between: 'between {0} and {1}',
              notBetween: 'not between {0} and {1}',
              equalTo: 'equal to {0}',
              notEqualTo: 'not equal to {0}',
              greaterThanOrEqualTo: 'greater than or equal to {0}',
              lessThanOrEqualTo: 'less than or equal to {0}',
              custom: 'that satisfies the formula: {0}'
            },
            labels: {
              criteria: 'Criteria',
              comparer: 'Comparer',
              min: 'Min',
              max: 'Max',
              value: 'Value',
              start: 'Start',
              end: 'End',
              onInvalidData: 'On invalid data',
              rejectInput: 'Reject input',
              showWarning: 'Show warning',
              showHint: 'Show hint',
              hintTitle: 'Hint title',
              hintMessage: 'Hint message',
              ignoreBlank: 'Ignore blank'
            },
            placeholders: {
              typeTitle: 'Type title',
              typeMessage: 'Type message'
            }
          },
          exportAsDialog: {
            title: 'Export...',
            labels: {
              fileName: 'File name',
              saveAsType: 'Save as type',
              exportArea: 'Export',
              paperSize: 'Paper size',
              margins: 'Margins',
              orientation: 'Orientation',
              print: 'Print',
              guidelines: 'Guidelines',
              center: 'Center',
              horizontally: 'Horizontally',
              vertically: 'Vertically'
            }
          },
          modifyMergedDialog: {
            errorMessage: 'Cannot change part of a merged cell.'
          },
          useKeyboardDialog: {
            title: 'Copying and pasting',
            errorMessage:
              'These actions cannot be invoked through the menu. Please use the keyboard shortcuts instead:',
            labels: {
              forCopy: 'for copy',
              forCut: 'for cut',
              forPaste: 'for paste'
            }
          },
          unsupportedSelectionDialog: {
            errorMessage:
              'That action cannot be performed on multiple selection.'
          },
          insertCommentDialog: {
            title: 'Insert comment',
            labels: {
              comment: 'Comment',
              removeComment: 'Remove comment'
            }
          },
          insertImageDialog: {
            title: 'Insert image',
            info: 'Drag an image here, or click to select',
            typeError: 'Please select a JPEG, PNG or GIF image'
          }
        }
      )
    }

    if (kendo.spreadsheet && kendo.spreadsheet.messages.filterMenu) {
      kendo.spreadsheet.messages.filterMenu = $.extend(
        true,
        kendo.spreadsheet.messages.filterMenu,
        {
          sortAscending: 'Sort range A to Z',
          sortDescending: 'Sort range Z to A',
          filterByValue: 'Filter by value',
          filterByCondition: 'Filter by condition',
          apply: 'Apply',
          search: 'Search',
          addToCurrent: 'Add to current selection',
          clear: 'Clear',
          blanks: '(Blanks)',
          operatorNone: 'None',
          and: 'AND',
          or: 'OR',
          operators: {
            string: {
              contains: 'Text contains',
              doesnotcontain: 'Text does not contain',
              startswith: 'Text starts with',
              endswith: 'Text ends with'
            },
            date: {
              eq: 'Date is',
              neq: 'Date is not',
              lt: 'Date is before',
              gt: 'Date is after'
            },
            number: {
              eq: 'Is equal to',
              neq: 'Is not equal to',
              gte: 'Is greater than or equal to',
              gt: 'Is greater than',
              lte: 'Is less than or equal to',
              lt: 'Is less than'
            }
          }
        }
      )
    }

    if (kendo.spreadsheet && kendo.spreadsheet.messages.colorPicker) {
      kendo.spreadsheet.messages.colorPicker = $.extend(
        true,
        kendo.spreadsheet.messages.colorPicker,
        {
          reset: 'Reset color',
          customColor: 'Custom color...',
          apply: 'Apply',
          cancel: 'Cancel'
        }
      )
    }

    if (kendo.spreadsheet && kendo.spreadsheet.messages.toolbar) {
      kendo.spreadsheet.messages.toolbar = $.extend(
        true,
        kendo.spreadsheet.messages.toolbar,
        {
          addColumnLeft: 'Add column left',
          addColumnRight: 'Add column right',
          addRowAbove: 'Add row above',
          addRowBelow: 'Add row below',
          alignment: 'Alignment',
          alignmentButtons: {
            justtifyLeft: 'Align left',
            justifyCenter: 'Center',
            justifyRight: 'Align right',
            justifyFull: 'Justify',
            alignTop: 'Align top',
            alignMiddle: 'Align middle',
            alignBottom: 'Align bottom'
          },
          backgroundColor: 'Background',
          bold: 'Bold',
          borders: 'Borders',
          colorPicker: {
            reset: 'Reset color',
            customColor: 'Custom color...'
          },
          copy: 'Copy',
          cut: 'Cut',
          deleteColumn: 'Delete column',
          deleteRow: 'Delete row',
          excelImport: 'Import from Excel...',
          filter: 'Filter',
          fontFamily: 'Font',
          fontSize: 'Font size',
          format: 'Custom format...',
          formatTypes: {
            automatic: 'Automatic',
            number: 'Number',
            percent: 'Percent',
            financial: 'Financial',
            currency: 'Currency',
            date: 'Date',
            time: 'Time',
            dateTime: 'Date time',
            duration: 'Duration',
            moreFormats: 'More formats...'
          },
          formatDecreaseDecimal: 'Decrease decimal',
          formatIncreaseDecimal: 'Increase decimal',
          freeze: 'Freeze panes',
          freezeButtons: {
            freezePanes: 'Freeze panes',
            freezeRows: 'Freeze rows',
            freezeColumns: 'Freeze columns',
            unfreeze: 'Unfreeze panes'
          },
          insertComment: 'Insert comment',
          insertImage: 'Insert image',
          italic: 'Italic',
          merge: 'Merge cells',
          mergeButtons: {
            mergeCells: 'Merge all',
            mergeHorizontally: 'Merge horizontally',
            mergeVertically: 'Merge vertically',
            unmerge: 'Unmerge'
          },
          open: 'Open...',
          paste: 'Paste',
          quickAccess: {
            redo: 'Redo',
            undo: 'Undo'
          },
          saveAs: 'Save As...',
          sortAsc: 'Sort ascending',
          sortDesc: 'Sort descending',
          sortButtons: {
            sortSheetAsc: 'Sort sheet A to Z',
            sortSheetDesc: 'Sort sheet Z to A',
            sortRangeAsc: 'Sort range A to Z',
            sortRangeDesc: 'Sort range Z to A'
          },
          textColor: 'Text Color',
          textWrap: 'Wrap text',
          underline: 'Underline',
          validation: 'Data validation...'
        }
      )
    }

    if (kendo.spreadsheet && kendo.spreadsheet.messages.view) {
      kendo.spreadsheet.messages.view = $.extend(
        true,
        kendo.spreadsheet.messages.view,
        {
          errors: {
            shiftingNonblankCells:
              'Cannot insert cells due to data loss possibility. Select another insert location or delete the data from the end of your worksheet.',
            filterRangeContainingMerges:
              'Cannot create a filter within a range containing merges',
            validationError:
              'The value that you entered violates the validation rules set on the cell.'
          },
          tabs: {
            home: 'Home',
            insert: 'Insert',
            data: 'Data'
          }
        }
      )
    }

    /* Slider messages */

    if (kendo.ui.Slider) {
      kendo.ui.Slider.prototype.options = $.extend(
        true,
        kendo.ui.Slider.prototype.options,
        {
          increaseButtonTitle: 'Increase',
          decreaseButtonTitle: 'Decrease'
        }
      )
    }

    /* ListBox messaages */

    if (kendo.ui.ListBox) {
      kendo.ui.ListBox.prototype.options.messages = $.extend(
        true,
        kendo.ui.ListBox.prototype.options.messages,
        {
          tools: {
            remove: 'Delete',
            moveUp: 'Move Up',
            moveDown: 'Move Down',
            transferTo: 'Transfer To',
            transferFrom: 'Transfer From',
            transferAllTo: 'Transfer All To',
            transferAllFrom: 'Transfer All From'
          }
        }
      )
    }

    /* TreeList messages */

    if (kendo.ui.TreeList) {
      kendo.ui.TreeList.prototype.options.messages = $.extend(
        true,
        kendo.ui.TreeList.prototype.options.messages,
        {
          noRows: 'No records to display',
          loading: 'Loading...',
          requestFailed: 'Request failed.',
          retry: 'Retry',
          commands: {
            edit: 'Edit',
            update: 'Update',
            canceledit: 'Cancel',
            create: 'Add new record',
            createchild: 'Add child record',
            destroy: 'Delete',
            excel: 'Export to Excel',
            pdf: 'Export to PDF'
          }
        }
      )
    }

    /* TreeView messages */

    if (kendo.ui.TreeView) {
      kendo.ui.TreeView.prototype.options.messages = $.extend(
        true,
        kendo.ui.TreeView.prototype.options.messages,
        {
          loading: 'Loading...',
          requestFailed: 'Request failed.',
          retry: 'Retry'
        }
      )
    }

    /* Upload messages */

    if (kendo.ui.Upload) {
      kendo.ui.Upload.prototype.options.localization = $.extend(
        true,
        kendo.ui.Upload.prototype.options.localization,
        {
          select: 'Select files...',
          cancel: 'Cancel',
          retry: 'Retry',
          remove: 'Remove',
          clearSelectedFiles: 'Clear',
          uploadSelectedFiles: 'Upload files',
          dropFilesHere: 'Drop files here to upload',
          statusUploading: 'uploading',
          statusUploaded: 'uploaded',
          statusWarning: 'warning',
          statusFailed: 'failed',
          headerStatusUploading: 'Uploading...',
          headerStatusUploaded: 'Done',
          invalidMaxFileSize: 'File size too large.',
          invalidMinFileSize: 'File size too small.',
          invalidFileExtension: 'File type not allowed.'
        }
      )
    }

    /* Validator messages */

    if (kendo.ui.Validator) {
      kendo.ui.Validator.prototype.options.messages = $.extend(
        true,
        kendo.ui.Validator.prototype.options.messages,
        {
          required: '{0} is required',
          pattern: '{0} is not valid',
          min: '{0} should be greater than or equal to {1}',
          max: '{0} should be smaller than or equal to {1}',
          step: '{0} is not valid',
          email: '{0} is not valid email',
          url: '{0} is not valid URL',
          date: '{0} is not valid date',
          dateCompare:
            'End date should be greater than or equal to the start date'
        }
      )
    }

    /* kendo.ui.progress method */
    if (kendo.ui.progress) {
      kendo.ui.progress.messages = $.extend(true, kendo.ui.progress.messages, {
        loading: 'Loading...'
      })
    }

    /* Dialog */

    if (kendo.ui.Dialog) {
      kendo.ui.Dialog.prototype.options.messages = $.extend(
        true,
        kendo.ui.Dialog.prototype.options.localization,
        {
          close: 'Close'
        }
      )
    }

    /* Calendar */
    if (kendo.ui.Calendar) {
      kendo.ui.Calendar.prototype.options.messages = $.extend(
        true,
        kendo.ui.Calendar.prototype.options.messages,
        {
          weekColumnHeader: ''
        }
      )
    }

    /* Alert */

    if (kendo.ui.Alert) {
      kendo.ui.Alert.prototype.options.messages = $.extend(
        true,
        kendo.ui.Alert.prototype.options.localization,
        {
          okText: 'OK'
        }
      )
    }

    /* Confirm */

    if (kendo.ui.Confirm) {
      kendo.ui.Confirm.prototype.options.messages = $.extend(
        true,
        kendo.ui.Confirm.prototype.options.localization,
        {
          okText: 'OK',
          cancel: 'Cancel'
        }
      )
    }

    /* Prompt */
    if (kendo.ui.Prompt) {
      kendo.ui.Prompt.prototype.options.messages = $.extend(
        true,
        kendo.ui.Prompt.prototype.options.localization,
        {
          okText: 'OK',
          cancel: 'Cancel'
        }
      )
    }

    /* DateInput */
    if (kendo.ui.DateInput) {
      kendo.ui.DateInput.prototype.options.messages = $.extend(
        true,
        kendo.ui.DateInput.prototype.options.messages,
        {
          year: 'year',
          month: 'month',
          day: 'day',
          weekday: 'day of the week',
          hour: 'hours',
          minute: 'minutes',
          second: 'seconds',
          dayperiod: 'AM/PM'
        }
      )
    }

    /* List messages */

    if (kendo.ui.List) {
      kendo.ui.List.prototype.options.messages = $.extend(
        true,
        kendo.ui.List.prototype.options.messages,
        {
          clear: 'clear',
          noData: 'No data found.'
        }
      )
    }

    /* DropDownList messages */

    if (kendo.ui.DropDownList) {
      kendo.ui.DropDownList.prototype.options.messages = $.extend(
        true,
        kendo.ui.DropDownList.prototype.options.messages,
        kendo.ui.List.prototype.options.messages
      )
    }

    /* ComboBox messages */

    if (kendo.ui.ComboBox) {
      kendo.ui.ComboBox.prototype.options.messages = $.extend(
        true,
        kendo.ui.ComboBox.prototype.options.messages,
        kendo.ui.List.prototype.options.messages
      )
    }

    /* AutoComplete messages */

    if (kendo.ui.AutoComplete) {
      kendo.ui.AutoComplete.prototype.options.messages = $.extend(
        true,
        kendo.ui.AutoComplete.prototype.options.messages,
        kendo.ui.List.prototype.options.messages
      )
    }

    /* MultiColumnComboBox messages */

    if (kendo.ui.MultiColumnComboBox) {
      kendo.ui.MultiColumnComboBox.prototype.options.messages = $.extend(
        true,
        kendo.ui.MultiColumnComboBox.prototype.options.messages,
        kendo.ui.List.prototype.options.messages
      )
    }

    /* DropDownTree messages */

    if (kendo.ui.DropDownTree) {
      kendo.ui.DropDownTree.prototype.options.messages = $.extend(
        true,
        kendo.ui.DropDownTree.prototype.options.messages,
        {
          singleTag: 'item(s) selected',
          clear: 'clear',
          deleteTag: 'delete',
          noData: 'No data found.'
        }
      )
    }

    /* MultiSelect messages */

    if (kendo.ui.MultiSelect) {
      kendo.ui.MultiSelect.prototype.options.messages = $.extend(
        true,
        kendo.ui.MultiSelect.prototype.options.messages,
        {
          singleTag: 'item(s) selected',
          clear: 'clear',
          deleteTag: 'delete',
          noData: 'No data found.'
        }
      )
    }
  },
  he: () => {
    /* Validator */
    if (kendo.ui.Validator) {
      kendo.ui.Validator.prototype.options.messages = $.extend(
        true,
        kendo.ui.Validator.prototype.options.messages,
        {
          required: '{0} נדרש',
          pattern: '{0} לא תקין',
          min: '{0} אמור להיות גדול או שווה ל {1}',
          max: '{0} אמור להיות קטן או שווה ל {1}',
          step: '{0} לא תקין',
          email: '{0}  אימייל לא תקין',
          url: '{0} לא תקין URL',
          date: '{0} תאריך לא תקין',
          dateCompare: 'משווה נתונים'
        }
      )
    }

    /* TreeView */

    if (kendo.ui.TreeView) {
      kendo.ui.TreeView.prototype.options.messages = $.extend(
        true,
        kendo.ui.TreeView.prototype.options.messages,
        {
          loading: 'אנא המתן...',
          requestFailed: 'הבקשה נכשלה',
          retry: 'נסה שנית'
        }
      )
    }

    /* Upload */

    if (kendo.ui.Upload) {
      kendo.ui.Upload.prototype.options.localization = $.extend(
        true,
        kendo.ui.Upload.prototype.options.localization,
        {
          select: 'בחר...',
          cancel: 'בטל',
          retry: 'נסה שנית',
          remove: 'הסר',
          uploadSelectedFiles: 'בחר קבצים להעלות לשרת',
          dropFilesHere: 'גרור לכאן את הקבצים',
          statusUploading: 'מעלה לשרת',
          statusUploaded: 'הקבצים הועלו בהצלחה',
          statusWarning: 'אזהרה',
          statusFailed: 'נכשל',
          headerStatusUploading: 'מעלה...',
          headerStatusUploaded: 'העלאת קבצים'
        }
      )
    }

    /* Slider */

    if (kendo.ui.Slider) {
      kendo.ui.Slider.prototype.options = $.extend(
        true,
        kendo.ui.Slider.prototype.options,
        {
          increaseButtonTitle: 'יותר',
          decreaseButtonTitle: 'פחות'
        }
      )
    }

    /* Numeric text box */

    if (kendo.ui.NumericTextBox) {
      kendo.ui.NumericTextBox.prototype.options = $.extend(
        true,
        kendo.ui.NumericTextBox.prototype.options,
        {
          upArrowText: 'למעלה',
          downArrowText: 'למטה'
        }
      )
    }

    /* Gantt */

    if (kendo.ui.Gantt) {
      kendo.ui.Gantt.prototype.options.messages = $.extend(
        true,
        kendo.ui.Gantt.prototype.options.messages,
        {
          actions: {
            addChild: 'הוסף ילד',
            append: 'הוסף',
            insertAfter: 'הכנס אחרי',
            insertBefore: 'הכנס לפני',
            pdf: 'קובץ PDF'
          },
          cancel: 'בטל',
          deleteDependencyWindowTitle: 'מחיקה',
          deleteTaskWindowTitle: 'מחיקה',
          destroy: 'Delete',
          editor: {
            assingButton: 'שייך',
            editorTitle: 'כותרת',
            end: 'סיים',
            percentComplete: 'אחוז שהושלם',
            resources: 'משאבים',
            resourcesEditorTitle: 'כותרת עורך',
            resourcesHeader: 'כותרת כללית',
            start: 'התחל',
            title: 'כותרת',
            unitsHeader: 'מידה'
          },
          save: 'שמור',
          views: {
            day: 'יום',
            end: 'סוף',
            month: 'חודש',
            start: 'התחלה',
            week: 'שבוע',
            year: 'שנה'
          }
        }
      )
    }

    /* File browser */

    if (kendo.ui.FileBrowser) {
      kendo.ui.FileBrowser.prototype.options.messages = $.extend(
        true,
        kendo.ui.FileBrowser.prototype.options.messages,
        {
          uploadFile: 'העלאה',
          orderBy: 'סדר לפי',
          orderByName: 'שם',
          orderBySize: 'גודל',
          directoryNotFound: 'תיקייה בשם זה לא נמצאה',
          emptyFolder: 'תיקייה ריקה',
          deleteFile: 'האם למחוק "{0}"?',
          invalidFileType: 'הקובץ הנבחר "{0}"לא חוקי, הקבצים הנתמכים הם {1}.',
          overwriteFile: 'קובץ בשם "{0}" כבר קיים בתיקייה. האם לדרוס אותו?',
          dropFilesHere: 'גרור קבצים לכאן כדי להעלות',
          search: 'חפש'
        }
      )
    }

    /* Flat color picker */

    if (kendo.ui.FlatColorPicker) {
      kendo.ui.FlatColorPicker.prototype.options.messages = $.extend(
        true,
        kendo.ui.FlatColorPicker.prototype.options.messages,
        {
          apply: 'עדכן',
          cancel: 'בטל'
        }
      )
    }

    /* Color picker */

    if (kendo.ui.ColorPicker) {
      kendo.ui.ColorPicker.prototype.options.messages = $.extend(
        true,
        kendo.ui.ColorPicker.prototype.options.messages,
        {
          apply: 'עדכן',
          cancel: 'בטל'
        }
      )
    }

    /* Filter menu operator messages */

    if (kendo.ui.FilterMenu) {
      kendo.ui.FilterMenu.prototype.options.operators = $.extend(
        true,
        kendo.ui.FilterMenu.prototype.options.operators,
        {
          string: {
            eq: 'שווה ל',
            neq: 'לא שווה ל',
            startswith: 'מתחיל עם',
            contains: 'מכיל',
            doesnotcontain: 'לא מכיל',
            endswith: 'נגמר ב'
          },
          number: {
            eq: 'שווה ל',
            neq: 'לא שווה ל',
            gte: 'גדול או שווה מ',
            gt: 'גדול מ',
            lte: 'קטן או שווה ל',
            lt: 'קטן מ'
          },
          date: {
            eq: 'שווה ל',
            neq: 'לא שווה ל',
            gte: 'אחרי או שווה ל',
            gt: 'אחרי',
            lte: 'לפני או שווה ל',
            lt: 'לפני'
          },
          enums: {
            eq: 'שווה',
            neq: 'לא שווה'
          }
        }
      )
    }

    /* Filter cell operator messages */

    if (kendo.ui.FilterCell) {
      kendo.ui.FilterCell.prototype.options.operators = $.extend(
        true,
        kendo.ui.FilterCell.prototype.options.operators,
        {
          string: {
            eq: 'שווה ל',
            neq: 'לא שווה ל',
            startswith: 'מתחיל עם',
            contains: 'מכיל',
            doesnotcontain: 'לא מכיל',
            endswith: 'נגמר ב'
          },
          number: {
            eq: 'שווה ל',
            neq: 'לא שווה ל',
            gte: 'גדול או שווה מ',
            gt: 'גדול מ',
            lte: 'קטן או שווה ל',
            lt: 'קטן מ'
          },
          date: {
            eq: 'שווה ל',
            neq: 'לא שווה ל',
            gte: 'אחרי או שווה ל',
            gt: 'אחרי',
            lte: 'לפני או שווה ל',
            lt: 'לפני'
          },
          enums: {
            eq: 'שווה',
            neq: 'לא שווה'
          }
        }
      )
    }

    /* ColumnMenu messages */

    if (kendo.ui.ColumnMenu) {
      kendo.ui.ColumnMenu.prototype.options.messages = $.extend(
        true,
        kendo.ui.ColumnMenu.prototype.options.messages,
        {
          sortAscending: 'מיון עולה',
          sortDescending: 'מיון יורד',
          filter: 'סינון',
          columns: 'עמודות',
          done: 'בצע',
          settings: 'הגדרות',
          lock: 'קבע',
          unlock: 'שחרר'
        }
      )
    }

    /* RecurrenceEditor messages */

    if (kendo.ui.RecurrenceEditor) {
      kendo.ui.RecurrenceEditor.prototype.options.messages = $.extend(
        true,
        kendo.ui.RecurrenceEditor.prototype.options.messages,
        {
          daily: {
            interval: 'מחזוריות',
            repeatEvery: 'חזור כל: '
          },
          end: {
            after: 'לאחר ',
            occurrence: 'מחזוריות',
            label: 'תגית:',
            never: 'אף פעם',
            on: 'ב ',
            mobileLabel: 'נייד'
          },
          frequencies: {
            daily: 'יומי',
            monthly: 'חודשי',
            never: 'אף פעם',
            weekly: 'שבועי',
            yearly: 'שנתי'
          },
          monthly: {
            day: 'יום ',
            interval: ' מחזוריות',
            repeatEvery: 'חזרה כל: ',
            repeatOn: 'חזור ב: '
          },
          offsetPositions: {
            first: 'ראשון',
            fourth: 'רביעי',
            last: 'אחרון',
            second: 'שני',
            third: 'שלישי'
          },
          weekly: {
            repeatEvery: 'חזור כל: ',
            repeatOn: 'חזור ב: ',
            interval: 'מחזוריות'
          },
          yearly: {
            of: ' ב ',
            repeatEvery: 'חזור כל: ',
            repeatOn: 'חזור ב: ',
            interval: 'מחזוריות'
          },
          weekdays: {
            day: 'יום',
            weekday: 'בשבוע',
            weekend: 'בסוף שבוע'
          }
        }
      )
    }

    /* Grid messages */

    if (kendo.ui.Grid) {
      kendo.ui.Grid.prototype.options.messages = $.extend(
        true,
        kendo.ui.Grid.prototype.options.messages,
        {
          commands: {
            cancel: 'בטל',
            canceledit: 'בטל עריכה',
            create: 'צור חדש',
            destroy: 'מחק',
            edit: 'עריכה',
            save: 'שמור',
            select: 'בחר',
            update: 'עדכן'
          },
          editable: {
            cancelDelete: 'בטל מחיקה',
            confirmation: 'האם הנך בטוח שברונך לבצע זאת?',
            confirmDelete: 'אשר מחיקה'
          }
        }
      )
    }

    /* Pager messages */

    if (kendo.ui.Pager) {
      kendo.ui.Pager.prototype.options.messages = $.extend(
        true,
        kendo.ui.Pager.prototype.options.messages,
        {
          allPages: 'All',
          page: 'עמוד',
          display: '{0} - {1} מתוך {2} פריטים',
          of: 'מתוך {0}',
          empty: 'אין פריטים להצגה',
          refresh: 'רענן',
          first: 'לעמוד הראשון',
          itemsPerPage: 'פריטים בעמוד',
          last: 'לעמוד האחרון',
          next: 'לעמוד הבא',
          previous: 'לעמוד הקודם',
          morePages: 'עמודים נוספים'
        }
      )
    }

    /* TreeListPager messages */

    if (kendo.ui.TreeListPager) {
      kendo.ui.TreeListPager.prototype.options.messages = $.extend(
        true,
        kendo.ui.TreeListPager.prototype.options.messages,
        {
          allPages: 'All',
          page: 'עמוד',
          display: '{0} - {1} מתוך {2} פריטים',
          of: 'מתוך {0}',
          empty: 'אין פריטים להצגה',
          refresh: 'רענן',
          first: 'לעמוד הראשון',
          itemsPerPage: 'פריטים בעמוד',
          last: 'לעמוד האחרון',
          next: 'לעמוד הבא',
          previous: 'לעמוד הקודם',
          morePages: 'עמודים נוספים'
        }
      )
    }

    /* FilterMenu messages */

    if (kendo.ui.FilterMenu) {
      kendo.ui.FilterMenu.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterMenu.prototype.options.messages,
        {
          filter: 'סנן',
          and: 'וגם',
          clear: 'נקה',
          info: 'הראה פריטים עם ערך ש',
          title: 'הראה פריטים עם ערך ש',
          selectValue: '-בחר ערך-',
          isFalse: 'לא',
          isTrue: 'כן',
          or: 'או',
          cancel: 'בטל',
          operator: 'אופרטור',
          value: 'ערך'
        }
      )
    }

    /* FilterCell messages */

    if (kendo.ui.FilterCell) {
      kendo.ui.FilterCell.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterCell.prototype.options.messages,
        {
          filter: 'סנן',
          clear: 'נקה',
          isFalse: 'לא',
          isTrue: 'כן',
          operator: 'אופרטור'
        }
      )
    }

    /* FilterMultiCheck messages */

    if (kendo.ui.FilterMultiCheck) {
      kendo.ui.FilterMultiCheck.prototype.options.messages = $.extend(
        true,
        kendo.ui.FilterMultiCheck.prototype.options.messages,
        {
          checkAll: 'בחר הכל',
          clear: 'נקה',
          filter: 'סנן',
          search: 'חפש'
        }
      )
    }

    /* Groupable messages */

    if (kendo.ui.Groupable) {
      kendo.ui.Groupable.prototype.options.messages = $.extend(
        true,
        kendo.ui.Groupable.prototype.options.messages,
        {
          empty: 'כדי לקבץ לפי העמודה גרור כותרת העמודה לכאן'
        }
      )
    }

    /* Editor messages */

    if (kendo.ui.Editor) {
      kendo.ui.Editor.prototype.options.messages = $.extend(
        true,
        kendo.ui.Editor.prototype.options.messages,
        {
          bold: 'מודגש',
          createLink: 'הכנסת קישור',
          fontName: 'בחירת משפחת גופנים',
          fontNameInherit: '(גופן בירושה)',
          fontSize: 'בחר גודל פונט',
          fontSizeInherit: '(גודל בירושה)',
          formatBlock: 'פורמט',
          indent: 'Indent',
          insertHtml: 'הכנסת HTML',
          insertImage: 'הכנסת תמונה',
          insertOrderedList: 'Insert ordered list',
          insertUnorderedList: 'Insert unordered list',
          italic: 'נטוי',
          justifyCenter: 'ממורכז',
          justifyFull: 'מיושר',
          justifyLeft: 'ישור לשמאל',
          justifyRight: 'ישור לימין',
          outdent: 'הזחה',
          strikethrough: 'קו חוצה',
          style: 'סגנון',
          subscript: 'ציון תחתי',
          superscript: 'ציון עילי',
          underline: 'קו תחתון',
          unlink: 'הסרת קישור',
          deleteFile: 'האם בוודאות ברצונך למחוק "{0}"?',
          directoryNotFound: 'תיקייה בשם זה כבר קיימת.',
          emptyFolder: 'תיקייה ריקה',
          invalidFileType:
            'הקובץ הנבחר "{0}" אינו חוקי. סוגי קבצים נתמכים {1}.',
          orderBy: 'מיון לפי:',
          orderByName: 'שם',
          orderBySize: 'גודל',
          overwriteFile:
            'קובץ עם השם "{0}" כבר קיים במחיצה הנוכחית. האם לדרוס אותו?',
          uploadFile: 'העלה',
          backColor: 'צבע רקע',
          foreColor: 'צבע',
          dialogButtonSeparator: 'או',
          dialogCancel: 'ביטול',
          dialogInsert: 'הכנסה',
          imageAltText: 'Alternate text',
          imageWebAddress: 'כתובת אינטרנט',
          linkOpenInNewWindow: 'Open link in new window',
          linkText: 'Text',
          linkToolTip: 'ToolTip',
          linkWebAddress: 'Web address',
          search: 'חפש',
          createTable: 'צור טבלה',
          dropFilesHere: 'גרור לכאן קבצים',
          addColumnLeft: 'הוסף עמודה לשמאל',
          addColumnRight: 'הוסף עמודה לימין',
          addRowAbove: 'הוסף שורה מעל',
          addRowBelow: 'הוסף שורה מתחת',
          deleteColumn: 'מחק עמודה',
          deleteRow: 'מחק שורה',
          styles: 'סגנונות',
          formatting: 'פורמט',
          viewHtml: 'ראה HTML',
          dialogUpdate: 'עדכן',
          insertFile: 'צרף קובץ'
        }
      )
    }

    /* Upload messages */

    if (kendo.ui.Upload) {
      kendo.ui.Upload.prototype.options.localization = $.extend(
        true,
        kendo.ui.Upload.prototype.options.localization,
        {
          cancel: 'בטל',
          dropFilesHere: 'גרור קבצים לכאן כדי להעלות',
          remove: 'הסר',
          retry: 'חזור על הפעולה שוב',
          select: 'העלאה',
          statusFailed: 'הפעולה כשלה',
          statusUploaded: 'הפעולה הצליחה',
          statusUploading: 'מעלה את הקבצים',
          uploadSelectedFiles: 'העלה קבצים שבחרת',
          headerStatusUploaded: 'בוצע',
          headerStatusUploading: 'מעלה קבצים'
        }
      )
    }

    /* Scheduler messages */

    if (kendo.ui.Scheduler) {
      kendo.ui.Scheduler.prototype.options.messages = $.extend(
        true,
        kendo.ui.Scheduler.prototype.options.messages,
        {
          allDay: 'כל יום',
          cancel: 'בטל',
          date: 'תאריך',
          destroy: 'מחק',
          editable: {
            confirmation: 'האם הנך בטוח כי ברצונך למחוק אירוע זה ?'
          },
          editor: {
            allDayEvent: 'אירוע של יום שלם',
            description: 'תיאור',
            editorTitle: 'אירוע',
            end: 'סיום',
            endTimezone: 'After timezone',
            repeat: 'ארוע חוזר',
            separateTimezones: 'Use different time zones start and end',
            start: 'התחלה',
            startTimezone: 'זמן מקומי',
            timezone: ' ',
            timezoneEditorButton: 'Classes zone',
            timezoneEditorTitle: 'Classes zone',
            title: 'כותרת',
            noTimezone: 'No hours zone'
          },
          event: 'אירוע',
          recurrenceMessages: {
            deleteRecurring:
              'האם הנך בטוח כי ברצונך למחוק אירוע זה או את כל הסדרה ?',
            deleteWindowOccurrence: 'מחיקת אירוע בודד',
            deleteWindowSeries: 'מחק סדרת ארועים',
            deleteWindowTitle: 'מחק אירוע מחזורי',
            editRecurring:
              'האם הנך בטוח כי ברצונך לשנות אירוע זה או את כל הסדרה ?',
            editWindowOccurrence: 'שינוי אירוע בודד',
            editWindowSeries: 'שינוי סדרת אירועים',
            editWindowTitle: 'שינוי אירוע מחזורי'
          },
          save: 'שמור',
          time: 'שעה',
          today: 'היום',
          views: {
            agenda: 'סדר יום',
            day: 'יום',
            month: 'חודש',
            week: 'שבוע',
            workWeek: 'שבוע עבודה'
          },
          deleteWindowTitle: 'מחיקה',
          showFullDay: 'הצג יום מלא',
          showWorkDay: 'הצג יום עבודה'
        }
      )
    }

    /* Dialog */

    if (kendo.ui.Dialog) {
      kendo.ui.Dialog.prototype.options.messages = $.extend(
        true,
        kendo.ui.Dialog.prototype.options.localization,
        {
          close: 'קרוב'
        }
      )
    }

    /* Alert */

    if (kendo.ui.Alert) {
      kendo.ui.Alert.prototype.options.messages = $.extend(
        true,
        kendo.ui.Alert.prototype.options.localization,
        {
          okText: 'בסדר'
        }
      )
    }

    /* Confirm */

    if (kendo.ui.Confirm) {
      kendo.ui.Confirm.prototype.options.messages = $.extend(
        true,
        kendo.ui.Confirm.prototype.options.localization,
        {
          okText: 'בסדר',
          cancel: 'בטל'
        }
      )
    }

    /* Prompt */
    if (kendo.ui.Prompt) {
      kendo.ui.Prompt.prototype.options.messages = $.extend(
        true,
        kendo.ui.Prompt.prototype.options.localization,
        {
          okText: 'בסדר',
          cancel: 'בטל'
        }
      )
    }
  }
}

module.exports = kendoSetLang
