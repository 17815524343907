/* global angular */
// import WorkflowSessionTimeLogReportController from '../modules/main/workflow/session/templates/workflow-session.time-log-report.controller.js'

const debug = require('debug')('nextplus:timeLogReportService')

/** @ngInject */
function timeLogReportService (
  $rootScope,
  TimeLogReport,
  $mdDialog,
  $interval,
  $ocLazyLoad,
  nextplusSocket
) {
  let popupIsOpened = false
  const closeTimeLogsApprovalPopUp = function (data) {
    if (popupIsOpened) {
      $mdDialog.hide()
    }
    $rootScope.currentUser.lastTimeRecordsApproval = new Date(
      data.lastTimeRecordsApproval
    )
  }
  nextplusSocket.promiseState.then(() => {
    nextplusSocket.angularSocket.on(
      'lastTimeRecordsApproval',
      closeTimeLogsApprovalPopUp
    )
  })

  const intervalIfNeedsToOpenTimeRecordDialog = async function () {
    const appSettings = $rootScope.appSettings
    const workingDayEndTime = appSettings.workingDayEndTime
    const displayRecordsApprovalPopup =
      appSettings.displayRecordsApprovalPopup &&
      $rootScope.currentUser.displayRecordsApprovalPopup
    const lastTimePopUpDisplayed =
      getLastTimePopUpWasDisplayed(workingDayEndTime)

    // Check if the popup needs to be displayed
    if (
      lastTimePopUpDisplayed >
      new Date($rootScope.currentUser.lastTimeRecordsApproval)
    ) {
      if (displayRecordsApprovalPopup) {
        if (!popupIsOpened) {
          popupIsOpened = true
          displayTimeRecordsPopup(
            $rootScope.currentUser.lastTimeRecordsApproval
          )
        }
      } else {
        await updateApproveTime()
      }
    }

    // Check every second if the popup needs to be displayed
    const recordsPopupInterval = $interval(async () => {
      if (!$rootScope.currentUser) {
        $interval.cancel(recordsPopupInterval)
        return
      }
      const today = new Date()
      const currentTime =
        today.getHours().toString().padStart(2, '0') +
        ':' +
        today.getMinutes().toString().padStart(2, '0')
      const userApprovalDate = new Date(
        $rootScope.currentUser.lastTimeRecordsApproval
      )
      if (workingDayEndTime === currentTime) {
        if (displayRecordsApprovalPopup) {
          if (!popupIsOpened) {
            popupIsOpened = true
            await displayTimeRecordsPopup(userApprovalDate)
          }
        } else {
          await updateApproveTime()
        }
      }
    }, 1000)
  }

  const updateApproveTime = async function updateApproveTime () {
    const approvalTime = await TimeLogReport.setCloseTimeLogsDialogTimeForUser()
      .$promise
    $rootScope.currentUser.lastTimeRecordsApproval = approvalTime.now.$data
  }
  const getLastTimePopUpWasDisplayed = function getLastTimePopUpWasDisplayed (
    workingDayEndTime
  ) {
    const timeArray = $rootScope.appSettings.workingDayEndTime.split(':')
    const lastWorkingDay = getLastWorkingDay(
      $rootScope.appSettings.standardWorkingDays,
      workingDayEndTime
    )
    return new Date(
      new Date(lastWorkingDay.setHours(timeArray[0], timeArray[1])).setSeconds(
        0
      )
    )
  }

  function getLastWorkingDay (workingDays, workingDayEndTime) {
    const weekday = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ]

    // Check if at least one day is marked as a working day
    const atLeastOneWorkingDay = Object.values(workingDays).some(day => day)

    // Set the initial date to yesterday
    const lastWorkingDay = new Date()
    const today = new Date()
    const currentTime =
      today.getHours().toString().padStart(2, '0') +
      ':' +
      today.getMinutes().toString().padStart(2, '0')
    if (workingDayEndTime > currentTime) {
      lastWorkingDay.setDate(lastWorkingDay.getDate() - 1)
    }

    // Get the day of the week for the initial date
    let day = weekday[lastWorkingDay.getDay()]

    // If at least one day is marked as a working day,
    // keep decrementing the date by one day until we reach a working day
    if (atLeastOneWorkingDay) {
      while (!workingDays[day]) {
        lastWorkingDay.setDate(lastWorkingDay.getDate() - 1)
        day = weekday[lastWorkingDay.getDay()]
      }
    }

    return lastWorkingDay
  }

  const displayTimeRecordsPopup = async function displayTimeRecordsPopup (
    lastTimeRecordsApproval
  ) {
    const timeLogRecords = await TimeLogReport.find({
      filter: {
        where: {
          and: [
            { userId: $rootScope.currentUser.id },
            {
              modified: {
                gte: lastTimeRecordsApproval
              }
            }
          ]
        },
        limit: 1
      }
    }).$promise
    if (timeLogRecords?.length > 0) {
      import(
        /* webpackChunkName: "time-logging.module" */ 'app/modules/main/time-logging/time-logging.module.js'
      ).then(mod => {
        $ocLazyLoad.inject(mod.default)
        $mdDialog
          .show({
            controller: 'TimeLoggingController',
            template: require('../modules/main/workflow/session/templates/workflow-session.time-log-report.template.html'),
            parent: angular.element(document.body),
            multiple: true,
            clickOutsideToClose: false,
            escapeToClose: true,
            fullscreen: true,
            resolve: {
              ResolvedSessionData: () => {
                return null
              },
              ResolvedActiveTimeLog: (TimeLogReport, $rootScope) => {
                return new Promise(async (resolve, reject) => {
                  const timeLogs = await TimeLogReport.find({
                    filter: {
                      limit: 1,
                      where: {
                        end: { eq: null },
                        userId: $rootScope.currentUser.id
                      }
                    }
                  }).$promise
                  if (timeLogs.length > 0) {
                    resolve(timeLogs[0].toJSON())
                  } else {
                    resolve(null)
                  }
                })
              },
              ResolvedBaseFilter: $rootScope => {
                return new Promise(async (resolve, reject) => {
                  const baseFilter = {
                    order: 'start DESC',
                    where: { end: { neq: null } }
                  }
                  baseFilter.where.modified = {
                    gte: lastTimeRecordsApproval
                  }
                  baseFilter.where.userId = $rootScope.currentUser.id
                  resolve(baseFilter)
                })
              },
              ResolvedUsers: UserModel =>
                UserModel.find({
                  filter: {
                    fields: {
                      id: true,
                      displayName: true
                    }
                  }
                }).$promise
            }
          })
          .then(async approve => {
            if (approve) {
              await updateApproveTime()
            }
          })
          .catch(err => {
            debug('dialog error', err)
          })
      })
    } else {
      const approvalTime =
        await TimeLogReport.setCloseTimeLogsDialogTimeForUser().$promise
      $rootScope.currentUser.lastTimeRecordsApproval = approvalTime.now.$data
    }
  }
  return {
    intervalIfNeedsToOpenTimeRecordDialog
  }
}

module.exports = timeLogReportService
