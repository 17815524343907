/** @ngInject */

function customTranslateLoader ($http, $q, $rootScope) {
  // return loaderFn
  return function (options) {
    var deferred = $q.defer(),
      translations

    if (!window.nextplus) {
      window.nextplus = {}
    }

    if (window.nextplus[options.key]) {
      translations = window.nextplus[options.key]
      deferred.resolve(translations)
    } else {
      $http.get(`api/getTranslations`).then(response => {
        if (!window.nextplus) window.nextplus = {}
        _.mapKeys(response.data, function (value, key) {
          window.nextplus[key] = value
        })

        translations = window.nextplus[options.key]
        deferred.resolve(translations)
      })
    }

    return deferred.promise
  }
}

module.exports = customTranslateLoader
