/** @ngInject */
function FormlyValidations ($translate) {
  {
    const noComma = {
      expression: function ($viewValue, $modelValue, scope) {
        var value = $modelValue || $viewValue
        return !/[,]+/.test(value)
      },
      message: () => $translate.instant('VALIDATIONS.NO_COMMA')
    }
    return {
      noComma
    }
  }
}

module.exports = FormlyValidations
