/** @ngInject */
function IntercomService ($window) {
  {
    let trackEvent = (name, metadata = {}) => {
      if (!$window.Intercom) {
        return
      }
      if (!name) {
        console.error('Event name is required')
        return
      }
      $window.Intercom('trackEvent', name, metadata)
    }

    return {
      trackEvent
    }
  }
}

module.exports = IntercomService
