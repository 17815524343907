/*eslint-disable*/
/** @ngInject */

function KendoFilterHelper(
  $rootScope,
  $translate,
  htmlWork,
  DateTimeFormatService
) {
  const overwriteEditor = function overwriteEditor() {
    if (kendo.ui.Filter) {
      const editors = {
        number:
          "<input type='text' title='#=field#' data-#=ns#role='numerictextbox' data-#=ns#bind='value: value'/>",
        string:
          "<input type='text' title='#=field#' class='k-textbox' data-#=ns#bind='value: value'/>",
        boolean:
          "<input class='k-checkbox' data-role='checkbox' data-#=ns#bind='checked: value' type='checkbox'>",
        date: "<input type='text' title='#=field#' data-#=ns#role='datepicker' data-#=ns#bind='value: value'/>"
      }

      const expressionItemTemplate = `<li class='k-filter-item'><div class='k-filter-toolbar'><div class='k-toolbar' id='#=uid#'><div class='k-filter-toolbar-item k-filter-field'><select data-#=ns#bind='value: field' title='#=fieldsLabel#' class='k-filter-dropdown' data-auto-width='true' data-#=ns#role='dropdownlist'>#for(var current in fields){#<option value='#=fields[current].name#'>#=fields[current].label#</option>#}#</select></div><div class='k-filter-toolbar-item k-filter-operator'></div><div class='k-filter-toolbar-item k-filter-value'></div><div class='k-filter-toolbar-item'><button data-role='button' class='k-button-flat k-button k-button-icon' role='button' title='#=close#' aria-label='#=close#' aria-disabled='false' tabindex='0'><span class='k-icon k-i-close'></span></button></div></div></div></li>`
      const expressionDeletedItemTemplate = `<li class='k-filter-item'><div class='k-filter-toolbar'><div class='k-toolbar' id='#=uid#' style='background-color: rgb(222, 53, 12);border-color: rgb(222, 53, 12);'><div class='k-filter-toolbar-item k-filter-field'><select data-#=ns#bind='value: field' title='#=fieldsLabel#' class='k-filter-dropdown' data-auto-width='true' data-#=ns#role='dropdownlist'>#for(var current in fields){#<option value='#=fields[current].name#'>#=fields[current].label#</option>#}#</select></div><div class='k-filter-toolbar-item k-filter-operator'></div><div class='k-filter-toolbar-item k-filter-value'></div><div class='k-filter-toolbar-item'><button data-role='button' class='k-button-flat k-button k-button-icon' role='button' title='#=close#' aria-label='#=close#' aria-disabled='false' tabindex='0'><span class='k-icon k-i-close'></span></button></div></div></div></li>`
      const findModel = function findModel(model, uid) {
        if (model.uid === uid) {
          return model
        }
        if (model.filters) {
          for (var i = 0; i < model.filters.length; i++) {
            var temp = findModel(model.filters[i], uid)
            if (temp) {
              return temp
            }
          }
        }
      }

      kendo.ui.Filter.fn._modelChange = function (e) {
        var that = this
        var container = that.element.find('[id=' + e.sender.uid + ']')

        that._showHideEditor(container, e.sender)
        if (e.field !== 'field') {
          if (e.field !== 'filters') {
            that._expressionChange(container, e.sender)
          }
          return
        }

        var newField = e.sender.field
        var parent = e.sender.parent()
        var field = that._fields[newField]
        var filterModel = that._addNewModel(parent, field)

        e.sender.unbind('change', that._modelChangeHandler)

        parent.remove(e.sender)

        that._addExpressionControls(container, field, filterModel)

        that._expressionChange(container, e.sender)
      }

      kendo.ui.Filter.fn._showHideEditor = function (container, model) {
        const that = this
        if (model.logic) {
          return
        }
        const operator = model.operator
        const field = this._fields[model.field]
        if (field) {
          const type = field.type
          const editorContainer = container.find('.k-filter-toolbar-item:eq(2)')
          if (operator === 'is_null' || operator === 'is_not_null') {
            editorContainer.hide()
          } else {
            if (type === 'number') {
              const extraField = editorContainer.find('.k-numerictextbox')[1]
              if (
                (extraField && model.operator === 'between') ||
                model.operator === 'not_between'
              ) {
                extraField.style.display = ''
              } else if (extraField) {
                extraField.style.display = 'none'
              }
            }
            editorContainer.show()
          }
        }
      }

      kendo.ui.Filter.fn._expressionChange = function (container, model) {
        var that = this
        var filter = that.filterModel.toJSON()
        var html = ''
        if (that.options.expressionPreview) {
          html = that._createPreview(filter)
          that._previewContainer.html(html)
        }

        that.trigger('change', { expression: filter })
        if (container) {
          const editorContainer = container.find('.k-filter-toolbar-item:eq(2)')
          const field = this._fields[model.field]
          if (
            field &&
            field.type === 'number' &&
            (model.operator === 'between' || model.operator === 'not_between')
          ) {
            const extraField = editorContainer.find('.k-numerictextbox')[1]
            extraField.style.display = ''
          } else if (field && field.type === 'number') {
            const extraField = editorContainer.find('.k-numerictextbox')[1]
            extraField.style.display = 'none'
          }
        }
      }

      kendo.ui.Filter.fn._addExpression = function (parentContainer, model) {
        var that = this
        var parentUID = parentContainer.attr('id')
        var itemsContainer = parentContainer
          .closest('.k-filter-toolbar')
          .next('ul.k-filter-lines')
        var field = model
          ? that._fields[model.field]
            ? that._fields[model.field]
            : that._defaultField
          : that._defaultField
        var expressionModel
        var itemHTML = ''

        if (model) {
          expressionModel = model
        } else {
          expressionModel = findModel(that.filterModel, parentUID)

          if (!expressionModel.filters) {
            expressionModel.set('filters', [])
          }

          expressionModel = that._addNewModel(expressionModel.filters, field)
        }

        if (!itemsContainer.length) {
          itemsContainer = $("<ul class='k-filter-lines'></ul>").appendTo(
            parentContainer.closest('li')
          )
        }
        if (!field.isInvalid) {
          itemHTML = $(
            kendo.template(expressionItemTemplate)({
              fields: _.filter(that._fields, { isInvalid: false }),
              operators: that.operators[field.type],
              close: that.options.messages.close,
              fieldsLabel: that.options.messages.fields,
              uid: expressionModel.uid,
              ns: kendo.ns
            })
          ).appendTo(itemsContainer)
        } else {
          itemHTML = $(
            kendo.template(expressionDeletedItemTemplate)({
              fields: that._fields,
              operators: that.operators[field.type],
              close: that.options.messages.close,
              fieldsLabel: that.options.messages.fields,
              uid: expressionModel.uid,
              ns: kendo.ns
            })
          ).appendTo(itemsContainer)
        }

        that._addExpressionControls(
          itemHTML.find('.k-toolbar'),
          field,
          expressionModel
        )

        if (!model) {
          that._expressionChange()
        }
      }

      kendo.ui.Filter.fn._getFieldsInfo = function () {
        var that = this
        var fieldsCollection = that.options.fields.length
          ? that.options.fields
          : (that.options.dataSource.options.schema.model || {}).fields
        var fieldInfo

        that._fields = {}

        for (var field in fieldsCollection) {
          fieldInfo = fieldsCollection[field]
          fieldInfo = $.extend(
            true,
            {},
            {
              name: fieldInfo.name || field,
              editor:
                fieldInfo.editorTemplate || editors[fieldInfo.type || 'string'],
              defaultValue:
                fieldInfo.defaultValue ||
                fieldInfo.defaultValue === false ||
                fieldInfo.defaultValue === 0
                  ? fieldInfo.defaultValue
                  : '',
              type: fieldInfo.type || 'string',
              label: fieldInfo.label || fieldInfo.name || field,
              operators: fieldInfo.operators,
              previewFormat: fieldInfo.previewFormat,
              isInvalid: fieldInfo.isInvalid || false
            }
          )
          that._fields[fieldInfo.name] = fieldInfo
          if (!that._defaultField) {
            that._defaultField = fieldInfo
          }
        }
      }
    }
  }
  const messagesTranslate = {
    and: $translate.instant('FILTER.MESSAGES.AND'),
    or: $translate.instant('FILTER.MESSAGES.OR'),
    apply: $translate.instant('FILTER.MESSAGES.APPLY'),
    close: $translate.instant('FILTER.MESSAGES.CLOSE'),
    addExpression: $translate.instant('FILTER.MESSAGES.ADD_EXPRESSION'),
    fields: $translate.instant('FILTER.MESSAGES.FIELDS'),
    operators: $translate.instant('FILTER.MESSAGES.OPERATORS'),
    addGroup: $translate.instant('FILTER.MESSAGES.ADD_GROUP')
  }
  const defaultOperatorsArray = ['equal', 'not_equal', 'is_null', 'is_not_null']
  const booleanOperatorsArray = ['equal', 'not_equal']
  const systemTodayOperatorsArray = ['equal', 'not_equal', 'after', 'before']
  const stringOperatorsArray = [...defaultOperatorsArray, 'includes']
  const numberOperatorsArray = [
    ...defaultOperatorsArray,
    'less',
    'less_or_equal',
    'greater',
    'greater_or_equal',
    'between',
    'not_between'
  ]
  const stringOperators = Object.assign(
    ...stringOperatorsArray.map(key => ({
      [key]: $translate.instant(`FILTER.OPERATORS.${key}`)
    }))
  )

  const numberOperators = Object.assign(
    ...numberOperatorsArray.map(key => ({
      [key]: $translate.instant(`FILTER.OPERATORS.${key}`)
    }))
  )

  const booleanOperators = Object.assign(
    ...booleanOperatorsArray.map(key => ({
      [key]: $translate.instant(`FILTER.OPERATORS.${key}`)
    }))
  )

  const systemTodayOperators = Object.assign(
    ...systemTodayOperatorsArray.map(key => ({
      [key]: $translate.instant(`FILTER.OPERATORS.${key}`)
    }))
  )
  const arrayOperators = Object.assign(
    ...defaultOperatorsArray.map(key => ({
      [key]: $translate.instant(`FILTER.OPERATORS.${key}`)
    }))
  )
  const operators = {
    string: stringOperators,
    number: numberOperators,
    boolean: booleanOperators,
    date: systemTodayOperators,
    dateTime: systemTodayOperators,
    system_today: systemTodayOperators,
    array: arrayOperators
  }

  const hasFieldsFilter = function hasFieldsFilter(filters, haveField) {
    haveField = !!haveField

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].filters) {
        haveField = hasFieldsFilter(filters[i].filters, haveField)
      }
      if (filters[i].field) {
        return true
      }
    }

    return haveField
  }

  const getOperatorText = function (type, op) {
    switch (type) {
      case 'string':
        return stringOperators[op] || op
      case 'number':
        return numberOperators[op] || op
      case 'boolean':
        return booleanOperators[op] || op
      case 'date':
      case 'dateTime':
      case 'system_today':
        return systemTodayOperators[op] || op
      case 'array':
        return arrayOperators[op] || op
      default:
        console.error(`There is no type '${type}'`)
    }
  }

  const createPreviewHtml = function createPreviewHtml(
    filter = {},
    fieldsById = {}
  ) {
    let html = ''
    let createdField = false
    const haveFields = hasFieldsFilter(filter.filters || [])
    let childhtml = ''

    if (!filter.filters || !filter.filters.length || !haveFields) {
      return ''
    }
    html += '<span class="k-filter-preview-bracket">(</span>'
    for (let i = 0; i < filter.filters.length; i++) {
      let current = filter.filters[i]

      if (current.filters) {
        childhtml = createPreviewHtml(current, fieldsById)
        if (childhtml) {
          if (createdField) {
            html +=
              '<span class="k-filter-preview-operator"> ' +
              filter.logic.toLocaleUpperCase() +
              ' </span>'
          }
          createdField = true
        }
        html += childhtml
      }
      if (current.field) {
        const field = fieldsById[current.field]
        if (createdField) {
          html +=
            '<span class="k-filter-preview-operator"> ' +
            filter.logic.toLocaleUpperCase() +
            ' </span>'
        }
        createdField = true
        html +=
          '<span class="k-filter-preview-field">' + field.label + '</span>'
        html +=
          '<span class="k-filter-preview-criteria"> ' +
          getOperatorText(field.type, current.operator)
        if (current.operator.indexOf('is') < 0) {
          html += ' </span>'
          let value = current.value
          if (field.input === 'select') {
            const valueObject = field.values.find(
              v => v.value === current.value
            )
            value = valueObject ? valueObject.text : current.value
          } else {
            value = field.previewFormat
              ? kendo.toString(current.value, field.previewFormat)
              : current.value
          }
          html += `<span class='k-filter-preview-value'>'${htmlWork.htmlEncode(
            value
          )}'</span>`
        } else {
          html += '</span>'
        }
      }
    }
    html += '<span class="k-filter-preview-bracket">)</span>'
    return html
  }

  const filterInstance = function () {
    const filterOptions = {}
    const defaults = {
      expressionPreview: false,
      applyButton: false,
      fields: [],
      expression: {
        logic: 'and',
        filters: []
      }
    }
    return {
      setup: function (options, scope) {
        this.scope = scope
        this.options = options
        this.filterOptions = _.defaultsDeep(options, defaults)
        this.filterOptions.language = $rootScope.currentLang
        this.filterOptions.messages = messagesTranslate
        this.filterOptions.operators = operators
        overwriteEditor()
        const dateTimeFormats =
          DateTimeFormatService.getDateTimeFormatsForKendoGrid(true)
        this.filterOptions.fields.forEach(field => {
          if (field.input === 'select') {
            field.editorTemplate = function (container, options) {
              $(
                '<input flex data-bind="value: value" name="' +
                  options.field +
                  '"/>'
              )
                .appendTo(container)
                .kendoDropDownList({
                  dataValueField: 'value',
                  dataTextField: 'text',
                  dataSource: field.values
                })
            }
            field.operators = {
              string: stringOperators,
              array: arrayOperators
            }
          } else if (field.input === 'checkbox') {
            field.editorTemplate = function (container, options) {}
            field.operators = {
              string: {
                checked: $translate.instant(`FILTER.OPERATORS.checked`),
                not_checked: $translate.instant(`FILTER.OPERATORS.not_checked`)
              }
            }
          } else if (field.type === 'number') {
            field.editorTemplate = function (container, options) {
              $(
                '<input flex data-bind="value: value" name="' +
                  options.field +
                  '"/>'
              )
                .appendTo(container)
                .kendoNumericTextBox()
              $(
                '<input flex style="display: none;" data-bind="value: value2" name="' +
                  options.field +
                  'extra_field' +
                  '"/>'
              )
                .appendTo(container)
                .kendoNumericTextBox()
            }
          } else if (field.type === 'system_today') {
            field.operators = {
              system_today: {
                before: $translate.instant(`FILTER.OPERATORS.before`),
                after: $translate.instant(`FILTER.OPERATORS.after`)
              }
            }
            field.editorTemplate = function (container, options) {
              $(
                '<input flex data-bind="value: value" name="' +
                  options.field +
                  '"/>'
              )
                .appendTo(container)
                .kendoDatePicker({
                  format: dateTimeFormats.date,
                  value: new Date()
                })
            }
          } else if (field.type === 'date') {
            field.editorTemplate = function (container, options) {
              $(
                '<input flex data-bind="value: value" name="' +
                  options.field +
                  '"/>'
              )
                .appendTo(container)
                .kendoDatePicker({
                  format: dateTimeFormats.date
                })
            }
          } else if (field.type === 'dateTime') {
            field.editorTemplate = function (container, options) {
              $(
                '<input flex data-bind="value: value" name="' +
                  options.field +
                  '"/>'
              )
                .appendTo(container)
                .kendoDateTimePicker({
                  dateInput: true,
                  format: `${dateTimeFormats.date} ${dateTimeFormats.time}`
                })
            }
          } else if (field.type === 'boolean') {
            field.defaultValue = false
          } else if (field.input === 'array') {
            field.editorTemplate = function (container, options) {
              $(
                '<input flex data-bind="value: value" name="' +
                  options.field +
                  '"/>'
              ).appendTo(container)
              const args = {
                element: $(container).find('input')
              }
              if (field.template) {
                field.template(args)
              }
            }
          }
        })
      },
      removeGrouping: function removeGrouping() {
        $('.k-i-filter-add-group').parent().parent().remove()
      },
      setFields: function (fields) {
        this.filterOptions.fields = _.cloneDeep(fields)
      },
      getExpression: function () {
        return this.filterOptions.expression
      },
      setExpression: function (exp) {
        if (!exp.filters) {
          exp.filters = []
        }
        this.filterOptions.expression = exp
        this.instance.setOptions({ expression: exp })
      },
      filterOptions: this.filterOptions,
      instance: this.instance
    }
  }

  return {
    filterInstance,
    createPreviewHtml
  }
}
module.exports = KendoFilterHelper
