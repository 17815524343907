/* global _ */
const debug = require('debug')('nextplus:user-utils')

/** @ngInject */
function UserUtils (UserModel) {
  const validConstants = ['manager', 'owner']

  const normalizeUserPicker = async function normalizeUserPicker (
    potentialConstants = [],
    currentUserId = null,
    extraObject = {}
  ) {
    debug('Get user ids')
    let resolvedUserIds = new Set()
    const extraConstants = Object.keys(extraObject)
    for (let i = 0; i < potentialConstants.length; i++) {
      const potentialConstant = potentialConstants[i]
      if (validConstants.includes(potentialConstant)) {
        if (potentialConstant === 'owner') {
          resolvedUserIds.add(currentUserId)
        } else if (potentialConstant === 'manager') {
          const currentUser = await UserModel.findById({
            id: currentUserId,
            filter: {
              fields: { id: true, manager: true }
            }
          }).$promise
          if (!_.isNil(currentUser) && !_.isNil(currentUser.manager)) {
            resolvedUserIds.add(currentUser.manager)
          }
        }
      } else if (extraConstants.includes(potentialConstant)) {
        if (Array.isArray(extraObject[potentialConstant])) {
          resolvedUserIds = new Set([
            ...resolvedUserIds,
            ...extraObject[potentialConstant]
          ])
        } else {
          resolvedUserIds.add(extraObject[potentialConstant])
        }
      } else {
        // Is actually an user id
        resolvedUserIds.add(potentialConstant)
      }
    }
    return [...resolvedUserIds]
  }

  return {
    normalizeUserPicker
  }
}

module.exports = UserUtils
