/* global angular _ */
import VariantsSaveDialogController from '../common/dialog/variants.save.dialog.controller'
import VariantsLoadDialogController from '../common/dialog/variants.load.dialog.controller.js'
/** @ngInject */
function VariantsService ($rootScope, $mdDialog, $state, $translate, Variant) {
  const convetVariantForSave = function convetVariantForSave (page, data) {
    const { variantName } = data
    return {
      page,
      name: variantName,
      value: _.omit(data, ['variantName', 'variantId'])
    }
  }

  const saveVariant = async function saveVariant (page, data) {
    const variant = convetVariantForSave(page, data)
    variant.id = data.variantId
    await Variant.create(variant).$promise
  }

  const updateVariant = async function updateVariant (page, data) {
    const variant = convetVariantForSave(page, data)
    await Variant.prototype$patchAttributes({ id: data.variantId }, variant)
      .$promise
  }

  const openSaveVariantDialog = async function openSaveVariantDialog (
    page = '',
    edit = false,
    data = {}
  ) {
    return new Promise((resolve, reject) => {
      $mdDialog
        .show({
          controller: VariantsSaveDialogController,
          template: require('../common/dialog/variants.save.dialog.html'),
          parent: angular.element(document.body),
          targetEvent: '',
          locals: {
            editMode: edit || false,
            variantData: edit
              ? _.pick(data, ['variantName', 'variantId'])
              : null
          },
          multiple: true,
          fullscreen: true,
          escapeToClose: false,
          clickOutsideToClose: false
        })
        .then(
          async function (variantData) {
            $rootScope.loadingProgress = true
            try {
              // edit category with id to categoryName
              if (!_.isEmpty(variantData)) {
                if (edit) {
                  await updateVariant(page, { ...data, ...variantData })
                } else {
                  await saveVariant(page, { ...data, ...variantData })
                }
              }
            } catch (err) {
              console.error(err)
              $rootScope.showErrorToast(
                'NP-7900 - Create / Edit variant failed'
              )
            } finally {
              $rootScope.loadingProgress = false
            }
            resolve(variantData)
          },
          function () {
            /* eslint-disable-next-line */
            reject()
          }
        )
    })
  }

  const openLoadVariantDialog = async function openLoadVariantDialog (
    page = ''
  ) {
    return new Promise(resolve => {
      $mdDialog
        .show({
          controller: VariantsLoadDialogController,
          template: require('../common/dialog/variants.load.dialog.html'),
          parent: angular.element(document.body),
          targetEvent: '',
          locals: {
            page
          },
          multiple: true,
          fullscreen: true,
          escapeToClose: false,
          clickOutsideToClose: false
        })
        .then(
          async function (variantData) {
            resolve(variantData)
          },
          function () {
            resolve(null)
          }
        )
    })
  }

  const openDeleteVariantDialog = async function openDeleteVariantDialog (
    variantId,
    variantName
  ) {
    return new Promise(resolve => {
      $mdDialog
        .show(
          $mdDialog
            .confirm()
            .title(
              $translate.instant('VARIANT.DELETE_MESSAGE', {
                variantName
              })
            )
            .targetEvent()
            .clickOutsideToClose(true)
            .multiple(true)
            .parent(angular.element(document.body))
            .ok($translate.instant('BUTTONS.DELETE'))
            .cancel($translate.instant('BUTTONS.CANCEL'))
        )
        .then(
          function () {
            Variant.destroyById({ id: variantId })
              .$promise.then(() => {
                resolve()
              })
              .catch(err => {
                console.error(err)
                $rootScope.showErrorToast('NP-7010 - Delete variant failed')
                /* eslint-disable-next-line */
                reject()
              })
          },
          function () {
            /* eslint-disable-next-line */
            reject()
          }
        )
    })
  }

  return {
    openSaveVariantDialog,
    openLoadVariantDialog,
    openDeleteVariantDialog
  }
}

module.exports = VariantsService
