/* global angular */
/** @ngInject */

function NewVersionDialog ($mdDialog, $window, $interval) {
  const intervalTime = 5 * 60 * 1000
  let interval
  const setReminderInterval = function setReminderInterval () {
    interval = $interval(() => {
      if (!isDialogOpen()) {
        showDialog()
      }
    }, intervalTime)
  }
  const isDialogOpen = function isDialogOpen () {
    const isOpen = document.getElementById('newVersionDialog') !== null
    return isOpen
  }
  const showDialog = function showDialog () {
    if (interval) {
      $interval.cancel(interval)
      interval = null
    }
    if (
      document.getElementById('smgViewport') &&
      document.getElementById('smgViewport').style
    ) {
      document.getElementById('smgViewport').style.visibility = 'hidden'
    }
    $mdDialog
      .show({
        controller: function ($scope) {
          $scope.upgradeNow = () => {
            $window.location.reload()
          }
          $scope.remindLater = () => {
            setReminderInterval()
            $mdDialog.hide()
          }
        },
        multiple: true,
        skipHide: true,
        clickOutsideToClose: false,
        escapeToClose: false,
        fullscreen: true,
        template: require('../templates/dialogs/new-version.html'),
        parent: angular.element(document.body)
      })
      .then(
        function () {
          // when user click - update
        },
        function () {
          if (
            // Make sure there is no another opened dialog
            document.getElementById('smgViewport') &&
            document.getElementById('smgViewport').style &&
            document.getElementsByClassName('md-dialog-container').length === 0
          ) {
            document.getElementById('smgViewport').style.visibility = 'visible'
          }
          setReminderInterval()
        }
      )
  }
  return {
    showDialog,
    isDialogOpen
  }
}

module.exports = NewVersionDialog
