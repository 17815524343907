/* global Blob atob angular */
const UUID = require('uuid')

/** @ngInject */
function UploadService ($window, $mdDialog, Upload, Resource) {
  const platform = $window.navigator.platform
  const resumeChunkSize = '25MB'
  const uploadFile = function uploadFile (data, url, direct) {
    let defaultUrl = 'api/Resources/chunkUpload'
    const uploadId = UUID()
    data.uploadId = uploadId
    if (data.container) {
      defaultUrl = `${defaultUrl}/?container=${data.container}`
    }
    if (direct) {
      return Upload.upload({
        resumeChunkSize,
        url: url || defaultUrl,
        arrayKey: '',
        data
      })
    } else {
      return Upload.upload({
        resumeChunkSize,
        url: url || defaultUrl,
        arrayKey: '',
        data
      })
    }
  }

  const uploadBase64File = function uploadBase64File (encodedFile, options) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString
    if (encodedFile.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(encodedFile.split(',')[1])
    } else byteString = unescape(encodedFile.split(',')[1])

    // separate out the mime component
    const mimeString = encodedFile.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    const blob = new Blob([ia], { type: mimeString })

    return Upload.upload({
      url: 'api/Resources/upload',
      arrayKey: '',
      file: blob,
      // Additional data with file
      params: options
    })
  }

  const isWindowsOrMacintosh = function isWindowsOrMacintosh () {
    return platform.indexOf('Mac') > -1 || platform.indexOf('Win') > -1
  }

  const canStreamVideo = async function canStreamVideo () {
    const { BrowserStream } = require('../common/dialog/browser-stream')
    const browserStream = new BrowserStream()
    const canStreamVideo = await browserStream.canStreamVideo()
    return isWindowsOrMacintosh() && canStreamVideo
  }

  const openCameraCaptureDialog = function openCameraCaptureDialog () {
    return new Promise((resolve, reject) => {
      $mdDialog
        .show({
          controller: 'CameraCaptureDialogController',
          template: require('../common/dialog/camera-capture.dialog.html'),
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          fullscreen: true,
          multiple: true
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const fetchImageViaProxy = function fetchImageViaProxy (src) {
    return Resource.imageProxy({ imageUrl: src }).$promise.then(response => {
      return response.dataUrl
    })
  }

  return {
    fetchImageViaProxy,
    uploadFile,
    uploadBase64File,
    isWindowsOrMacintosh,
    canStreamVideo,
    openCameraCaptureDialog
  }
}

module.exports = UploadService
