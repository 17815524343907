/* global angular */

const UUID = require('uuid')

/** @ngInject */
function visionService (
  $rootScope,
  $mdDialog,
  $translate,
  $mdToast,
  $window,
  $interval,
  $document
) {
  const runtimeIds = []

  const androidWithVision =
    $window.androidApp && typeof $window.androidApp.sendBase64 === 'function'

  const androidWithNewVersion =
    $window.androidApp && typeof $window.androidApp.readOCRText2 === 'function'

  const androidGeckoViewVersion =
    $window.androidApp &&
    typeof $window.androidApp.sendMessageToAndroid === 'function'

  const mdToast = $mdToast.nextplus({
    position: $rootScope.toastLocation,
    parent: 'document.body',
    theme: 'warning-toast',
    hideDelay: 4000
  })

  const openOCRSelectDialog = function openOCRSelectDialog (
    words,
    eventObject,
    key = null
  ) {
    if (eventObject !== null && words.length) {
      $mdDialog
        .show({
          /** @ngInject */
          controller: function ($element, $scope, $mdDialog, locals) {
            // Beacuse dialog might opened by OCR button in opened md-select, we should increase his z-index
            $element[0].style.zIndex = 90
            $scope.words = locals.words
            $scope.cancel = () => {
              $mdDialog.cancel()
            }
            $scope.selectWord = word => {
              $mdDialog.hide({ retake: false, word })
            }
            $scope.retry = () => {
              $mdDialog.hide({ retake: true })
            }
          },
          locals: {
            words
          },
          template: `<md-dialog style="width:60rem;">
                      <md-toolbar>
                        <div class="md-toolbar-tools">
                          <h2><span translate="OCR.DIALOG.TITLE"></span></h2>
                        </div>
                      </md-toolbar>
                      <md-dialog-content>
                        <div layout="column" flex>
                          <md-list>
                            <md-list-item ng-repeat="word in words track by $index" ng-click="selectWord(word)" style="border-bottom: 1px solid #dcd9d9;">
                              <div class="md-list-item-text"><h3>{{word}}</h3></div>
                            </md-list-item>
                          </md-list>
                        </div>
                      </md-dialog-content>
                      <md-dialog-actions layout="row">
                      <md-button ng-click="retry()" class="md-raised md-accent">
                          <span translate="OCR.DIALOG.RETRY"></span>
                        </md-button>
                        <md-button ng-click="cancel()" class="md-raised md-warn">
                          <span translate="BUTTONS.CANCEL"></span>
                        </md-button>
                      </md-dialog-actions>
                  </md-dialog>`,
          parent: angular.element(document.body),
          multiple: true,
          fullscreen: true,
          clickOutsideToClose: true
        })
        .then(
          function ({ retake, word }) {
            if (retake) {
              if (androidWithVision || androidGeckoViewVersion) {
                openVisionDialog({
                  callback: eventObject.callback,
                  returnWords: false,
                  key
                })
              } else {
                sendOCRAndroidEvent(eventObject.callback)
              }
            } else {
              if (eventObject.callback) {
                eventObject.callback(word)
              }
            }
          },
          function () {}
        )
    } else {
      $mdToast.updateTextContent($translate.instant('OCR.NO_RESULTS_FOUND'))
      $mdToast.show(mdToast)
    }
  }

  const interval = $interval(() => {
    for (let i = 0; i < runtimeIds.length; i++) {
      const runTime = runtimeIds[i]
      if (runTime.time + 1000 * 60 * 10 < new Date().getTime()) {
        $document[0].removeEventListener(
          `NextPlusLens-${runTime.uuid}`,
          OCREventCallback
        )
        runtimeIds.splice(i, 1)
        i--
      }
    }
  }, 1000 * 60)

  if (!$rootScope.hasOcr) {
    clearInterval(interval)
  }

  const OCRGeckoEventCallback = function OCREventCallback (details, data) {
    console.log('OCRGeckoEventCallback', details.name, data)
    const { uuid, words } = data.detail.value
    const idx = runtimeIds.findIndex(runTime => runTime.uuid === uuid)
    let releventUUIDObject = null
    if (idx !== -1) {
      releventUUIDObject = runtimeIds[idx]
      runtimeIds.splice(idx, 1)
    }
    if (releventUUIDObject && releventUUIDObject.returnWords) {
      releventUUIDObject.callback(JSON.parse(words), releventUUIDObject)
    } else {
      openOCRSelectDialog(JSON.parse(words), releventUUIDObject)
    }
  }

  const OCREventCallback = function OCREventCallback (e) {
    const type = e.type
    const res = e.detail.value
    if (androidWithNewVersion) {
      const { uuid, words } = res
      const idx = runtimeIds.findIndex(runTime => runTime.uuid === uuid)
      let releventUUIDObject = null
      if (idx !== -1) {
        releventUUIDObject = runtimeIds[idx]
        $document[0].removeEventListener(
          `NextPlusLens-${releventUUIDObject.uuid}`,
          OCREventCallback
        )
        runtimeIds.splice(idx, 1)
      }
      if (releventUUIDObject && releventUUIDObject.returnWords) {
        releventUUIDObject.callback(JSON.parse(words), releventUUIDObject)
      } else {
        openOCRSelectDialog(JSON.parse(words), releventUUIDObject)
      }
    } else {
      const uuidForRemove = type.split('NextPlusLens-')[1]
      console.log('uuidForRemove', uuidForRemove)
      const idx = runtimeIds.findIndex(
        runTime => runTime.uuid === uuidForRemove
      )
      if (idx !== -1) {
        const releventUUIDObject = runtimeIds[idx]
        console.log(res, releventUUIDObject)
        releventUUIDObject.callback(res, releventUUIDObject)
        $document[0].removeEventListener(
          `NextPlusLens-${releventUUIDObject.uuid}`,
          OCREventCallback
        )
        runtimeIds.splice(idx, 1)
      }
    }
  }

  const sendOCRAndroidEvent = function sendOCRAndroidEvent (
    callback,
    returnWords = false
  ) {
    return new Promise((resolve, reject) => {
      if ($rootScope.hasOcr) {
        const runtimeId = UUID()
        runtimeIds.push({
          uuid: runtimeId,
          time: new Date().getTime(),
          callback,
          returnWords
        })
        $document[0].addEventListener(
          `NextPlusLens-${runtimeId}`,
          OCREventCallback
        )
        if (androidWithNewVersion) {
          $window.androidApp.readOCRText2(runtimeId)
        } else if (!androidGeckoViewVersion) {
          $window.androidApp.readOCRText(runtimeId)
        }
        resolve()
      } else {
        // eslint-disable-next-line
        reject('OCR is disabled')
      }
    })
  }

  const sendOCRImageAndroidEvent = function sendOCRImageAndroidEvent (
    imageBase64,
    callback,
    returnWords = false
  ) {
    return new Promise((resolve, reject) => {
      if ($rootScope.hasOcr) {
        const runtimeId = UUID()
        runtimeIds.push({
          uuid: runtimeId,
          time: new Date().getTime(),
          callback,
          returnWords
        })
        if (androidWithVision) {
          $document[0].addEventListener(
            `NextPlusLens-${runtimeId}`,
            OCREventCallback
          )
          $window.androidApp.sendBase64(runtimeId, imageBase64)
        } else if (androidGeckoViewVersion) {
          $rootScope.$on(`NextPlusLens-${runtimeId}`, OCRGeckoEventCallback)
          $window.androidApp.sendMessageToAndroid({
            runtimeId,
            type: 'ocr',
            image: imageBase64
          })
        }
        resolve()
      } else {
        // eslint-disable-next-line
        reject('OCR is disabled')
      }
    })
  }

  const openVisionDialog = function openVisionDialog (obj) {
    return new Promise(resolve => {
      const { callback, returnWords, key } = obj
      if (
        $window.androidApp &&
        !androidGeckoViewVersion &&
        !androidWithVision
      ) {
        sendOCRAndroidEvent(callback, returnWords)
        resolve()
      } else {
        $mdDialog
          .show({
            controller: 'VisionDialogController',
            locals: {
              callback,
              returnWords,
              key
            },
            resolve: {
              lazyLoad: async function () {
                if (!('BarcodeDetector' in $window)) {
                  const { BrowserMultiFormatReader } = await import(
                    /* webpackChunkName: "zxing.BrowserMultiFormatReader" */ '@zxing/library/esm/browser/BrowserMultiFormatReader'
                  )
                  $window.BrowserMultiFormatReader = BrowserMultiFormatReader
                  const { default: BarcodeDetectorPolyfill } = await import(
                    /* webpackChunkName: "barcode-detector-polyfill" */ 'barcode-detection'
                  )
                  BarcodeDetectorPolyfill.engine = 'ZXing'
                  $window.BarcodeDetector = BarcodeDetectorPolyfill
                }
              }
            },
            template: require('../common/dialog/vision.dialog.html'),
            parent: angular.element(document.body),
            multiple: true,
            fullscreen: true,
            clickOutsideToClose: false,
            escapeToClose: false
          })
          .then(
            function ({ mode, value }) {
              if (mode === 'barcode') {
                callback(value)
              }
              resolve()
            },
            function () {
              resolve()
            }
          )
      }
    })
  }

  return {
    sendOCRAndroidEvent,
    sendOCRImageAndroidEvent,
    openOCRSelectDialog,
    openVisionDialog,
    androidWithNewVersion,
    androidGeckoViewVersion
  }
}

module.exports = visionService
