const _ = require('lodash')

/** @ngInject */

function DocumentUtils ($location) {
  const getDocument = (id, arr) => _.find(arr, ['recordId', id]) || { name: '' }

  const getValueForSearch = (nodes, arr) =>
    _.map(nodes, 'id')
      .map(x => getDocument(x, arr).name)
      .join(' ') + _.flatten(nodes).map(x => getValueForSearch(x.nodes, arr))

  const formatNodes = (str, arr) => {
    const tmp = []

    _.mapKeys(str, (value, key) => {
      const doc = getDocument(value.id, arr)
      tmp.push({
        id: doc.id,
        notPublished: doc.releaseStatus === 'unreleased',
        recordId: doc.recordId,
        name: doc.name,
        collapsed: false,
        search: doc.name + ' ' + getValueForSearch(value.nodes, arr),
        nodes: _.isEmpty(value.nodes) ? [] : formatNodes(value.nodes, arr)
      })
    })
    return tmp
  }

  const makeIdTree = (obj, rootId) => {
    obj.rootId = rootId ? rootId + obj.recordId : obj.recordId
    obj.collapsed = false
    obj.nodes = obj.nodes.map(x => makeIdTree(x, obj.rootId + '_'))
    return obj
  }

  const createLinkForDocument = function (pack, doc) {
    if (!doc) {
      return '/#!/content-package/show/released/' + pack.id
    }
    return (
      '/#!/content-package/show/released/' +
      pack.id +
      '#document_' +
      doc.recordId
    )
  }

  return {
    getDocument,
    getValueForSearch,
    formatNodes,
    makeIdTree,
    createLinkForDocument
  }
}

module.exports = DocumentUtils
