/* global _ */
/** @ngInject */

function KendoTreeViewHelper ($rootScope) {
  const keys = [
    'id',
    'name',
    'color',
    'translations',
    'fallbackStatus',
    'checked',
    'type'
  ]

  const convertTreeToDB = function convertTreeToDB (
    items = [],
    parentId = null
  ) {
    const res = []
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const object = _.pick(item, keys)
      if (parentId) {
        object.parentId = parentId
      }
      res.push(object)
      if (item.hasChildren) {
        res.push(...convertTreeToDB(item.items, item.id))
      }
    }
    return res
  }

  const findChildren = function findChildren (
    items = [],
    parentId = null,
    expanded = true
  ) {
    const res = []
    const relevantItems = items.filter(o => o.parentId === parentId)
    if (relevantItems.length) {
      for (let i = 0; i < relevantItems.length; i++) {
        const relevantItem = relevantItems[i]
        const object = _.pick(relevantItem, keys)
        const innerItems = findChildren(items, object.id)
        if (innerItems.length) object.items = innerItems
        res.push({ ...object, expanded })
      }
    }
    return res
  }

  const convertDBToTree = function convertDBToTree (items, expanded = true) {
    const res = []
    const firstLevelNodes = items.filter(o => !o.parentId)
    for (let i = 0; i < firstLevelNodes.length; i++) {
      const firstLevelNode = firstLevelNodes[i]
      const innerItems = findChildren(items, firstLevelNode.id, expanded)
      res.push({
        ..._.pick(firstLevelNode, keys),
        items: innerItems,
        expanded
      })
    }
    return res
  }

  const treeViewInstance = function (extraOptions = {}) {
    const defaults = {
      dataSource: [],
      dataTextField: 'id',
      dragAndDrop: true,
      loadOnDemand: true
    }
    return {
      setup: function (options, scope) {
        this.scope = scope
        this.options = _.defaultsDeep({ ...options, ...extraOptions }, defaults)
        this.options.language = $rootScope.currentLang
      },
      addNewItem: function (item) {
        this.instance.append(_.pick(item, keys))
      },
      getNodeById: function (id) {
        const relevantData = this.instance.dataSource.get(id)
        if (relevantData) {
          const kendoUid = relevantData.uid
          return this.instance.findByUid(kendoUid)
        }
        return null
      },
      getData: function () {
        return this.instance.dataItems()
      },
      getDataForDB: function () {
        return convertTreeToDB(this.getData())
      },
      options: this.options,
      instance: this.instance
    }
  }

  return {
    treeViewInstance,
    convertTreeToDB,
    convertDBToTree
  }
}
module.exports = KendoTreeViewHelper
