/* global _ */
/*eslint-disable*/

/** @ngInject */
function EvalService($translate) {
  {
    const types = {
      text: 'hello world',
      radio: 'hello world',
      select: 'hello world',
      string: 'hello world',
      textarea: 'hello world',
      resource: 'd87861b8-7d42-4cd9-b1fe-7c9170150fac',
      boolean: true,
      json: '',
      date: new Date()
    }

    const randomNumber = () => Math.floor(Math.random() * 100000) + 1

    const convertExpression = function convertExpression(expression, options) {
      let newExpression = angular.copy(expression)
      options.customFields.forEach(customField => {
        const regex = new RegExp(
          'customFields\\[\\"' +
            customField.name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') +
            '\\"\\]',
          'i'
        )
        if (regex.test(newExpression)) {
          newExpression = newExpression
            .split(regex)
            .join(`customFields[\"${customField.id}\"]`)
        }
      })
      return newExpression
    }

    const checkExpression = function checkExpression(expression, options) {
      const newOptions = {}
      _.mapKeys(options, (values, key) => {
        if (key !== 'session') {
          values.map(value => {
            if (!newOptions[key]) {
              newOptions[key] = {}
            }
            if (key === 'customFields') {
              newOptions[key][value.id] =
                value.type === 'number' ? randomNumber() : types[value.type]
            } else {
              newOptions[key][value.name] =
                value.type === 'number' ? randomNumber() : types[value.type]
            }
          })
        } else {
          newOptions[key] = options[key]
        }
      })
      newOptions.variables[$translate.instant('COMMON.CURRENT_DATE')] =
        new Date()
      try {
        const convertedExpression = convertExpression(expression, options)
        if (convertedExpression === '') return true
        const ast = expr.parse(convertedExpression)
        const value = expr.eval(ast, newOptions)
        if (value === Infinity) {
          return false
        }
        if (
          (!_.isUndefined(value) || value === 0 || value === '') &&
          !_.isNaN(value)
        ) {
          if (value.error) {
            return value
          }
          return true
        } else {
          return false
        }
      } catch (e) {
        console.error(e)
        return false
      }
    }

    const evalExpression = function evalExpression(expression, options) {
      options.variables[$translate.instant('COMMON.CURRENT_DATE')] = new Date()
      try {
        if (expression === '') return null
        const ast = expr.parse(expression)
        const value = expr.eval(ast, options)
        if (value === Infinity) {
          return new Error('Infinity')
        }
        if (
          (!_.isUndefined(value) || value === 0 || value === '') &&
          !_.isNaN(value) &&
          !value.toString().includes('undefined')
        ) {
          return value
        } else {
          return new Error('NaN')
        }
      } catch (e) {
        return e
      }
    }

    return {
      convertExpression,
      checkExpression,
      evalExpression
    }
  }
}

module.exports = EvalService
