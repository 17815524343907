/** @ngInject */
function UsersService (UserModel) {
  let idQueue = []
  let resolverQueue = []

  const dequeueAndFetch = async () => {
    // Capture current queues and reset them
    const idsToFetch = [...idQueue]
    const resolvers = [...resolverQueue]
    idQueue = []
    resolverQueue = []

    try {
      // Assume UserModel.find gets multiple users by their ids.
      // Adjust this according to your actual API
      const users = await UserModel.find({
        filter: {
          where: { id: { inq: idsToFetch } },
          fields: ['id', 'displayName']
        }
      }).$promise

      // Create a map of id to displayName for quick lookup
      const displayNameMap = users.reduce((map, user) => {
        map[user.id] = user.displayName
        return map
      }, {})

      // Resolve the promises with the corresponding displayNames
      resolvers.forEach((resolve, index) => {
        const id = idsToFetch[index]
        resolve(displayNameMap[id] || null)
      })
    } catch (error) {
      console.error('Error fetching users:', error)
      resolvers.forEach(resolve => resolve(null)) // or reject(error) to reject the promises
    }
  }

  const getUserDisplayNameById = id => {
    return new Promise((resolve, reject) => {
      idQueue.push(id)
      resolverQueue.push(resolve)

      // Set a timeout to dequeue and fetch after 100ms
      if (idQueue.length === 1) {
        setTimeout(dequeueAndFetch, 100)
      }
    })
  }

  return {
    getUserDisplayNameById
  }
}

module.exports = UsersService
