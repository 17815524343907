const _ = require('lodash')

/** @ngInject */
function LocalStorageUtils ($localStorage, $rootScope) {
  const get = key => $localStorage[key]

  const set = (key, value) => {
    if (_.isArray(key)) {
      key.map(elem => _.mapKeys(elem, (v, k) => ($localStorage[k] = v)))
    } else if (_.isObject(key)) {
      _.mapKeys(key, (v, k) => ($localStorage[k] = v))
    } else {
      $localStorage[key] = value
    }
  }

  const clear = key => delete $localStorage[key]

  const clearAll = () => $localStorage.$reset()

  return {
    get,
    set,
    clear,
    clearAll
  }
}

module.exports = LocalStorageUtils
