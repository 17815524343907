/* global _ */

/** @ngInject */

function CatalogUtils ($location) {
  const makeIdTree = (obj, rootId) => {
    obj.rootId = rootId ? rootId + obj.number : obj.number
    if (!_.isArray(obj.nodes)) obj.nodes = []
    obj.collapsed = true
    obj.nodes.map(node => {
      if (
        typeof node.label === 'string' &&
        !isNaN(node.label) &&
        !node.label.includes('.')
      ) {
        node.label = parseInt(node.label)
      }
      node = makeIdTree(node, obj.rootId + '_')
      return node
    })
    return obj
  }

  const getValueForSearch = nodes => {
    let y = ''
    nodes.map(x => {
      if (x.nodes && x.nodes.length > 0)
        y = y + ' ' + x.name + ' ' + getValueForSearch(x.nodes)
      return x
    })
    return y
  }

  const formatNodes = str => {
    const tmp = []
    str.map(x => {
      x.search = (x.name ? x.name : '') + ' ' + getValueForSearch(x.nodes)
      x.collapsed = true
      x.nodes = !x.nodes ? [] : formatNodes(x.nodes)
      tmp.push(x)
      return x
    })
    return tmp
  }

  const createLinkForCatalog = (catalog, node) => {
    if (!node) {
      return '/#!/catalog/show/' + catalog.id + '/'
    }
    const ids = node.rootId.split('_')
    const idsNotAssembly = ids.slice(0, ids.length - 1).join('_')
    const subPartId = ids.slice(ids.length - 1, ids.length).join('_')

    if (node.is_assembly) {
      return (
        '/#!/catalog/show/' + catalog.id + '/?selectedPartNumber=' + node.rootId
      )
    } else {
      return (
        '/#!/catalog/show/' +
        catalog.id +
        '/?selectedPartNumber=' +
        idsNotAssembly +
        '&selectedSubPartNumber=' +
        subPartId
      )
    }
  }

  return {
    getValueForSearch,
    formatNodes,
    makeIdTree,
    createLinkForCatalog
  }
}

module.exports = CatalogUtils
