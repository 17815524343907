/* global DOMParser _ angular kendo */
import { mapOrder } from 'app/helper'
const debug = require('debug')

/** @ngInject */
function WorkflowUtilsService (
  $rootScope,
  $mdToast,
  $translate,
  $mdDialog,
  WorkflowSessionItem,
  Workflow,
  Tool,
  Location,
  Certificate,
  Field,
  KendoGridHelper,
  FieldUtilsService,
  MultiTranslateService,
  Category,
  htmlWork
) {
  /**
   * This function checks if the given string is HTML.
   *
   * @param {string} str Input string
   * @return {boolean} Is HTML
   */
  const isHTML = function isHTML (str) {
    const doc = new DOMParser().parseFromString(str, 'text/html')
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
  }

  const generateWorkflowEditPage = async function generateWorkflowEditPage (
    workflowId,
    lockId
  ) {
    let data
    if (workflowId) {
      data = await Workflow.enterWorkflow({
        workflowId,
        lockId: null
      }).$promise
    } else if (lockId) {
      data = await Workflow.enterWorkflow({
        workflowId: null,
        lockId
      }).$promise
    }

    data.resourceMap = {}
    data.productionEntitiesById = _.keyBy(data.productionEntities, 'id')
    data.productionEntityTypesById = _.keyBy(data.productionEntityTypes, 'id')
    data.workflow.nodes = data.workflow.nodes.map(node => {
      // reorder fields
      if (node.fields && node.fields.length > 0) {
        node.fields = mapOrder(node.fields, node.fieldIds, 'id')
      }
      if (node.resources && node.resources.length > 0) {
        node.resources.forEach(resource => {
          if (!data.resourceMap[resource.id]) {
            data.resourceMap[resource.id] = _.cloneDeep(
              _.omit(resource, 'resource._meta')
            )
          }
        })
        return node
      }
      return node
    })
    // Translate Service
    if ($rootScope.appSettings.contentTranslations) {
      data.allWorkflows = data.allWorkflows.map(workflow =>
        MultiTranslateService.getForView(
          Workflow,
          $rootScope.currentLang,
          workflow
        )
      )
      data.workorderTemplates = data.workorderTemplates.map(workflow =>
        MultiTranslateService.getForView(
          Workflow,
          $rootScope.currentLang,
          workflow
        )
      )
      // TODO: implement in future
      // data.forms = data.forms.map(form => {
      //   MultiTranslateService.getForView(
      //     Form,
      //     $rootScope.currentLang,
      //     form
      //   )
      // })
      data.categories = data.categories.map(category =>
        MultiTranslateService.getForView(
          Category,
          $rootScope.currentLang,
          category
        )
      )
      data.tools = data.tools.map(tool =>
        MultiTranslateService.getForView(Tool, $rootScope.currentLang, tool)
      )
      data.locations = data.locations.map(location =>
        MultiTranslateService.getForView(
          Location,
          $rootScope.currentLang,
          location
        )
      )
      data.certificates = data.certificates.map(certificate =>
        MultiTranslateService.getForView(
          Certificate,
          $rootScope.currentLang,
          certificate
        )
      )
      data.fields = data.fields.map(field =>
        MultiTranslateService.getForView(Field, $rootScope.currentLang, field)
      )
      if (data.referenceWorkflows) {
        data.referenceWorkflows = data.referenceWorkflows.map(workflow =>
          MultiTranslateService.getForView(
            Workflow,
            $rootScope.currentLang,
            workflow
          )
        )
      }
    }
    return data
  }

  const handleTranslationsAndWorkflowData =
    function handleTranslationsAndWorkflowData (data, workflowTemplate) {
      // Translate Service
      if ($rootScope.appSettings.contentTranslations) {
        data.workflow = MultiTranslateService.getForView(
          Workflow,
          $rootScope.currentLang,
          data.workflow
        )
        // TODO: implement in future
        // data.globalForms = data.globalForms.map(form => {
        //   MultiTranslateService.getForView(
        //     Form,
        //     $rootScope.currentLang,
        //     form
        //   )
        // })
        data.tools = data.tools.map(tool =>
          MultiTranslateService.getForView(Tool, $rootScope.currentLang, tool)
        )
        data.locations = data.locations.map(location =>
          MultiTranslateService.getForView(
            Location,
            $rootScope.currentLang,
            location
          )
        )
        data.certificates = data.certificates.map(certificate =>
          MultiTranslateService.getForView(
            Certificate,
            $rootScope.currentLang,
            certificate
          )
        )
        data.fields = data.fields.map(field =>
          MultiTranslateService.getForView(Field, $rootScope.currentLang, field)
        )
      }
      data.locations = _.keyBy(data.locations, 'id')
      // reorder fields
      data.workflow.nodes = data.workflow.nodes.map(node => {
        if (node.fields && node.fields.length > 0) {
          node.fields = mapOrder(node.fields, node.fieldIds, 'id')
        }
        return node
      })
      $rootScope.workflowTemplate = workflowTemplate
      return data
    }

  const getSkuAndRev = function getSkuAndRev (workflow) {
    return new Promise(async (resolve, reject) => {
      const product = {}
      if (workflow.parts && workflow.parts.length > 1) {
        const separator = `####################################`
        $mdDialog
          .show({
            /** @ngInject */
            controller: ($scope, locals, $mdDialog) => {
              $scope.header = $translate.instant('WF.PICK_SKU_FOR_SESSION')
              $scope.fields = [
                {
                  key: 'sku',
                  type: 'selectWithSearch',
                  templateOptions: {
                    required: true,
                    label: $translate.instant('WF.SKU'),
                    options: locals.parts.map(part => {
                      return {
                        name: `${part.sku} ${part.rev ? `(${part.rev})` : ''}`,
                        id: `${part.sku}${separator}${part.rev}`
                      }
                    })
                  }
                }
              ]
              $scope.form = {}
              $scope.model = {}
              $scope.save = () => $mdDialog.hide($scope.model.sku)
              $scope.cancel = () => $mdDialog.cancel()
            },
            locals: {
              parts: workflow.parts
            },
            title: $translate.instant('WF.PICK_SKU_FOR_SESSION'),
            multiple: true,
            // template: action.content,
            template: `
            <form name="form" ng-submit="save()">
            <md-dialog style="height: 300px; max-width: 500px;">
                          <md-toolbar>
                            <div class="md-toolbar-tools">
                              <h2>{{header}}</h2>
                              <span flex></span>
                              <md-button class="md-icon-button" ng-click="cancel()">
                                <md-icon md-font-icon="icon-close"></md-icon>
                              </md-button>
                            </div>
                          </md-toolbar>
                          <md-dialog-content style="height: 100%;">
                            <formly-form form="form" model="model" fields="fields">
                            </formly-form>
                          </md-dialog-content>
                          <md-dialog-actions>
                          <md-button ng-disabled="form.$invalid" type="submit" class="md-raised md-primary">
                          Ok
                        </md-button>
                          </md-dialog-actions>
                      </md-dialog>
                      </form>
                      `,
            parent: angular.element(document.body),
            targetEvent: '',
            clickOutsideToClose: false,
            escapeToClose: false
          })
          .then(async selectedPart => {
            const [sku, rev] = selectedPart.split(separator)
            product.sku = sku
            product.rev = rev
            resolve(product)
          })
          .catch(() => {
            reject(new Error('Manually Closed'))
          })
      } else {
        product.sku =
          workflow.parts && workflow.parts.length === 1
            ? workflow.parts[0].sku
            : ''
        product.rev =
          workflow.parts && workflow.parts.length === 1
            ? workflow.parts[0].rev
            : ''
        resolve(product)
      }
    })
  }

  const enterSession = function enterSession (stateParams, isRealwear = false) {
    return new Promise(async (resolve, reject) => {
      const errorToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'body',
        theme: 'error-toast',
        hideDelay: 5000
      })
      let data = null
      let ids = !stateParams.workflowSessionIds
        ? []
        : stateParams.workflowSessionIds
      if (!_.isArray(ids)) {
        ids = [ids]
      }

      try {
        const isPreview = stateParams.preview && stateParams.preview !== 'false'
        if (isPreview) {
          // preview
          const previewWorkflow = await Workflow.findOne({
            filter: {
              where: {
                id: stateParams.id
              },
              fields: { id: true, parts: true }
            }
          }).$promise
          const { sku, rev } = await getSkuAndRev(previewWorkflow)
          data = await WorkflowSessionItem.enterSessionPreview({
            ids,
            workflowId: stateParams.id,
            sku,
            rev,
            startNode:
              stateParams.preview !== 'true' ? stateParams.preview : null
          }).$promise
        } else {
          if (ids && ids.length > 0) {
            // regular session
            data = await WorkflowSessionItem.enterSession({
              ids,
              workflowId: stateParams.id || null,
              recordId: stateParams.recordId || null
            }).$promise
          } else {
            // new session
            let currentWorkflow
            try {
              currentWorkflow = await Workflow.findOne({
                filter: {
                  where: {
                    recordId: stateParams.recordId,
                    currentReleasedVersion: true
                  },
                  order: 'releaseDate DESC',
                  fields: { id: true, parts: true }
                }
              }).$promise
            } catch (err) {
              $mdToast.show(errorToast)
              $mdToast.updateTextContent(
                $translate.instant(
                  'WF.COULD_NOT_FIND_ANY_RELEASED_VERSION_OF_THIS_WORKFLOW'
                )
              )
              $rootScope.loadingProgress = false
              return
            }
            const { sku, rev } = await getSkuAndRev(currentWorkflow)
            data = await WorkflowSessionItem.enterSession({
              recordId: stateParams.recordId,
              sku,
              rev
            }).$promise
          }
        }
        if (!isPreview) {
          data.workflow.nodes = data.workflow.globalNodes
          data.workflow.connections = data.workflow.globalConnections
        }
        data = handleTranslationsAndWorkflowData(data, data.workflow.template)
        if (stateParams.configuration && (!ids || ids.length === 0)) {
          // only in new sessions
          const configuration = JSON.parse(stateParams.configuration)
          await WorkflowSessionItem.setConfigurationInParent({
            sessionId: data.sessions[0].id,
            parentSessionId: configuration.parentSession,
            parentConfigurationId: configuration.parentConfigurationId
          }).$promise
          data.sessions[0].parentSessionId = configuration.parentSession
          const parentSessionIncludes = [
            {
              relation: 'workorder',
              scope: {
                fields: {
                  id: true,
                  workorderNumber: true,
                  productRev: true,
                  sku: true,
                  skuDesc: true,
                  supplier: true,
                  end: true,
                  start: true,
                  source: true
                }
              }
            }
          ]
          const parentSession = await WorkflowSessionItem.findOne({
            filter: {
              where: {
                id: configuration.parentSession
              },
              include: parentSessionIncludes,
              fields: {
                id: true,
                workorderId: true
              }
            }
          }).$promise
          data.sessions[0].workorderId = parentSession.workorderId
          data.sessions[0].workorder = parentSession.workorder || {}
        }
        if (isRealwear === true) {
          $rootScope.workflowTemplate = 'Realwear'
        }
        resolve(data)
      } catch (error) {
        if (error === 'Manually Closed') {
          return reject(error)
        }
        const {
          data: {
            error: { code, details }
          }
        } = error
        $mdToast.show(errorToast)
        let message = $translate.instant(`WF.${code}`)
        if (details) {
          let workflowsString = ''
          details.workflows.forEach((workflow, idx) => {
            if (idx > 0) {
              workflowsString += ' > '
            }
            workflowsString += `"${workflow.name}"`
          })
          message = $translate.instant(`WF.${code}`, {
            workflowsString
          })
        }
        $mdToast.updateTextContent(message)
        reject(error)
      }
    })
  }

  const generateSubFormTemplate = function generateSubFormTemplate (
    controllerScope,
    kendoPath,
    columnId,
    subFormKey,
    data,
    innerFields,
    dbFields
  ) {
    let htmlValue = '--'
    if (
      _.isNil(data) ||
      _.isNil(data.fields) ||
      _.isNil(data.fields[subFormKey])
    ) {
      return htmlValue
    }
    const kendoGridInstance = _.get(controllerScope, kendoPath)
    const kendoInstance = kendoGridInstance.instance

    const tableColumns = kendoInstance
      ? kendoInstance.columns.filter(c => !c.hidden)
      : kendoGridInstance.gridOptions.columns.filter(c => !c.hidden)
    if (kendoInstance && !tableColumns.find(c => c.uniqueId === columnId)) {
      return htmlValue
    }
    const subFormMapping =
      KendoGridHelper.createComplexTypeMapping(tableColumns)
    const currentSequence = subFormMapping.find(c => c.columnId === columnId)
    if (!currentSequence) {
      return htmlValue
    }
    const relevantInnerFields = tableColumns
      .slice(
        currentSequence.index,
        currentSequence.index + currentSequence.length
      )
      .map(c => {
        const parts = c.uniqueId.split('_')
        return parts[parts.length - 1]
      })
    innerFields = mapOrder(
      innerFields.filter(f => relevantInnerFields.includes(f.id)),
      relevantInnerFields,
      'id'
    )
    const values = data.fields[subFormKey]
    if (values && values.length) {
      htmlValue = '<div layout="column" layout-align="center start">'
      htmlValue += `<table class="sub-form-display-table"><thead><tr>
                      <th><span>#</span></th>`
      for (let i = 0; i < innerFields.length; i++) {
        const field = innerFields[i]
        htmlValue += `<th><span>${htmlWork.htmlEncode(field.title)}</span></th>`
      }
      htmlValue += '</tr></thead><tbody>'
      for (let idx = 0; idx < values.length; idx++) {
        const row = values[idx]
        htmlValue += `<tr><td>${idx + 1}</td>`
        for (let f = 0; f < innerFields.length; f++) {
          const innerField = innerFields[f]
          const innerFieldValue = row[innerField.id + '_lookup']
            ? row[innerField.id + '_lookup']
            : row[innerField.id]
          const html = FieldUtilsService.getFieldHTMLValue(
            dbFields,
            innerField.id,
            innerFieldValue,
            'column',
            false
          )
          htmlValue += `<td dir="auto">${html || ''}</td>`
        }
        htmlValue += '</tr>'
      }
      htmlValue += '</tbody></table></div>'
    }
    return htmlValue
  }

  const generateIBFTemplate = function generateIBFTemplate (
    controllerScope,
    kendoPath,
    columnId,
    IBFkey,
    data,
    innerFields,
    dbFields
  ) {
    let htmlValue = '--'
    if (_.isNil(data) || _.isNil(data.fields) || _.isNil(data.fields[IBFkey])) {
      return htmlValue
    }
    const kendoGridInstance = _.get(controllerScope, kendoPath)
    const kendoInstance = kendoGridInstance.instance

    const tableColumns = kendoInstance
      ? kendoInstance.columns.filter(c => !c.hidden)
      : kendoGridInstance.gridOptions.columns.filter(c => !c.hidden)
    if (kendoInstance && !tableColumns.find(c => c.uniqueId === columnId)) {
      return htmlValue
    }
    const IBFMapping = KendoGridHelper.createComplexTypeMapping(tableColumns)
    const currentSequence = IBFMapping.find(c => c.columnId === columnId)
    if (!currentSequence) {
      return htmlValue
    }
    const relevantInnerFields = tableColumns
      .slice(
        currentSequence.index,
        currentSequence.index + currentSequence.length
      )
      .map(c => {
        const parts = c.uniqueId.split('_')
        return parts[parts.length - 1]
      })
    innerFields = mapOrder(
      innerFields.filter(f => relevantInnerFields.includes(f.id)),
      relevantInnerFields,
      'id'
    )
    const fieldData = data.fields[IBFkey]
    if (fieldData && fieldData.length) {
      htmlValue = '<div layout="column" layout-align="center start">'
      htmlValue += `<table class="image-based-form-display-table"><thead><tr>
                      <th><span>${$translate.instant(
                        'MAP_EDITOR.LAYER'
                      )}</span></th>`
      for (let i = 0; i < innerFields.length; i++) {
        const field = innerFields[i]
        htmlValue += `<th><span>${htmlWork.htmlEncode(field.title)}</span></th>`
      }
      htmlValue += '</tr></thead><tbody>'
      for (let idx = 0; idx < fieldData.length; idx++) {
        const row = fieldData[idx]
        const { values, areaName } = row
        htmlValue += `<tr><td>${htmlWork.htmlEncode(areaName)}</td>`
        for (let f = 0; f < innerFields.length; f++) {
          const innerField = innerFields[f]
          const innerFieldValue =
            innerField.type === 'lookupSelect'
              ? values.find(
                  valueObj => valueObj.fieldId === innerField.id + '_lookup'
                )
              : values.find(valueObj => valueObj.fieldId === innerField.id)
          const html = FieldUtilsService.getFieldHTMLValue(
            dbFields,
            innerField.id,
            innerFieldValue && innerFieldValue.value
              ? innerFieldValue && innerFieldValue.value
              : null,
            'column',
            false
          )
          htmlValue += `<td dir="auto">${html || ''}</td>`
        }
        htmlValue += '</tr>'
      }
      htmlValue += '</tbody></table></div>'
    }
    return htmlValue
  }

  const getInnerFieldType = function getInnerFieldType (fieldObject) {
    switch (fieldObject.type) {
      case 'input':
        return fieldObject.subtype === 'number' ? 'number' : 'string'
      case 'checkbox':
        return 'boolean'
      case 'datePicker':
      case 'dateTimePicker':
        return 'date'
      default:
        return 'string'
    }
  }

  const generateFieldsColumns = function generateFieldsColumns (
    nodes,
    dbFields,
    controllerScope,
    users,
    kendoPath = 'kendoGrid'
  ) {
    const columns = []
    const dbFieldsById = _.keyBy(dbFields, 'id')
    const usersNamesById =
      users?.length > 0
        ? users.reduce((obj, key) => {
            obj[key.id] = key.displayName
            return obj
          }, {})
        : {}
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const { title, fieldIds } = node
      for (let j = 0; j < fieldIds.length; j++) {
        const fieldId = fieldIds[j]
        const existsField = dbFieldsById[fieldId]
        if (existsField) {
          const uniqueId = `$${node.originalNodeId}-${fieldId}`
          const columnName = `${title} -- ${existsField.title}`
          const fieldKey = `${node.originalNodeId || node.id}_${fieldId}`
          switch (existsField.type) {
            case 'imageBasedForm':
              {
                const innerFields = dbFields.filter(f =>
                  existsField.fieldIds.includes(f.id)
                )
                const orderedFields = mapOrder(
                  _.uniqBy(innerFields, 'id'),
                  existsField.fieldIds,
                  'id'
                )
                for (let i = 0; i < orderedFields.length; i++) {
                  const innerField = orderedFields[i]
                  const id = `${fieldKey}_${innerField.id}`
                  columns.push({
                    uniqueId: id,
                    hidden: true,
                    field: `fields["${fieldKey}"]["${innerField.id}"]`,
                    translateCode: `(${columnName}) ${innerField.title}`,
                    type: getInnerFieldType(innerField),
                    filterable: !FieldUtilsService.nonFilterableTypes.includes(
                      innerField.type
                    ),
                    trustedTemplate: data => {
                      return generateIBFTemplate(
                        controllerScope,
                        kendoPath,
                        id,
                        fieldKey,
                        data,
                        orderedFields,
                        dbFields
                      )
                    }
                  })
                }
              }
              break
            case 'subForm':
              {
                const innerFields = dbFields.filter(f =>
                  existsField.fieldIds.includes(f.id)
                )
                const orderedFields = mapOrder(
                  _.uniqBy(innerFields, 'id'),
                  existsField.fieldIds,
                  'id'
                )
                for (let i = 0; i < orderedFields.length; i++) {
                  const innerField = orderedFields[i]
                  const id = `${fieldKey}_${innerField.id}`
                  columns.push({
                    uniqueId: id,
                    hidden: true,
                    field: `fields["${fieldKey}"]["${innerField.id}"]`,
                    translateCode: `(${columnName}) ${innerField.title}`,
                    type: getInnerFieldType(innerField),
                    filterable: !FieldUtilsService.nonFilterableTypes.includes(
                      innerField.type
                    ),
                    trustedTemplate: data => {
                      return generateSubFormTemplate(
                        controllerScope,
                        kendoPath,
                        id,
                        fieldKey,
                        data,
                        orderedFields,
                        dbFields
                      )
                    }
                  })
                }
              }
              break
            case 'checkbox':
              columns.push({
                uniqueId,
                hidden: true,
                field: `fields["${node.id}_${fieldId}"]`,
                translateCode: columnName,
                type: 'boolean',
                filterable: true
              })
              break
            case 'textarea':
              columns.push({
                uniqueId,
                hidden: true,
                field: `fields["${fieldKey}"]`,
                translateCode: columnName,
                type: 'string',
                filterable: true,
                trustedTemplate: data => {
                  const value = data?.fields[fieldKey]

                  if (value) {
                    let string = htmlWork.htmlEncode(value)

                    if (string.length > 255) {
                      let cutPosition = 255

                      // Check for special sequences near the end of the cut and adjust the cutPosition.
                      while (cutPosition > 0) {
                        // If we encounter a '\', then we check the character after it
                        if (
                          string.charAt(cutPosition - 1) === '\\' &&
                          (string.charAt(cutPosition) === 'n' ||
                            string.charAt(cutPosition) === 'r')
                        ) {
                          cutPosition -= 1
                        } else {
                          break
                        }
                      }

                      string = string.substring(0, cutPosition) + '...'
                    }

                    return htmlWork.nl2br(string)
                  }

                  return '--'
                }
              })
              break
            case 'upload':
            case 'button':
              columns.push({
                uniqueId,
                hidden: true,
                field: `fields["${fieldKey}"]`,
                translateCode: columnName,
                type: 'array',
                sortable: false,
                trustedTemplate: data => {
                  return !data.fields || !data.fields[fieldKey]
                    ? ''
                    : FieldUtilsService.getFieldHTMLValue(
                        [existsField],
                        fieldId,
                        data.fields[fieldKey]
                      )
                }
              })
              break
            case 'datePicker':
            case 'dateTimePicker':
              columns.push({
                uniqueId,
                hidden: true,
                field: `fields["${fieldKey}"]`,
                translateCode: columnName,
                type: 'date',
                filterable: true,
                sortable: true,
                trustedTemplate: data => {
                  return !data.fields || !data.fields[fieldKey]
                    ? '--'
                    : FieldUtilsService.getFieldHTMLValue(
                        [existsField],
                        fieldId,
                        data.fields[fieldKey]
                      )
                }
              })
              break
            case 'lookupSelect':
              if (
                existsField.tableType === FieldUtilsService.lookupTypes.MODELS
              ) {
                const id = `${node.id}_${fieldId}`
                columns.push({
                  uniqueId: id,
                  field: `fields["${id}"]`,
                  translateCode: `${columnName} -> ${existsField.title}`,
                  filterable: true,
                  sortable: true,
                  template: data => {
                    return _.isNil(data.fields[id])
                      ? '--'
                      : htmlWork.htmlEncode(data.fields[id])
                  }
                })
              } else if (
                existsField.table &&
                Array.isArray(existsField.table.columns)
              ) {
                for (let j = 0; j < existsField.table.columns.length; j++) {
                  const column = existsField.table.columns[j]
                  if (
                    !column.deletedAt &&
                    existsField.fieldIds.includes(column.id)
                  ) {
                    const id = `${node.id}_${fieldId}_lookup_${column.id}`
                    columns.push({
                      uniqueId: id,
                      hidden: true,
                      field: `fields["${id}"]`,
                      translateCode: `${columnName} -> ${column.name}`,
                      type: column.type,
                      filterable: true,
                      sortable: true,
                      template: data => {
                        return _.isNil(data.fields[id])
                          ? '--'
                          : htmlWork.htmlEncode(data.fields[id])
                      }
                    })
                  }
                }
              }
              break
            case 'select':
            case 'radio':
              columns.push({
                uniqueId,
                hidden: true,
                field: `fields["${fieldKey}"]`,
                translateCode: columnName,
                filterable: true,
                sortable: true,
                trustedTemplate: data => {
                  return !data.fields || !data.fields[fieldKey]
                    ? '--'
                    : FieldUtilsService.getFieldHTMLValue(
                        [existsField],
                        fieldId,
                        data.fields[fieldKey]
                      )
                }
              })
              break
            case 'gpsInput':
              columns.push({
                uniqueId,
                field: `fields["${fieldKey}"]`,
                translateCode: columnName,
                type: 'object',
                filterable: false,
                sortable: false,
                trustedTemplate: data => {
                  return !data.fields || !data.fields[fieldKey]
                    ? '--'
                    : FieldUtilsService.getFieldHTMLValue(
                        [existsField],
                        fieldId,
                        data.fields[fieldKey]
                      )
                }
              })
              break
            case 'selectUser':
              columns.push({
                uniqueId,
                field: `fields["${fieldKey}"]`,
                translateCode: columnName,
                type: 'array',
                filterable: {
                  mode: 'row',
                  cell: {
                    showOperators: false,
                    operator: 'eq',
                    suggestionOperator: 'eq',
                    template: function (args) {
                      args.element.kendoDropDownList({
                        filter: 'contains',
                        autoBind: false,
                        dataTextField: 'displayName',
                        dataValueField: 'id',
                        dataSource: new kendo.data.DataSource({
                          data: users
                        }),
                        valuePrimitive: true
                      })
                    }
                  }
                },
                sortable: false,
                trustedTemplate: data => {
                  return !data.fields || !data.fields[fieldKey]
                    ? '--'
                    : FieldUtilsService.getFieldHTMLValue(
                        [existsField],
                        fieldId,
                        data.fields[fieldKey],
                        usersNamesById
                      )
                }
              })
              break
            case 'tinymce':
              columns.push({
                uniqueId,
                hidden: true,
                field: `fields["${fieldKey}"]`,
                translateCode: columnName,
                type: 'string',
                filterable: true,
                trustedTemplate: data => {
                  return !data.fields || !data.fields[fieldKey]
                    ? '--'
                    : FieldUtilsService.getFieldHTMLValue(
                        [existsField],
                        fieldId,
                        data.fields[fieldKey]
                      )
                }
              })
              break
            default: {
              let type = 'string'
              if (existsField.subtype === 'number') {
                type = 'number'
              }
              columns.push({
                uniqueId,
                hidden: true,
                field: `fields["${fieldKey}"]`,
                translateCode: columnName,
                type,
                filterable: true,
                template: data => {
                  return !_.isNil(data.fields[fieldKey])
                    ? htmlWork.htmlEncode(data.fields[fieldKey])
                    : '--'
                }
              })
            }
          }
        }
      }
    }
    return columns
  }
  const generateToolsColumns = function generateToolsColumns (nodes, dbTools) {
    const columns = []
    const dbFieldsById = _.keyBy(dbTools, 'id')
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const { title, toolIds } = node
      for (let j = 0; j < toolIds.length; j++) {
        const toolId = toolIds[j]
        const existsTool = dbFieldsById[toolId]
        if (existsTool) {
          const uniqueId = `${node.originalNodeId}-${toolId}`
          const columnName = `${title} -- ${existsTool.name}`
          const toolKey = `${node.originalNodeId}_${toolId}`
          columns.push({
            uniqueId,
            hidden: true,
            field: `tools["${toolKey}"]`,
            translateCode: columnName,
            type: 'string',
            filterable: true,
            template: data => {
              return !_.isNil(data.tools[toolKey])
                ? htmlWork.htmlEncode(data.tools[toolKey])
                : '--'
            }
          })
        }
      }
    }
    return columns
  }

  const insertNodeOrChapterRecursive = function insertNodeOrChapterRecursive (
    object,
    indexArray,
    array
  ) {
    if (indexArray.length === 0) {
      array.push(object)
      return array.length - 1
    }
    if (indexArray.length === 1) {
      const idx = indexArray[0]
      array[idx].items.push(object)
      return array[idx].items.length - 1
    }
    const idx = indexArray.shift()
    return insertNodeOrChapterRecursive(object, indexArray, array[idx].items)
  }
  const createChapterPath = function createChapterPath (
    chapterId = null,
    chaptersById
  ) {
    const path = []
    while (!_.isNil(chapterId)) {
      const chapter = chaptersById[chapterId]
      if (chapter) {
        path.unshift(chapterId)
        if (chapter.parentId) {
          chapterId = chapter.parentId
        } else {
          chapterId = null
        }
      } else {
        chapterId = null
      }
    }
    return path
  }

  const getFullChaptersPath = function getFullChaptersPath (
    nodesPath,
    workflow,
    hiddenByNodeId = {}
  ) {
    const chapters = []
    if (workflow.chapters) {
      chapters.push(...workflow.chapters)
    } else {
      chapters.push(...workflow._chapters)
    }
    if (workflow.chaptersByNodeId && workflow.chaptersByNodeId.length > 0) {
      workflow.chaptersByNodeId.forEach(nodeId => {
        chapters.push(...nodeId.chapters)
      })
    }
    const chaptersById = _.keyBy(chapters, 'id')
    const nodesById = workflow.nodesById
      ? workflow.nodesById
      : workflow.nodes
      ? _.keyBy(workflow.nodes, 'id')
      : _.keyBy(workflow._globalNodes, 'id')
    let index = 1
    if (chapters.length === 0) {
      const nodePathString = null
      const chaptersPath = nodesPath.map((n, i) => {
        const nodeId = n.nodeId ? n.nodeId : n.id
        if (n.title) {
          const nodePathString = n.title
          if (nodesById[nodeId])
            nodesById[nodeId].nodePathString = nodePathString
        }
        const chapterPathObject = {
          ...n,
          originalIndex: i + 1,
          index,
          isGroup: false,
          nodePathString
        }
        hiddenByNodeId[nodeId] = n.hidden || false
        if (!n.hidden) {
          index++
        }
        return chapterPathObject
      })
      return {
        chaptersPath,
        hiddenByNodeId,
        nodesById
      }
    }
    const chaptersPath = []
    let currentChaptersPath = []
    let chaptersIndexs = []

    for (let i = 0; i < nodesPath.length; i++) {
      const nodePathObject = nodesPath[i]
      const workflowId = nodePathObject.workflowId || nodePathObject.wfId
      const nodeId = nodePathObject.nodeId
        ? nodePathObject.nodeId
        : nodePathObject.id
      const nodeObject = nodesById[nodeId]
      if (
        !nodeObject ||
        !nodeObject.chapterId ||
        !chaptersById[nodeObject.chapterId]
      ) {
        // There is no chapterId - reset all chapter tracking and push regular node to array
        let nodePathString = null
        if (nodePathObject.title) {
          nodePathString = nodePathObject.title
          if (nodesById[nodePathObject.id]) {
            nodesById[nodePathObject.id].nodePathString = nodePathString
          }
        }
        chaptersPath.push({
          ...nodePathObject,
          originalIndex: i + 1,
          index,
          isGroup: false,
          nodePathString
        })
        // clean chapters path + indexes
        currentChaptersPath = []
        chaptersIndexs = []
        hiddenByNodeId[nodeId] = nodePathObject.hidden || false

        if (!nodePathObject.hidden) {
          index++
        }
      } else {
        // There is a chapter
        const chapterIdsPath = createChapterPath(
          nodeObject.chapterId,
          chaptersById
        ) // return parent to son array [.., .., .., chapterId]
        const chaptersForInsert = []
        let finish = false
        /*  Before Loop */
        //  currentChaptersPath : [ 1, 2 ,3 ,4, 5 ]   //  [ 2, 3 ]
        //  chapterIdsPath : [ 1, 2, 6 ]              //  [ 1, 1.1 ]
        //  chaptersForInsert  : [ ]                  //  [ ]
        while (chapterIdsPath.length > 0 && !finish) {
          const currentChapterId = chapterIdsPath.pop() // remove last chapter Id
          const groupIndexInCurrentPath = currentChaptersPath.findIndex(
            chapterId => chapterId === currentChapterId
          )
          if (groupIndexInCurrentPath === -1) {
            chaptersForInsert.push(currentChapterId)
          } else {
            currentChaptersPath.length = groupIndexInCurrentPath + 1
            chaptersIndexs.length = groupIndexInCurrentPath + 1
            finish = true
          }
        }
        /*  After Loop */
        //  currentChaptersPath : [ 1, 2 ]  //  [ ]
        //  chapterIdsPath : [ 1, 2 ]       //  [ ]
        //  chaptersForInsert  : [ 6 ]      //  [ 1.1, 1 ]

        // in case there is no match chapter in currentChaptersPath
        if (!finish) {
          currentChaptersPath = []
          chaptersIndexs = []
        }
        let innerId = 0
        for (let j = chaptersForInsert.length - 1; j >= 0; j--) {
          const chapterId = chaptersForInsert[j]
          const currentChapter = chaptersById[chapterId]
          const parentChapter = chaptersById[currentChapter.parentId]
          const nodePathString = parentChapter
            ? parentChapter.nodePathString + ' > ' + currentChapter.name
            : currentChapter.name
          chaptersById[chapterId].nodePathString = nodePathString
          let nodeIndex = 0
          if (nodesPath[i].sessionNodeId) {
            const splittedSessionNodeId = nodesPath[i].sessionNodeId.split('_')
            if (splittedSessionNodeId[2]) {
              nodeIndex = splittedSessionNodeId[2]
            }
          }
          const id = `${workflowId}_${currentChapter.id}_${chaptersPath.length}_${innerId}_${nodeIndex}`
          innerId++
          const index = insertNodeOrChapterRecursive(
            {
              id,
              chapterId: currentChapter.id,
              name: currentChapter.name,
              color: currentChapter.color || '#ffffff',
              items: [],
              isGroup: true,
              wfId: workflowId,
              selected: true,
              nodePathString
            },
            angular.copy(chaptersIndexs),
            chaptersPath
          )
          currentChaptersPath.push(chapterId)
          chaptersIndexs.push(index)
        }
        let nodePathString = null
        if (nodePathObject.chapterId) {
          nodePathString =
            chaptersById[nodePathObject.chapterId].nodePathString +
            ' > ' +
            nodePathObject.title
          nodesById[nodePathObject.id].nodePathString = nodePathString
        }
        insertNodeOrChapterRecursive(
          {
            ...nodePathObject,
            originalIndex: i + 1,
            index,
            isGroup: false,
            nodePathString
          },
          angular.copy(chaptersIndexs),
          chaptersPath
        )
        hiddenByNodeId[nodePathObject.nodeId] = nodePathObject.hidden || false
        if (!nodePathObject.hidden) {
          index++
        }
      }
    }
    debug('getChaptersPath', chaptersPath)
    return {
      chaptersPath,
      hiddenByNodeId,
      chaptersById,
      nodesById
    }
  }

  return {
    isHTML,
    enterSession,
    generateFieldsColumns,
    generateToolsColumns,
    handleTranslationsAndWorkflowData,
    generateWorkflowEditPage,
    getFullChaptersPath
  }
}
module.exports = WorkflowUtilsService
