/* global angular */

/** @ngInject */
function AuthenticationTimeoutService (
  $timeout,
  $window,
  $rootScope,
  $injector
) {
  let timeoutPromise, warningPromise
  let timeoutDuration = -1 // Default to unlimited
  const storageKey = 'lastActivityTimestamp'
  const popupOpenKey = 'InactivityPopup'

  const startTimeout = function () {
    timeoutDuration = $rootScope.appSettings.authenticationTimeout
    if (!timeoutDuration || timeoutDuration === -1) return
    stopTimeout()
    timeoutPromise = $timeout(showWarning, timeoutDuration * 0.8 * 60 * 1000)
  }

  const stopTimeout = function () {
    if (timeoutPromise) $timeout.cancel(timeoutPromise)
    if (warningPromise) $timeout.cancel(warningPromise)
  }

  const resetTimeout = function () {
    if (timeoutDuration === -1) return

    stopTimeout()
    startTimeout()
  }

  const checkActivity = function () {
    if (timeoutDuration === -1) return

    const lastActivityTimestamp = parseInt(
      $window.localStorage.getItem(storageKey),
      10
    )
    if (Date.now() - lastActivityTimestamp > timeoutDuration * 60 * 1000) {
      $rootScope.logout()
    } else {
      resetTimeout()
    }
  }

  $window.addEventListener('storage', function (event) {
    if (event.key === storageKey) {
      checkActivity()
    }
  })

  const showWarning = function () {
    const minutesLeft = timeoutDuration * 0.2
    // Set up another timeout for actual session expiration
    warningPromise = $timeout(function () {
      $rootScope.logout()
    }, minutesLeft * 60 * 1000)
    // Show the warning popup
    showInactivityWarning(minutesLeft).then(async function (res) {
      if (!res.logout) {
        if (res.updateActivity) {
          // this is to trick the middleware to think that the user is active we get the user
          const UserModel = $injector.get('UserModel')
          await UserModel.findById({
            id: $rootScope.currentUser.id
          }).$promise.then(function () {
            resetTimeout()
            // Signal other tabs to close their popups
            $window.localStorage.setItem(popupOpenKey, 'false')
            $timeout(function () {
              $window.localStorage.removeItem(popupOpenKey)
            }, 100)
          })
        }
      } else {
        $rootScope.logout()
      }
    })
  }

  const showInactivityWarning = function (minutesLeft) {
    $window.localStorage.setItem(popupOpenKey, 'true')
    const $mdDialog = $injector.get('$mdDialog')
    return $mdDialog.show({
      controller: function ($scope, $translate, $mdDialog) {
        $scope.messageTitle = $translate.instant(
          'SETTINGS.SESSION_TIMEOUT_WARNING.MESSAGE_TITLE'
        )
        $scope.message = $translate.instant(
          'SETTINGS.SESSION_TIMEOUT_WARNING.MESSAGE',
          {
            firstName: $rootScope.currentUser.firstName,
            minutesLeft: Math.ceil(minutesLeft)
          }
        )
        $scope.logout = function () {
          $mdDialog.hide({
            logout: true,
            updateActivity: false
          })
        }
        $scope.continue = function () {
          $mdDialog.hide({
            logout: false,
            updateActivity: true
          })
        }
        $window.addEventListener('storage', function (event) {
          if (event.key === popupOpenKey && event.newValue === 'false') {
            $mdDialog.hide({
              logout: false,
              updateActivity: false
            })
          }
        })
      },
      controllerAs: 'vm',
      template: ` <md-dialog class="inactivity-warning" style="height: 50%; width: 50%;">
                              <md-toolbar>
                                <div class="md-toolbar-tools">
                                    <h2>{{messageTitle}}</h2>
                                    <span flex></span>
                                    <md-button class="md-icon-button" ng-click="continue()">
                                        <md-icon md-font-icon="icon-close">
                                        </md-icon>
                                    </md-button>
                                </div>
                              </md-toolbar>
                              <md-dialog-content style="height: 100%;">
                                <div>
                                  <h3>{{message}}</h3>
                                </div>
                              </md-dialog-content>
                              <md-dialog-actions>
                                <md-button translate="SETTINGS.SESSION_TIMEOUT_WARNING.LOGOUT" ng-click="logout()" class="md-raised md-warn md-primary">
                                </md-button>
                                <md-button translate="SETTINGS.SESSION_TIMEOUT_WARNING.CONTINUE" ng-click="continue()" class="md-raised md-accent md-primary">
                                </md-button>
                              </md-dialog-actions>
                          </md-dialog>
                `,
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      fullscreen: true
    })
  }

  const updateActivity = function () {
    $window.localStorage.setItem(storageKey, Date.now())
    checkActivity()
  }

  return {
    start: startTimeout,
    stop: stopTimeout,
    updateActivity
  }
}

module.exports = AuthenticationTimeoutService
