/* global _ */
const SystemVariablesDeclaration = require('../../../../common/constants/SystemVariablesDeclaration.js')

/** @ngInject */

function VariablesUtilsService ($rootScope, $translate, FieldUtilsService) {
  const workflowVariablesDeclaration = SystemVariablesDeclaration.getVariables(
    $rootScope.appSettings.modelsFields,
    'Workflow'
  )
  const formVariablesDeclaration = SystemVariablesDeclaration.getVariables(
    $rootScope.appSettings.modelsFields,
    'Form'
  )
  const workorderVariablesDeclaration = SystemVariablesDeclaration.getVariables(
    $rootScope.appSettings.modelsFields,
    'Workorder'
  )
  const productionEntityVariablesDeclaration =
    SystemVariablesDeclaration.getVariables(
      $rootScope.appSettings.modelsFields,
      'ProductionEntity'
    )

  const getPlainVariablesDeclaration = function getPlainVariablesDeclaration (
    modelName
  ) {
    switch (modelName) {
      case 'Workflow': {
        return workflowVariablesDeclaration
      }
      case 'Form': {
        return formVariablesDeclaration
      }
      case 'Workorder': {
        return workorderVariablesDeclaration
      }
    }
  }

  /**
   * There is an ERP itegration - manager / priority / sap
   * @returns boolean
   */
  const hasErpIntegration = function hasErpIntegration () {
    return $rootScope.appSettings.erpIntegration
  }

  /**
   * Create variable path
   * @param {object} object
   * @param {string} path
   * @param {string} prefix
   */
  const pathBuilder = function pathBuilder (object, path = null, prefix = '') {
    const pathArr = []
    Object.keys(object).forEach(key => {
      if (!object[key].type || key === 'productionEntity') {
        pathArr.push(
          ...pathBuilder(object[key], path ? `${path}.${key}` : key, prefix)
        )
      } else {
        const translationKey = object[key].translationKey
        if (!path) {
          pathArr.push({
            name: key,
            type: object[key].type,
            title: $translate.instant(translationKey)
          })
        } else {
          pathArr.push({
            name: `${path}.${key}`,
            type: object[key].type,
            title: $translate.instant(translationKey)
          })
        }
      }
    })
    return pathArr
  }

  /**
   * Create formly object
   * @param {object} object
   * @param {string} path
   * @param {string} prefix
   */
  const generateFormlyField = function generateFormlyField (key, type, label) {
    switch (type) {
      case 'kitArray':
        return {
          key,
          type: 'repeatingTemplate',
          templateOptions: {
            layout: 'row',
            title: label,
            btnText: $translate.instant('WO.TEMPLATE.BUTTON.ADD'),
            hideMovementButtons: true,
            addParameters: () => ({
              sku: '',
              quantity: 0
            }),
            onChange: function () {},
            fields: [
              {
                key: 'sku',
                type: 'input',
                className: 'flex margin-5',
                templateOptions: {
                  required: true,
                  label: $translate.instant('WF.SKU'),
                  onChange: function (value, options) {}
                }
              },
              {
                key: 'quantity',
                type: 'input',
                className: 'flex margin-5',
                templateOptions: {
                  required: true,
                  type: 'number',
                  label: $translate.instant('WF.QUANTITY'),
                  onChange: function (value, options) {}
                }
              }
            ]
          }
        }
      case 'boolean':
        return {
          key,
          type: 'checkbox',
          templateOptions: {
            label,
            onChange: function (value, options) {
              options.validation.errorExistsAndShouldBeVisible = null
              options.formControl.$setValidity('serverError', true)
            }
          }
        }
      case 'date':
      case 'today':
        return {
          key,
          type: 'dateTimePicker',
          templateOptions: {
            label,
            onChange: function (value, options) {
              options.validation.errorExistsAndShouldBeVisible = null
              options.formControl.$setValidity('serverError', true)
            }
          }
        }
      case 'number':
        return {
          key,
          type: 'input',
          templateOptions: {
            label,
            type: 'number',
            onChange: function (value, options) {
              options.validation.errorExistsAndShouldBeVisible = null
              options.formControl.$setValidity('serverError', true)
            }
          }
        }
      default:
        return {
          key,
          type: 'input',
          templateOptions: {
            label,
            onChange: function (value, options) {
              options.validation.errorExistsAndShouldBeVisible = null
              options.formControl.$setValidity('serverError', true)
            }
          }
        }
    }
  }

  /**
   * Create formly fields
   * @param {object} object
   * @param {string} prefix
   * @returns
   */
  const formlyBuilder = function formlyBuilder (
    object,
    path = null,
    prefix = ''
  ) {
    const formlyFields = []
    Object.keys(object).forEach(key => {
      if (!object[key].type) {
        formlyFields.push(
          ...formlyBuilder(object[key], path ? `${path}.${key}` : key, prefix)
        )
      } else {
        const translationKey = object[key].translationKey
        const type = object[key].type
        let label
        if (!path) {
          key = `${key}`
          label = $translate.instant(translationKey)
          formlyFields.push(generateFormlyField(key, type, label))
        } else {
          key = `${path}.${key}`
          label = $translate.instant(translationKey)
          formlyFields.push(generateFormlyField(key, type, label))
        }
      }
    })
    return formlyFields
  }

  /**
   * Create system variables array
   * @param {string} modelName
   * @returns array of variables
   */
  const getVariables = function getVariables (modelName) {
    switch (modelName) {
      case 'Workflow': {
        return pathBuilder(workflowVariablesDeclaration, null, 'WF.VARIABLES')
      }
      case 'Form': {
        return pathBuilder(formVariablesDeclaration, null, 'FORM.VARIABLES')
      }
      case 'Workorder': {
        return pathBuilder(
          workorderVariablesDeclaration,
          null,
          'WO.TEMPLATE.VARIABLES'
        )
      }
      case 'ProductionEntity': {
        return pathBuilder(
          productionEntityVariablesDeclaration,
          null,
          'PRODUCTION_ENTITY.TRIGGERS_DIALOG.VARIABLES'
        )
      }
    }
  }

  /**
   * Create system variables formly fields
   * @param {string} modelName
   * @returns array of formly fields
   */
  const getVariablesFormly = function getVariablesFormly (modelName) {
    switch (modelName) {
      case 'Workflow': {
        return formlyBuilder(workflowVariablesDeclaration, null, 'WF.VARIABLES')
      }
    }
  }

  /**
   * Create variables merged tag
   * @param {string} modelName
   * @param {boolean} removeLinks
   * @param {array} extraVariables
   * @returns
   */
  const getMergedTagsVariables = function getMergedTagsVariables (
    modelName,
    removeLinks = false,
    model,
    extraVariables = []
  ) {
    const variableByCategory = []
    const variables = [
      ...getVariables(modelName).map(variable => ({
        parent:
          variable.name.indexOf('.') === -1
            ? 'VARIABLES'
            : variable.name.split('.')[0],
        name: variable.name,
        title: variable.title
      }))
    ].concat(extraVariables)
    if (modelName === 'Workflow') {
      _.remove(variables, { name: 'workorder.kitArray' })
    }
    if (modelName === 'Form' && model) {
      if (!model.hasStatus) {
        _.remove(variables, { name: 'formDetails.status' })
        _.remove(variables, { name: 'formDetails.closedAt' })
      }
      if (!model.approvalWorkflows || model.approvalWorkflows.length === 0) {
        _.remove(variables, { name: 'approvalProcess.submittedForApproval' })
        _.remove(variables, { name: 'approvalProcess.approved' })
        _.remove(variables, { name: 'approvalProcess.rejected' })
      }
      if (!model.requireDeviceLink) {
        _.remove(variables, { name: 'device.serial' })
        _.remove(variables, { name: 'device.sku' })
        _.remove(variables, { name: 'device.lot' })
      }
    }
    if (removeLinks) {
      if (modelName === 'Form') {
        _.remove(variables, { name: 'formDetails.link' })
      } else if (modelName === 'Workorder') {
        _.remove(variables, { name: 'workorder.link' })
      } else if (modelName === 'sessionLink') {
        _.remove(variables, 'sessionLink')
      }
    }
    const categories = _.uniq(_.compact(_.map(variables, 'parent')))
    categories.forEach(category => {
      variableByCategory.push({
        label: $translate.instant(`VARIABLE_SELECT.GROUPS.${category}`),
        variables: variables.filter(variable => variable.parent === category)
      })
    })
    return variableByCategory
  }

  /**
   * Create HTML value to KitArray variable type
   * @param {array} value
   * @returns string
   */
  const generateKitArrayValueHTML = function generateKitArrayValueHTML (value) {
    let htmlValue = ''
    if (value && value.length) {
      htmlValue = '<div layout="column" layout-align="center start">'
      htmlValue += `<table class="image-based-form-display-table">
                      <thead>
                        <tr>
                          <th>
                            <span>
                              ${$translate.instant('WF.SKU')}
                            </span>
                          </th>
                          <th>
                            <span>
                              ${$translate.instant('WF.QUANTITY')}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>`
      for (let idx = 0; idx < value.length; idx++) {
        const row = value[idx]
        htmlValue += `<tr>
                        <td>${row.sku || ''}</td>
                        <td>${row.quantity || '0'}</td>
                      </tr>`
      }
      htmlValue += '</tbody></table></div>'
    }
    return htmlValue
  }

  const getAllWorkflowMergeTags = function getAllWorkflowMergeTags (
    workflow,
    forms,
    globalForms
  ) {
    const formIds = workflow.formIds || []
    forms = forms.filter(f => formIds.includes(f.id)).concat(globalForms)
    const fields = []
    const nodesById = _.keyBy(workflow.nodes, 'id')
    for (let i = 0; i < workflow.nodes.length; i++) {
      const nodeObject = workflow.nodes[i]
      const { id, title } = nodeObject
      const node = nodesById[id]
      if (node && node.fields && node.fields.length) {
        node.fields.forEach(field => {
          fields.push({
            parent: 'FIELDS',
            name: `field_${id}_${field.id}`,
            title: `(${title}) ${field.title}`
          })
        })
      }
    }
    const extraFields = [
      // {
      //   parent: 'CONFIGURATIONS',
      //   name: '{OPEN_CONFIGURATION}',
      //   title: $translate.instant('WF.OPEN_CONFIGURATIONS')
      // },
      // {
      //   parent: 'CONFIGURATIONS',
      //   name: '{OPEN_MASTER_CONFIGURATION}',
      //   title: $translate.instant('WF.OPEN_MASTER_CONFIGURATION')
      // },
      ...forms.map(form => ({
        parent: 'FORMS',
        name: `FORM_${form.id}`,
        title: form.name
      })),
      ...workflow.variables.map(variable => ({
        parent: 'VARIABLES',
        name: variable.name,
        title: variable.name
      })),
      ...fields
    ]
    const res = getMergedTagsVariables('Workflow', false, workflow, extraFields)
    return res
  }
  const getWorkflowVariablesForTinyMCE =
    function getWorkflowVariablesForTinyMCE (workflow, forms, globalForms) {
      const variables = getAllWorkflowMergeTags(workflow, forms, globalForms)
      const valid = []
      const mapper = {}
      const menus = []
      variables.forEach(variableGroup => {
        const menu = {
          text: variableGroup.label,
          menu: []
        }
        variableGroup.variables.forEach(variable => {
          valid.push(variable.name)
          mapper[variable.name] = `${variableGroup.label} → ${variable.title}`
          menu.menu.push({
            text: variable.title,
            value: variable.name
          })
        })
        menus.push(menu)
      })
      return { menu: menus, valid, mapper }
    }
  const getFormVariables = function getFormVariables (mergedFields, isHTML) {
    const fields = []
    if (!isHTML) {
      mergedFields = mergedFields.filter(
        mergedTagField =>
          !FieldUtilsService.nonFilterableTypes.includes(
            mergedTagField.originalType
          )
      )
    }
    for (let i = 0; i < mergedFields.length; i++) {
      const field = mergedFields[i]
      switch (field.category) {
        case 'fields':
          fields.push({
            parent: 'FIELDS',
            name: `field_${field.id}`,
            title: field.title
          })
          break
      }
    }
    return fields
  }
  const getProductionEntityParameters = function getProductionEntityParameters (
    mergedFields
  ) {
    const parameters = []
    for (let i = 0; i < mergedFields.length; i++) {
      const parameter = mergedFields[i]
      switch (parameter.category) {
        case 'parameters':
          parameters.push({
            parent: 'PARAMETERS',
            name: `parameter_${parameter.id}`,
            title: parameter.title
          })
          break
      }
    }
    return parameters
  }
  const getVariablesForTinyMCE = function getVariablesForTinyMCE (
    model,
    fields,
    isHTML,
    formModel
  ) {
    const variables = getMergedTagsVariables(model, !isHTML, formModel, fields)
    const valid = []
    const mapper = {}
    const menus = []
    variables.forEach(variableGroup => {
      const menu = {
        text: variableGroup.label,
        menu: []
      }
      variableGroup.variables.forEach(variable => {
        valid.push(variable.name)
        mapper[variable.name] = `${variableGroup.label} → ${variable.title}`
        menu.menu.push({
          text: variable.title,
          value: variable.name
        })
      })
      menus.push(menu)
    })
    return { menu: menus, valid, mapper }
  }

  return {
    getFormVariables,
    getVariablesForTinyMCE,
    getAllWorkflowMergeTags,
    getWorkflowVariablesForTinyMCE,
    getMergedTagsVariables,
    getVariablesFormly,
    generateKitArrayValueHTML,
    hasErpIntegration,
    getVariables,
    getPlainVariablesDeclaration,
    getProductionEntityParameters
  }
}

module.exports = VariablesUtilsService
