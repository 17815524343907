let _ = require('lodash')

/** @ngInject */

function WireUtils ($location) {
  {

    let getWire = (id, arr) => _.find(arr, ['id', id])

    let getValueForSearch = (nodes, arr) =>
      _.map(nodes, 'id').map((x) => getWire(x, arr).name).join(' ') + _.flatten(nodes).map(x => getValueForSearch(x.nodes, arr))

    let formatNodes = (str, arr) => {
      let tmp = []
      _.mapKeys(str, (value, key) => {
        tmp.push({
          id: value.id,
          name: getWire(value.id, arr).name,
          search: getWire(value.id, arr).name + ' ' + getValueForSearch(value.nodes, arr),
          nodes: _.isEmpty(value.nodes) ? [] : formatNodes(value.nodes, arr)
        })
      })
      return tmp
    }

    let makeIdTree = (obj, rootId) => {
      obj.rootId = rootId ? rootId + obj.id : obj.id
      obj.nodes = obj.nodes.map((x) => makeIdTree(x, obj.rootId + '_'))
      return obj
    }

    return {
      getWire,
      getValueForSearch,
      formatNodes,
      makeIdTree,
    }

  }

}

module.exports = WireUtils



