/*eslint-disable*/
import { mapOrder, isDate } from 'app/helper'
const kendoSetLang = require('./kendo-lang')
const debug = require('debug')('nextplus:kendo-datasource-helper-service')
const loopbackFilterConverter = require('loopback-filters')

/** @ngInject */
function KendoDataSourceHelper(
  $rootScope,
  $translate,
  $state,
  $stateParams,
  $compile,
  $interval,
  htmlWork,
  $window,
  MultiTranslateService
) {
  const WILDCARDS = {
    ASTERISK: '*',
    QUESTION_MARK: '?'
  }
  const WILDCARDS_REPLACEMENTS = {
    ASTERISK: `\.\*`,
    QUESTION_MARK: '.'
  }
  const testRegex = new RegExp(/.+\.(.*)/m)
  const escapeRegExp = function escapeRegExp(text) {
    debug('escapeRegExp')

    if (typeof text !== 'string') return text
    Object.keys(WILDCARDS).forEach(wildcard => {
      text = text.split(WILDCARDS[wildcard]).join(wildcard)
    })
    text = text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    Object.keys(WILDCARDS).forEach(wildcard => {
      text = text.split(wildcard).join(WILDCARDS_REPLACEMENTS[wildcard])
    })
    return text
  }
  // let GridInstance = function (options, scope, initialColumns) {
  //   const gridInstance = {}
  //   let gridInstanceRef
  //   scope.$on('$destroy', () => {
  //     if (gridInstanceRef?.instance) {
  //       gridInstanceRef.instance.destroy()
  //       console.log('Destroy grid instance')
  //     }
  //     if (resizeInterval) {
  //       $interval.cancel(resizeInterval)
  //     }
  //   })
  //   return new Promise((resolve, reject) => {

  //       const buildGridSchema = function buildGridSchema (options) {
  //         debug('KendoNextplusDataSource.buildGridSchema')

  //         const schema = { model: { fields: {} } }
  //         options.columns.forEach(column => {
  //           const field = column.field
  //           schema.model.fields[field] = {
  //             type: column.type
  //           }
  //         })
  //         return schema
  //       }

  //   })
  // }
  // return {
  //   GridInstance,
  //   calculateColumnAttributes,
  //   createComplexTypeMapping
  // }
  const kendoShadow = _.cloneDeep(kendo)
  // https://github.com/akera-io/akera-loopback-demo/tree/master/client/lib
  kendoShadow.Loopback = kendoShadow.Loopback || {}
  kendoShadow.Loopback.Filter = {}
  jQuery.extend(true, kendoShadow.Loopback.Filter, {
    getClause: function (flt) {
      // convert bracket [ ] notation to dot notation
      flt.field = flt.field.replace(/\[([^\]]+)\]/g, '.$1').replace(/"/g, '')
      // covert .UUID to UUID - for dynamic tables
      if (!testRegex.test(flt.field)) {
        flt.field = flt.field.replace(/\./g, '')
      }
      if (_.isString(flt.value)) {
        flt.value = flt.value.trim()
      }
      let clause = {}
      switch (flt.operator) {
        case 'eq':
          if (isDate(flt.value)) {
            let fromObj = {}
            const dateFrom = new Date(
              moment(flt.value, 'YYYY-MM-DD')
                .startOf('day')
                .add(1, 'days')
                .format('YYYY-MM-DD')
            )
            const fromOffSet = dateFrom.getTimezoneOffset() * 60000
            fromObj[flt.field] = {
              lt: new Date(dateFrom.getTime() + fromOffSet)
            }
            let toObj = {}
            const dateTo = new Date(
              moment(flt.value, 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD')
            )
            const toOffSet = dateTo.getTimezoneOffset() * 60000
            toObj[flt.field] = {
              gte: new Date(dateTo.getTime() + toOffSet)
            }
            clause = [fromObj, toObj]
          } else if (_.isString(flt.value)) {
            clause[flt.field] = {
              like: `^${escapeRegExp(flt.value)}$` /*eslint-disable-line*/,
              options: 'i'
            }
          } else {
            clause[flt.field] = flt.value
          }
          break
        case 'neq':
          if (isDate(flt.value)) {
            let fromObj = {}
            const dateFrom = new Date(
              moment(flt.value, 'YYYY-MM-DD')
                .startOf('day')
                .add(1, 'days')
                .format('YYYY-MM-DD')
            )
            const fromOffSet = dateFrom.getTimezoneOffset() * 60000
            fromObj[flt.field] = {
              gt: new Date(dateFrom.getTime() + fromOffSet)
            }
            let toObj = {}
            const dateTo = new Date(
              moment(flt.value, 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD')
            )
            const toOffSet = dateTo.getTimezoneOffset() * 60000
            toObj[flt.field] = {
              lt: new Date(dateTo.getTime() + toOffSet)
            }
            clause = { or: [fromObj, toObj] }
          } else {
            clause[flt.field] = {
              neq: flt.value
            }
          }
          break
        case 'gte':
          clause[flt.field] = {
            gte: flt.value
          }
          break
        case 'lte':
          clause[flt.field] = {
            lte: flt.value
          }
          break
        case 'lt':
          clause[flt.field] = {
            lt: flt.value
          }
          break
        case 'gt':
          clause[flt.field] = {
            gt: flt.value
          }
          break
        case 'contains':
          clause[flt.field] = {
            like: '.*' + escapeRegExp(flt.value) + '.*' /*eslint-disable-line*/,
            options: 'i'
          }
          break
        case 'doesnotcontain':
          clause[flt.field] = {
            nlike: '.*' + flt.value + '.*' /*eslint-disable-line*/,
            options: 'i'
          }
          break
        case 'startswith':
          clause[flt.field] = {
            like: flt.value + '.*' /*eslint-disable-line*/,
            options: 'i'
          }
          break
        case 'endswith':
          clause[flt.field] = {
            like: '.*' + flt.value /*eslint-disable-line*/,
            options: 'i'
          }
          break
        case 'isnull':
          clause[flt.field] = null
          break
        case 'isnotnull':
          clause[flt.field] = {
            neq: null
          }
          break
        case 'isempty':
          clause = {
            or: [
              { [flt.field]: { exists: false } },
              { [flt.field]: null },
              { [flt.field]: '' }
            ]
          }
          break
        case 'isnotempty':
          clause = [
            {
              [flt.field]: { exists: true },
              [flt.field]: {
                neq: null
              }
            },
            {
              [flt.field]: {
                neq: ''
              }
            }
          ]
          break
        case 'in':
          clause = [
            {
              [flt.field]: {
                in:
                  typeof flt.value === 'string'
                    ? flt.value.split(',')
                    : flt.value
              }
            }
          ]
          break
        default:
          console.error(
            'Filter operator ' + flt.operator + ' is not supported.'
          )
      }
      return clause
    },
    recursiveFilter: function (filter) {
      const self = this
      const loopbackFilter = {}
      if (filter.filters) {
        loopbackFilter[filter.logic] = []
        filter.filters.forEach(function (flt) {
          let res = self.recursiveFilter(flt)
          if (_.isArray(res)) {
            loopbackFilter[filter.logic] =
              loopbackFilter[filter.logic].concat(res)
          } else {
            loopbackFilter[filter.logic].push(res)
          }
        })
        return loopbackFilter
      } else return this.getClause(filter)
    },
    convert: function convert(filter, update) {
      const loopbackFilter = this.recursiveFilter(filter)
      return update === true
        ? loopbackFilter
        : {
            where: loopbackFilter
          }
    }
  })
  const find = (resultFilter, options, self) => {
    self.dataSource.loopbackFilter = resultFilter
    let findFilter = { filter: resultFilter }
    let counterFilter = { where: resultFilter.where || {} }

    const find = self.dataSource.options.find(findFilter).$promise
    const count = self.dataSource.options.count(counterFilter).$promise
    Promise.all([find, count]).then(values => {
      const rs = values[0]
      rs[self.dataSource.options.schema.total] = values[1].count
      options.success(rs)
    })
  }
  let LoopbackTransport = function () {}
  jQuery.extend(true, LoopbackTransport.prototype, {
    read: function (options) {
      const self = this
      const filter = {
        language: $rootScope.currentLang
      }
      filter.limit = options.data.pageSize || options.data.take || null
      if (
        this.dataSource.options.serverPaging === true &&
        options.data.page &&
        options.data.page > 1
      ) {
        filter.offset = options.data.skip
      }
      if (
        self.dataSource.options.baseFilter &&
        self.dataSource.options.baseFilter.order
      ) {
        filter.order = self.dataSource.options.baseFilter.order
      }
      if (
        self.dataSource.options.baseFilter &&
        self.dataSource.options.baseFilter.fields
      ) {
        filter.fields =
          self.dataSource.options.fields ||
          self.dataSource.options.baseFilter.fields
      }

      if (
        this.dataSource.options.serverSorting === true &&
        options.data.sort &&
        options.data.sort.length
      ) {
        filter.order = ''
        options.data.sort.forEach(function (sort) {
          sort.field = sort.field
            .replace(/\[([^\]]+)\]/g, '.$1')
            .replace(/"/g, '')
          // covert .UUID to UUID - for dynamic tables
          if (!testRegex.test(sort.field)) {
            sort.field = sort.field.replace(/\./g, '')
          }
          filter.order = sort.field + ' ' + sort.dir.toUpperCase()
        })
      }
      let newFilter = _.cloneDeep(options.data.filter)
      if (newFilter && newFilter.filters && newFilter.filters.length > 0) {
        newFilter.filters = newFilter.filters.map(filter1 => {
          if (self.dataSource.options.selectFields.includes(filter1.field)) {
            const options = filter1.value.split(',')
            if (options.length > 0) {
              let orObj = {
                logic: 'or',
                filters: []
              }
              options.map(value => {
                orObj.filters.push({
                  field: filter1.field,
                  operator: 'eq',
                  value: value
                })
              })
              filter1 = orObj
            }
          }
          return filter1
        })
      }

      if (this.dataSource.options.serverFiltering === true && newFilter) {
        jQuery.extend(
          true,
          filter,
          kendoShadow.Loopback.Filter.convert(newFilter, false)
        )
      }
      let resultFilter = _.cloneDeep(filter)
      if (
        self.dataSource.options.baseFilter &&
        self.dataSource.options.baseFilter.where
      ) {
        if (resultFilter.where) {
          if (resultFilter.where.and) {
            resultFilter.where.and.push(
              self.dataSource.options.baseFilter.where
            )
          } else {
            resultFilter.where.and = [self.dataSource.options.baseFilter.where]
          }
        } else {
          resultFilter.where = self.dataSource.options.baseFilter.where
        }
      }
      if (
        self.dataSource.options.baseFilter &&
        self.dataSource.options.baseFilter.include
      ) {
        resultFilter.include = self.dataSource.options.baseFilter.include
      }
      if (
        resultFilter.where &&
        resultFilter.where.and &&
        resultFilter.where.and.length === 0
      ) {
        resultFilter.where = {}
      }
      if (
        self.dataSource.options.baseFilter &&
        self.dataSource.options.baseFilter.deleted
      ) {
        resultFilter.deleted = self.dataSource.options.baseFilter.deleted
      }
      find(resultFilter, options, self)
    }
  })
  const KendoNextplusDataSource = kendoShadow.data.DataSource.extend({
    init: function (options) {
      debug('KendoNextplusDataSource.init')
      var transport = new LoopbackTransport()
      transport.dataSource = this
      options.transport = transport
      options.filterable = true
      options.baseFilter = options.baseFilter || {}
      options.cleanBaseFilter = options.cleanBaseFilter || {}
      options.selectFields = options.selectFields || []
      options.find = options.find
        ? options.find
        : () => {
            console.error('find fn not found')
          }
      options.count = options.count
        ? options.count
        : () => {
            console.error('Count fn not found')
          }
      options.serverFiltering = options.serverFiltering || true
      options.serverPaging = options.serverPaging || true
      options.serverSorting = options.serverSorting || true
      options.schema = options.schema || {}
      options.schema.total = options.schema.total || 'count'
      kendoShadow.data.DataSource.fn.init.call(this, options)
    }
  })

  return { KendoNextplusDataSource }
}

module.exports = KendoDataSourceHelper
