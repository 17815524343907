/* global angular */
const _ = require('lodash')

/** @ngInject */

function ErrorHandlerService ($translate, $mdDialog) {
  const messageErrorHandler = err => {
    console.log(err)
    const errorContext = err.data ? err.data.error : err
    const allErrors = []
    _.mapKeys(errorContext.details.codes, (values, key) => {
      values.map(val => {
        allErrors.push({
          field: key,
          value: $translate.instant('ERRORS.' + val)
        })
      })
    })
    $mdDialog.show(
      $mdDialog
        .alert()
        .clickOutsideToClose(true)
        .parent(angular.element(document.body))
        .title('Error in arguments while running function')
        .htmlContent(
          allErrors.map(error => `${error.field} - ${error.value}`).join('<br>')
        )
        .ok('OK')
    )
  }

  const formlyErrorHandler = (err, fields, form) => {
    console.log(err)
    if (err.status === 422 || err.statusCode === 422) {
      const allErrors = []
      const errorContext = err.data ? err.data.error : err
      const errorParams = errorContext.details
        ? errorContext.details.params
        : {}
      _.mapKeys(errorContext.details.messages, (values, key) => {
        values.map(val => {
          let translate = val.code
            ? $translate.instant('ERRORS.' + val.code)
            : val
          if (val.translationCode) {
            if (errorParams) {
              translate = $translate.instant(val.translationCode, errorParams)
            } else {
              translate = $translate.instant(val.translationCode)
            }
          }
          allErrors.push({ field: key, value: translate })
        })
      })
      allErrors.map(error => {
        let index = _.findIndex(fields, { key: error.field })
        if (index !== -1) {
          fields[index].validation.errorExistsAndShouldBeVisible = true
          fields[index].validation.messages = allErrors
          form[fields[index].id].$touched = true
          form[fields[index].id].$setValidity('serverError', false)
        } else {
          const fieldsWithGroups = fields.filter(field => field.fieldGroup)
          fieldsWithGroups.map(field => {
            index = _.findIndex(field.fieldGroup, { key: error.field })
            if (index !== -1) {
              field.fieldGroup[
                index
              ].validation.errorExistsAndShouldBeVisible = true
              field.fieldGroup[index].validation.messages = allErrors
              form[field.fieldGroup[index].id].$touched = true
              form[field.fieldGroup[index].id].$setValidity(
                'serverError',
                false
              )
            }
          })
        }
      })
    } else {
      console.log(err)
    }
  }

  const formErrorHandler = (res, form) => {
    console.log(res)
    if (res.status === 422) {
      _.forOwn(res.data.error.details.codes, function (value, key) {
        _.each(value, function (errType) {
          form[key].$setValidity(errType, false)
        })
      })
    } else {
      console.log(res)
    }
  }

  return {
    formlyErrorHandler,
    formErrorHandler,
    messageErrorHandler
  }
}

module.exports = ErrorHandlerService
