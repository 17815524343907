/* global angular */
import DeleteDialogController from '../common/dialog/delete-dialog/delete.dialog.controller'
import DuplicateDialogController from '../common/dialog/duplicate-dialog/duplicate.dialog.controller'
import ResetDialogController from '../common/dialog/reset-dialog/reset.dialog.controller'
/** @ngInject */
function DialogService ($mdDialog) {
  const duplicateDialog = function duplicateDialog (name) {
    const dialogConfiguration = {
      controller: DuplicateDialogController,
      template: require('../common/dialog/duplicate-dialog/duplicate.dialog.html'),
      parent: angular.element(document.body),
      targetEvent: '',
      locals: {
        name: name || null
      },
      clickOutsideToClose: false,
      escapeToClose: true,
      multiple: true,
      fullscreen: true
    }
    return $mdDialog.show(dialogConfiguration)
  }

  const deleteDialog = function deleteDialog (
    text,
    confirmText = null,
    extraFieldsObj
  ) {
    const dialogConfiguration = {
      controller: DeleteDialogController,
      template: require('../common/dialog/delete-dialog/delete.dialog.html'),
      parent: angular.element(document.body),
      targetEvent: '',
      locals: {
        text,
        confirmText,
        extraFieldsObj
      },
      clickOutsideToClose: false,
      escapeToClose: true,
      multiple: true,
      fullscreen: true
    }
    return $mdDialog.show(dialogConfiguration)
  }

  const resetDialog = function resetDialog (
    text,
    confirmText = null,
    extraFieldsObj
  ) {
    const dialogConfiguration = {
      controller: ResetDialogController,
      template: require('../common/dialog/reset-dialog/reset.dialog.html'),
      parent: angular.element(document.body),
      targetEvent: '',
      locals: {
        text,
        confirmText,
        extraFieldsObj
      },
      clickOutsideToClose: false,
      escapeToClose: true,
      multiple: true,
      fullscreen: true
    }
    return $mdDialog.show(dialogConfiguration)
  }

  return {
    deleteDialog,
    duplicateDialog,
    resetDialog
  }
}

module.exports = DialogService
