/* global angular $ */
/** @ngInject */
let anLoader = angular.module('anLoader', [])

// AVAILABLE SPINNERS TYPE
// sk-cube
// spinner-2dot
// spinner-bounce
// spinner-fading-circle
// spinner-pen
// spinner-square
// spinner-2cube
// spinner-3bounce
// spinner-circle
// spinner-folding-cube
// spinner-point

anLoader.factory('serviceAnLoader', ['$rootScope', '$window', function ($rootScope, $window) {
  this.calc = (id) => {
    this.id = id
    this.elem = angular.element(document.querySelector('#' + id))[0]
    this.bk = angular.element(document.querySelector('#BGoverlay'))[0]
    if (this.bk && this.elem) {
      this.bk.style.width = this.elem.clientWidth + 'px'
      this.bk.style.height = this.elem.clientHeight + 'px'
      this.bk.style.top = this.elem.offsetTop + 'px'
      this.bk.style.left = this.elem.offsetLeft + 'px'
      this.bk.style['z-index'] = 6002
    } else
    if (this.bk) {
      this.bk.style.width = '100%'
      this.bk.style.height = '100%'
      this.bk.style.top = '0px'
      this.bk.style.left = '0px'
      this.bk.style['z-index'] = 6002
    }
  }
  $('#' + this.id).resize(function () {
    this.calc(this.id)
  })

  angular.element($window).bind('resize', () => {
    this.calc(this.id)
  })

  $rootScope.$watch('showState', (newValue, oldValue) => this.calc(this.id))

  this.start = () => {
    this.calc(this.id)
    $rootScope.showState = 'show'
  }

  this.stop = () => ($rootScope.showState = '')

  return this
}])

anLoader.directive('anLoader', ['serviceAnLoader', '$rootScope', function (serviceAnLoader, $rootScope) {
  return {
    restrict: 'E',
    link: (scope, element, attrs) => {
      serviceAnLoader.calc(attrs.id)
      scope.anLoader_getContentUrl = function () {
        return (attrs.templatefolder || 'templates') + '/' + attrs.loaderType + '.html'
      }
      $rootScope.anLoader_http = attrs.http || false
    },
    template: '<div ng-include="anLoader_getContentUrl()"></div>'
  }
}])
