/* global angular */
import UUID from 'uuid'

/** @ngInject */
function PanelHelper ($mdPanel, $mdMedia) {
  const getCenterPosition = function getCenterPosition () {
    return $mdPanel
      .newPanelPosition()
      .absolute()
      .center()
  }

  const OPERATIONS = {
    OPEN: 'OPEN',
    CLOSE: 'CLOSE'
  }

  const defaultConfig = {
    attachTo: angular.element(document.body),
    propagateContainerEvents: false,
    panelClass: 'default-dialog-panel',
    zIndex: 80,
    animation: $mdPanel
      .newPanelAnimation()
      .openFrom(document.body)
      .duration(250)
      .withAnimation($mdPanel.animation.FADE),
    clickOutsideToClose: false,
    escapeToClose: false,
    trapFocus: true,
    focusOnOpen: true,
    fullscreen: $mdMedia('xs') || $mdMedia('sm') || $mdMedia('md'),
    hasBackdrop: true,
    disableParentScroll: true
  }

  const openPanel = function openPanel (panelConfiguration = {}) {
    const panelId = UUID()
    return new Promise((resolve, reject) => {
      const configuration = {
        ...defaultConfig,
        id: panelId,
        position: getCenterPosition(),
        ...panelConfiguration,
        onCloseSuccess: (panelRef, data) => {
          const {
            _$mdPanel: { closeReasons }
          } = panelRef
          panelRef.destroy()
          if (typeof panelConfiguration.savePanelRefFunction === 'function') {
            panelConfiguration.savePanelRefFunction(
              OPERATIONS.CLOSE,
              panelId,
              panelRef
            )
          }
          if (
            typeof data === 'string' &&
            (data === closeReasons.CLICK_OUTSIDE ||
              data === closeReasons.ESCAPE)
          ) {
            return reject() //eslint-disable-line
          }
          resolve(data)
        }
      }

      $mdPanel.open(configuration).then(function (panelRef) {
        if (typeof panelConfiguration.savePanelRefFunction === 'function') {
          panelConfiguration.savePanelRefFunction(
            OPERATIONS.OPEN,
            panelId,
            panelRef
          )
        }
      })
    })
  }

  return {
    OPERATIONS,
    openPanel
  }
}

module.exports = PanelHelper
