/* global _ */

/** @ngInject */
function WizardConditionsService (htmlWork, $translate, DateTimeFormatService) {
  const blackListFieldTypes = [
    'upload',
    'button',
    'subForm',
    'imageBasedForm',
    'gpsInput',
    'selectUser'
  ]

  const operatorsNames = {}

  const operators = {
    boolean: ['changes', 'equal', 'not_equal', 'changes_from', 'changes_to'],
    date: [
      'before',
      'after',
      'equal',
      'not_equal',
      'is_null',
      'is_not_null',
      'changes_from',
      'changes_to',
      'changes'
    ],
    string: [
      'equal',
      'not_equal',
      'is_null',
      'is_not_null',
      'includes',
      'changes_from',
      'changes_to',
      'changes'
    ],
    number: [
      'equal',
      'not_equal',
      'is_null',
      'is_not_null',
      'less',
      'less_or_equal',
      'greater',
      'greater_or_equal',
      'between',
      'not_between',
      'changes_from',
      'changes_to',
      'changes'
    ],
    kitArray: ['includesPositive', 'includesNegative'],
    tinymce: ['changes', 'not_equal', 'is_null', 'is_not_null', 'includes']
  }

  const allOperators = _.uniq(_.flatten(_.values(operators)))

  allOperators.forEach(op => {
    operatorsNames[op] = $translate.instant(`CONDITIONS_WIZARD.OPERATORS.${op}`)
  })

  const valueFormatter = function valueFormatter (
    field,
    operator,
    value,
    allFields
  ) {
    const fieldById = _.keyBy(allFields, 'id')
    const valueIsField = fieldById[value]
    if (valueIsField) {
      return valueIsField.title
    }
    if (operator === 'changes') return ''
    if (
      ['changes_to', 'changes_from'].includes(operator) &&
      _.isArray(value) &&
      value.length === 2
    ) {
      const valueAIsField = fieldById[value[0]]
      const valueA = valueAIsField ? valueAIsField.title : value[0]
      return ` ${valueA}`
    }
    if (field && field.type === 'date') {
      return operator !== 'is_null' && operator !== 'is_not_null'
        ? DateTimeFormatService.formatDateTime(value, 'date')
        : ''
    } else if (field && field.type === 'dateTime') {
      return operator !== 'is_null' && operator !== 'is_not_null'
        ? DateTimeFormatService.formatDateTime(value, 'dateTime')
        : ''
    } else if (field && field.type === 'checkbox') {
      if (value) return $translate.instant('CONDITIONS_WIZARD.CHECKED')
      return $translate.instant('CONDITIONS_WIZARD.NOT_CHECKED')
    } else if (
      field &&
      field.type === 'number' &&
      (operator === 'between' || operator === 'not_between') &&
      _.isArray(value) &&
      value.length === 2
    ) {
      const valueAIsField = fieldById[value[0]]
      const valueA = valueAIsField ? valueAIsField.title : value[0]
      const valueBIsField = fieldById[value[1]]
      const valueB = valueBIsField ? valueBIsField.title : value[1]
      return ` ${valueA} ${$translate.instant(
        'CONDITIONS_WIZARD.TO'
      )} ${valueB}`
    }
    return _.isNil(value) ? '' : value
  }

  const createConditionHTML = function createConditionHTML (fields, obj) {
    const { values, type } = obj
    const fieldsById = _.keyBy(fields, 'id')
    let html =
      '<div layout="row" layout-align="start center" style="flex-wrap: wrap;">'
    values.forEach((valueObject, idx) => {
      const { id, operator, value } = valueObject
      const field = fieldsById[id]
      const unrecognizedTitle = isUUID(id)
        ? 'CONDITIONS_WIZARD.UNRECOGNIZED_FIELD'
        : 'CONDITIONS_WIZARD.UNRECOGNIZED_VARIABLE'
      const fieldTitle = field
        ? field.title
        : `<span style="color:red;">${$translate.instant(
            unrecognizedTitle
          )}</span>`
      if (idx > 0) {
        html += `&nbsp;<span style="color:#039be5;">${
          type === 'or' || type === 'OR'
            ? $translate.instant('CONDITIONS_WIZARD.OR')
            : $translate.instant('CONDITIONS_WIZARD.AND')
        }</span>&nbsp;`
      }
      html += `<b>${fieldTitle}</b>&nbsp;${
        operatorsNames[operator]
      }&nbsp;${valueFormatter(field, operator, value, fields)}`
    })
    html += '</div>'
    return html
  }

  const isUUID = function isUUId (name) {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      name
    )
  }

  const isWorkflowField = function isWorkflowField (name) {
    return /field_(.*)_(.*)/gm.test(name)
  }

  const isWorkflowVariable = function isWorkflowVariable (name) {
    return /variable_(.*)/gm.test(name)
  }

  const workflowValueFormatter = function workflowValueFormatter (
    field,
    operator,
    value,
    allFields,
    nodes
  ) {
    const valueIsField = allFields.find(f => f.id === value)
    if (valueIsField) {
      const [, nodeId] = valueIsField.id.split('_')
      const node = nodes[nodeId]
      if (node) return `(${node.name}) ${valueIsField.title}`
      return valueIsField.title
    }
    if (field && field.type === 'date') {
      return operator !== 'is_null' && operator !== 'is_not_null'
        ? DateTimeFormatService.formatDateTime(value, 'date')
        : ''
    } else if (field && field.type === 'dateTime') {
      return operator !== 'is_null' && operator !== 'is_not_null'
        ? DateTimeFormatService.formatDateTime(value, 'dateTime')
        : ''
    } else if (field && field.type === 'kitArray') {
      return value.join(', ')
    } else if (field && field.type === 'checkbox') {
      if (value) return $translate.instant('CONDITIONS_WIZARD.CHECKED')
      return $translate.instant('CONDITIONS_WIZARD.NOT_CHECKED')
    } else if (
      field &&
      field.type === 'number' &&
      (operator === 'between' || operator === 'not_between') &&
      _.isArray(value) &&
      value.length === 2
    ) {
      const valueAIsField = allFields.find(f => f.id === value[0])
      const valueA = valueAIsField ? valueAIsField.title : value[0]
      const valueBIsField = allFields.find(f => f.id === value[1])
      const valueB = valueBIsField ? valueBIsField.title : value[1]
      return ` ${valueA} ${$translate.instant(
        'CONDITIONS_WIZARD.TO'
      )} ${valueB}`
    }
    return _.isNil(value) ? '' : value
  }

  const createWorkflowConditionHTML = function createWorkflowConditionHTML (
    nodes,
    fields,
    obj
  ) {
    if (obj && obj.rules) {
      const { rules, condition } = obj
      const fieldsById = _.keyBy(fields, 'id')
      let html =
        '<div layout="row" layout-align="start center" style="flex-wrap: wrap;">'
      for (let i = 0; i < rules.length; i++) {
        const ruleObject = rules[i]
        const { id, operator, value } = ruleObject
        if (i > 0) {
          html += `&nbsp;<span style="color:#039be5;">${
            condition === 'or' || condition === 'OR'
              ? $translate.instant('CONDITIONS_WIZARD.OR')
              : $translate.instant('CONDITIONS_WIZARD.AND')
          }</span>&nbsp;`
        }
        const field = fieldsById[id]
        const unrecognizedTitle = id.includes('variable_')
          ? 'CONDITIONS_WIZARD.UNRECOGNIZED_VARIABLE'
          : 'CONDITIONS_WIZARD.UNRECOGNIZED_FIELD'
        let fieldTitle = field
          ? field.title
          : `<span style="color:red;">${$translate.instant(
              unrecognizedTitle
            )}</span>`
        if (isWorkflowField(id)) {
          const [, nodeId] = id.split('_')
          if (nodes[nodeId]) {
            fieldTitle = `(${nodes[nodeId].name}) ${fieldTitle}`
          }
        }
        html += `<b>${htmlWork.htmlEncode(fieldTitle)}</b>&nbsp;${
          operatorsNames[operator]
        }&nbsp;${htmlWork.htmlEncode(
          workflowValueFormatter(field, operator, value, fields, nodes)
        )}`
      }
      html += '</div>'
      return html
    } else {
      return ''
    }
  }

  return {
    operators,
    allOperators,
    blackListFieldTypes,
    isWorkflowField,
    isWorkflowVariable,
    createConditionHTML,
    createWorkflowConditionHTML
  }
}

module.exports = WizardConditionsService
