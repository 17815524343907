/** @ngInject */

function ResourceUtils (
  $rootScope,
  $location,
  Resource,
  $window,
  getUrlFromObj
) {
  const createDownloadLink = data => {
    const port =
      $location.port() === 80 || $location.port() === 443
        ? ''
        : `:${$location.port()}`
    const url = `${$location.protocol()}://${$location.host()}${port}/api/containers/${
      data.container
    }/download/${data.name}`
    return encodeURI(url)
  }

  const createDownloadLinkFromId = async resourceId => {
    const data = await Resource.findOne({
      filter: { where: { id: resourceId } }
    }).$promise
    const port =
      $location.port() === 80 || $location.port() === 443
        ? ''
        : `:${$location.port()}`
    const url = `${$location.protocol()}://${$location.host()}${port}/api/containers/${
      data.container
    }/download/${data.name}`
    return encodeURI(url)
  }

  const download = id => {
    Resource.findOne({ filter: { where: { id: id } } }).$promise.then(data => {
      const port = $location.port() === 8080 ? 3000 : $location.port()
      $window.open(
        encodeURI(
          `${$location.protocol()}://${$location.host()}:${port}/api/containers/${
            data.container
          }/download/${data.name}`
        )
      )
    })
  }

  const downloadByName = (container, filename) => {
    const port = $location.port() === 8080 ? 3000 : $location.port()
    $window.open(
      encodeURI(
        `${$location.protocol()}://${$location.host()}:${port}/api/containers/${container}/download/${filename}`
      )
    )
  }

  const getLink = async id => {
    const res = await Resource.findOne({
      filter: { where: { id: id } }
    }).$promise
    return res ? `api/containers/${res.container}/download/${res.name}` : ''
  }

  const getResource = async id => {
    const res = await Resource.findOne({
      filter: { where: { id: id } }
    }).$promise
    return res
  }

  const generateHLSSrc = function generateHLSSrc (resource) {
    let videoSrc = ''
    const { NEXTPLUS_HAS_HLS } = $rootScope.appSettings
    if (
      NEXTPLUS_HAS_HLS &&
      resource &&
      resource.optimalFiles &&
      resource.optimalFiles.length
    ) {
      // When NEXTPLUS_HAS_HLS && resource have multiple sizes
      // /hls/Users/93439348-1617182664944,,-medium,-large,.mp4.urlset/master.m3u8
      const nameWithoutExt = resource.name.replace(`.${resource.ext}`, '')
      videoSrc = `/hls/${resource.container}/${nameWithoutExt},`
      resource.optimalFiles.forEach(optimalFile => {
        const { name } = optimalFile
        videoSrc += `,-${name}`
      })
      videoSrc += `,.${resource.ext}.urlset/master.m3u8`
    } else if (
      resource &&
      resource.optimalFiles &&
      resource.optimalFiles.length
    ) {
      // resource have multiple sizes
      const large = resource.optimalFiles.find(f => f.name === 'large')
      if (large) {
        videoSrc = `/api/containers/${resource.container}/download/${large.fileName}`
      } else {
        videoSrc = getUrlFromObj(resource)
      }
    } else {
      // resource without multiple sizes
      videoSrc = getUrlFromObj(resource)
    }
    return videoSrc
  }

  return {
    getLink,
    getResource,
    download,
    downloadByName,
    createDownloadLink,
    createDownloadLinkFromId,
    generateHLSSrc
  }
}

module.exports = ResourceUtils
