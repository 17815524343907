/*eslint-disable*/

/** @ngInject */
function EventService($translate, $rootScope, nextplusSocket) {
  {
    let saveEvent = (eventData) => {
      nextplusSocket.angularSocket.emit('event', eventData)
    }

    return {
      saveEvent
    }
  }
}

module.exports = EventService

