/*eslint-disable*/
let _ = require('lodash')

/** @ngInject */
function PermissionUtils($location, $rootScope) {
  {
    function AccessRequest(
      model,
      property,
      accessType,
      permission,
      methodNames,
      registry
    ) {
      if (!(this instanceof AccessRequest)) {
        return new AccessRequest(
          model,
          property,
          accessType,
          permission,
          methodNames
        )
      }
      if (arguments.length === 1 && typeof model === 'object') {
        // The argument is an object that contains all required properties
        var obj = model || {}
        this.model = obj.model || '*'
        this.property = obj.property || '*'
        this.accessType = obj.accessType || '*'
        this.permission = obj.permission || 'DEFAULT'
        this.methodNames = obj.methodNames || []
        this.registry = obj.registry
      } else {
        this.model = model || '*'
        this.property = property || '*'
        this.accessType = accessType || '*'
        this.permission = permission || 'DEFAULT'
        this.methodNames = methodNames || []
        this.registry = registry
      }
      // do not create AccessRequest without a registry
      /* assert(this.registry,
         'Application registry is mandatory in AccessRequest but missing in provided argument(s)'); */
    }

    let isWildcard = function (obj) {
      return obj.model === '*' || obj.property === '*' || obj.accessType === '*'
    }

    let permissionOrder = {
      DEFAULT: 0,
      ALLOW: 1,
      ALARM: 2,
      AUDIT: 3,
      DENY: 4
    }

    let getMatchingScore = function getMatchingScore(rule, req) {
      var props = ['model', 'property', 'accessType']
      var score = 0

      for (var i = 0; i < props.length; i++) {
        // Shift the score by 4 for each of the properties as the weight
        score = score * 4
        var ruleValue = rule[props[i]] || '*'
        var requestedValue = req[props[i]] || '*'
        var isMatchingMethodName =
          props[i] === 'property' && req.methodNames.indexOf(ruleValue) !== -1

        var isMatchingAccessType = ruleValue === requestedValue
        if (props[i] === 'accessType' && !isMatchingAccessType) {
          switch (ruleValue) {
            case 'EXECUTE':
              // EXECUTE should match READ, REPLICATE and WRITE
              isMatchingAccessType = true
              break
            case 'WRITE':
              // WRITE should match REPLICATE too
              isMatchingAccessType = requestedValue === 'REPLICATE'
              break
          }
        }

        if (isMatchingMethodName || isMatchingAccessType) {
          // Exact match
          score += 3
        } else if (ruleValue === '*') {
          // Wildcard match
          score += 2
        } else if (requestedValue === '*') {
          score += 1
        } else {
          // Doesn't match at all
          return -1
        }
      }

      // Weigh against the principal type into 4 levels
      // - user level (explicitly allow/deny a given user)
      // - app level (explicitly allow/deny a given app)
      // - role level (role based authorization)
      // - other
      // user > app > role > ...
      score = score * 4
      switch (rule.principalType) {
        case 'USER':
          score += 4
          break
        case 'APP':
          score += 3
          break
        case 'ROLE':
          score += 2
          break
        default:
          score += 1
      }

      // Weigh against the roles
      // everyone < authenticated/unauthenticated < related < owner < ...
      score = score * 8
      if (rule.principalType === 'ROLE') {
        switch (rule.principalId) {
          case '$owner':
            score += 4
            break
          case '$related':
            score += 3
            break
          case '$authenticated':
          case '$unauthenticated':
            score += 2
            break
          case '$everyone':
            score += 1
            break
          default:
            score += 5
        }
      }
      score = score * 4
      score += permissionOrder[rule.permission || 'ALLOW'] - 1
      return score
    }

    function resolvePermission(acls, req) {
      if (!(req instanceof AccessRequest)) {
        req = new AccessRequest(req)
      }
      // Sort by the matching score in descending order
      acls = acls.sort(function (rule1, rule2) {
        return getMatchingScore(rule2, req) - getMatchingScore(rule1, req)
      })
      var permission = 'DEFAULT'
      var score = 0

      for (var i = 0; i < acls.length; i++) {
        var candidate = acls[i]
        score = getMatchingScore(candidate, req)
        if (score < 0) {
          // the highest scored ACL did not match
          break
        }
        if (!isWildcard(req)) {
          // We should stop from the first match for non-wildcard
          permission = candidate.permission
          break
        } else {
          if (req.exactlyMatches(candidate)) {
            permission = candidate.permission
            break
          }
          // For wildcard match, find the strongest permission
          var candidateOrder =
            AccessContext.permissionOrder[candidate.permission]
          var permissionOrder = AccessContext.permissionOrder[permission]
          if (candidateOrder > permissionOrder) {
            permission = candidate.permission
            break
          }
        }
      }

      return permission === req.permission
    }

    if (!$rootScope.appSettings) $rootScope.appSettings = {}
    if ($rootScope.appSettings.methodType)
      $rootScope.appSettings.methodType = {}

    let checkAccordingReplicationSettings = (model, accessType, isPermit) => {
      const settingsForModel = $rootScope.appSettings.replicationSettings[model]
      if ($rootScope.appSettings.isslave === true && accessType === 'WRITE') {
        return isPermit && settingsForModel && settingsForModel.push
      }

      return isPermit
    }

    /*
     * model : name of model (required)
     * method : name of method (required)
     * permission : name of permission (optional , DENY/ALLOW/DEFAULT)
     */
    let isPermit = (model, method, permission, ownerId) => {
      if (!$rootScope.currentUser) return false
      if (
        $rootScope.appSettings.methodType[model] &&
        !$rootScope.appSettings.methodType[model][method]
      ) {
        console.warn(
          `PermissionUtils is using a method that no longer exstis. model: ${model}, method: ${method}`
        )
      }
      let perm = {
        model: model,
        property: method,
        accessType:
          $rootScope.appSettings.methodType[model] &&
          $rootScope.appSettings.methodType[model][method]
            ? $rootScope.appSettings.methodType[model][method]
            : 'READ',
        permission: permission || 'ALLOW'
      }
      let resultACL = $rootScope.currentUser.acls[model] || []
      if (ownerId !== null && ownerId === $rootScope.currentUser.id) {
        resultACL = resultACL.concat(
          $rootScope.appSettings.ownerACL[model] || []
        )
      }
      let resolved = resolvePermission(resultACL || [], perm)
      return $rootScope.appSettings.NEXTPLUS_REPLICATION
        ? checkAccordingReplicationSettings(model, perm.accessType, resolved)
        : resolved
    }

    return {
      isPermit
    }
  }
}

module.exports = PermissionUtils
