/* global angular _ */
const anime = require('../../vendors/anime').default
/** @ngInject */
function SyncUtils (
  nextplusSocket,
  $mdDialog,
  $window,
  Sync,
  $timeout,
  $rootScope,
  $translate
) {
  $rootScope.scrollSyncMsg = () => {
    $timeout(
      function () {
        const scroller = document.getElementById('autoscroll')
        scroller.scrollTop = scroller.scrollHeight
      },
      0,
      false
    )
  }
  $rootScope.shadowEnable = false
  $rootScope.syncIsRunning = false
  $rootScope.syncIsError = false
  $rootScope.syncMsgs = []

  $rootScope.hideSyncBar = () => {
    $rootScope.shadowEnable = false
    $rootScope.syncIsRunning = false
    $rootScope.syncIsError = false
    $rootScope.syncMsgs = []
  }

  nextplusSocket.angularSocket.on('sync', data => {
    const index = _.findIndex($rootScope.syncMsgs, ['type', data.type])
    if (index === -1) {
      $rootScope.syncMsgs.unshift({
        type: data.type,
        msg: $translate.instant(data.msg)
      })
    } else {
      $rootScope.syncMsgs[index].msg = $translate.instant(data.msg)
    }
    // $rootScope.scrollSyncMsg()
  })

  nextplusSocket.angularSocket.on('need-sync', () => {
    $mdDialog
      .show({
        controller: /** @ngInject */ function ($scope, $interval) {
          $scope.TIME_BEFORE_AUTO_SYNC = 10
          const interval = $interval(() => {
            $scope.TIME_BEFORE_AUTO_SYNC--
            if ($scope.TIME_BEFORE_AUTO_SYNC === 0) {
              if (interval) {
                $interval.cancel(interval)
              }
              $mdDialog.hide()
            }
          }, 1000)

          $scope.startSync = () => {
            if (interval) {
              $interval.cancel(interval)
            }
            $mdDialog.hide()
          }
          $scope.cancel = () => {
            $mdDialog.cancel()
          }
        },
        template: `<md-dialog class="need-sync-dialog">
                      <md-dialog-content>
                        <h3 translate="SETTINGS.SYNC.DO_YOU_WANT_SYNC_NOW"></h3>
                      </md-dialog-content>
                      <md-dialog-actions layout="row" layout-align="end center">
                        <md-button class="md-raised md-warn" ng-click="cancel()">
                          <span translate="BUTTONS.CANCEL"></span>
                        </md-button>
                        <md-button class="md-raised md-accent" ng-click="startSync()">
                          <span translate="COMMON.START_SYNC"></span> <span>({{TIME_BEFORE_AUTO_SYNC}})</span>
                        </md-button>
                      </md-dialog-actions>
                    </md-dialog>`,
        multiple: true,
        skipHide: true,
        fullscreen: true,
        clickOutsideToClose: false,
        escapeToClose: false,
        parent: angular.element(document.body)
      })
      .then(
        function () {
          startSync('http', true)
          nextplusSocket.angularSocket.emit('need-sync')
        },
        function () {}
      )
  })

  nextplusSocket.angularSocket.on('sync-finish', data => {
    $timeout(function () {
      if (!$rootScope.syncIsError) {
        $rootScope.hideSyncBar()
        $window.location.reload()
      }
    }, 1000)
  })

  nextplusSocket.angularSocket.on('sync-error', e => {
    console.log(e)
    $rootScope.shadowEnable = true
    $rootScope.syncIsRunning = true
    $rootScope.syncIsError = true
    $rootScope.syncError = e
    // $rootScope.scrollSyncMsg()
  })

  const animations = function animations () {
    // TODO: Fix animations
    let msgIndex = 0

    const messages = [
      $translate.instant('SETTINGS.SYNC.SENTENCES.UPDATE_DB'),
      $translate.instant('SETTINGS.SYNC.SENTENCES.UPDATE_FILES'),
      $translate.instant('SETTINGS.SYNC.SENTENCES.FINALIZE')
    ]
    const doAnimation = () => {
      console.log('doAnimation')
      anime
        .timeline({
          loop: false
        })
        .add({
          targets: '#syncAnimation .letter',
          opacity: [0, 1],
          easing: 'easeOutExpo',
          duration: 2250,
          delay: (el, i) => 75 * (i + 1)
        })
        .add({
          targets: '#syncAnimation',
          duration: 1000,
          opacity: [1, 0],
          easing: 'easeInExpo',
          delay: 1000,
          complete: function (anim) {
            console.log('complete')
            msgIndex++
            if (typeof messages[msgIndex] === 'undefined') {
              msgIndex = 0
            }
            if ($rootScope.syncIsRunning) {
              updateText(msgIndex)
            }
          }
        })
    }
    const updateText = i => {
      const textWrapper = document.querySelector('#syncAnimation')
      if (textWrapper) {
        // Wrap every letter in a span
        textWrapper.innerHTML = messages[i].replace(
          /\S/g,
          "<span class='letter'>$&</span>"
        )
        doAnimation()
      }
    }
    updateText(msgIndex)
  }

  const triggerSync = function triggerSync (method, isLoginPage) {
    // if (method === 'fs') {
    //   if (file) {
    //     UploadService.uploadFile(file, 'api/makeLoginFsSync', true)
    //       .then(() => {
    //         $rootScope.shadowEnable = true
    //         $rootScope.syncIsRunning = true
    //         $rootScope.syncMsgs = []
    //         animations()
    //       })
    //       .catch(console.log)
    //   }
    // } else {
    $rootScope.shadowEnable = true
    $rootScope.syncIsRunning = true
    $rootScope.syncMsgs = []
    animations()
    Sync.syncClient()
      .$promise.then(console.log)
      .catch(console.log)
  }

  const getSyncZip = function getSyncZip (file) {
    // TODO: Implement 'fs' method
    // triggerSync('fs', null, file)
  }

  const downloadSyncZip = function downloadSyncZip () {
    $window.location = './api/UpdateInfos/downloadSyncZip'
  }

  const startSync = function startSync (method, isLoginPage = false) {
    // TODO: Implement 'fs' method
    // if (method === 'fs') {
    //   $('#login-sync-zip-upload-btn').click()
    // } else {
    //   console.log(`We make Sync with ${method} at login page ${isLoginPage}`)
    triggerSync(method, isLoginPage)
    // }
  }

  const syncClient = function syncClient () {
    $rootScope.shadowEnable = true
    $rootScope.syncIsRunning = true
    $rootScope.syncIsError = false
    $rootScope.syncMsgs = []
    Sync.syncClient()
    animations()
  }

  return {
    startSync,
    syncClient,
    downloadSyncZip,
    getSyncZip
  }
}

module.exports = SyncUtils
