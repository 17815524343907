/*eslint-disable*/

/** @ngInject */
function BreakService($translate, $rootScope, FormlyHelper, Break, BreakType) {
  {
    let pad = num => ('0' + num).slice(-2)

    let hhmmss = secs => {
      if (!secs) return '00:00:00'
      var minutes = Math.floor(secs / 60)
      secs = secs % 60
      var hours = Math.floor(minutes / 60)
      minutes = minutes % 60
      return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
    }

    let timePass = ''

    let showDialog = (start, breakId) => {
      document.getElementById('break').style.display = 'block'
    }
    let hideDialog = () => {
      document.getElementById('break').style.display = 'none'
    }

    let breakInstance = null

    $rootScope.$on('break-exist', (ev, data) => {
      breakInstance = {
        start: data.start,
        breakId: data.breakId
      }
      showDialog()
    })
    $rootScope.$on('break-end', hideDialog)
    let start = () => {
      if (breakInstance === null) {
        Break.start().$promise.then(data => {
          breakInstance = data.result
          showDialog(data.result.start, data.result.breakId)
          $rootScope.$broadcast('break-created')
        })
      }
    }
    let stop = breakTypeId => {
      return Break.stop({
        breakId: breakInstance.breakId,
        breakTypeId
      }).$promise.then(() => {
        breakInstance = null
        hideDialog()
      })
    }
    let getTimePass = () => {
      if (!breakInstance) {
        return ''
      }
      let a = moment(new Date())
      let b = moment(breakInstance.start)
      let duration = a.diff(b, 'seconds')
      return hhmmss(duration)
    }

    return {
      start,
      showDialog,
      stop,
      getTimePass
    }
  }
}

module.exports = BreakService
