/* global angular moment */

/** @ngInject */

function DateTimeFormatService ($rootScope) {
  const getAvailableDateFormats = function getAvailableDateFormats () {
    const nowDate = new Date()
    return [
      {
        value: 'YYYY-MM-DD',
        name: `YYYY-MM-DD (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('YYYY-MM-DD')})`
      },
      {
        value: 'MM/DD/YYYY',
        name: `MM/DD/YYYY (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('MM/DD/YYYY')})`
      },
      {
        value: 'DD/MM/YYYY',
        name: `DD/MM/YYYY (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('DD/MM/YYYY')})`
      },
      {
        value: 'DD-MM-YYYY',
        name: `DD-MM-YYYY (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('DD-MM-YYYY')})`
      },
      {
        value: 'MM-DD-YYYY',
        name: `MM-DD-YYYY (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('MM-DD-YYYY')})`
      },
      {
        value: 'DD MMM YYYY',
        name: `DD MMM YYYY (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('DD MMM YYYY')})`
      },
      {
        value: 'DD MMMM YYYY',
        name: `DD MMMM YYYY (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('DD MMMM YYYY')})`
      },
      {
        value: 'MMMM DD, YYYY',
        name: `MMMM DD, YYYY (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('MMMM DD, YYYY')})`
      },
      {
        value: 'YYYY/MM/DD',
        name: `YYYY/MM/DD (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('YYYY/MM/DD')})`
      }
    ]
  }
  const getAvailableTimeFormats = function getAvailableTimeFormats () {
    const nowDate = new Date()
    return [
      {
        value: 'HH:mm',
        name: `HH:mm (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('HH:mm')})`
      },
      {
        value: 'hh:mm A',
        name: `hh:mm A (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('hh:mm A')})`
      },
      {
        value: 'HH:mm:ss',
        name: `HH:mm:ss (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('HH:mm:ss')})`
      },
      {
        value: 'hh:mm:ss A',
        name: `hh:mm:ss A (${moment(nowDate)
          .locale($rootScope.currentLang)
          .format('hh:mm:ss A')})`
      }
    ]
  }

  const formatDateTime = function formatDateTime (dateTimeObject, type) {
    const dateTimeFormats = getDateTimeFormats()

    const dateFormat = dateTimeFormats.date
    const timeFormat = dateTimeFormats.time
    const date = moment(dateTimeObject)
      .locale($rootScope.currentLang)
      .format(dateFormat)
    const time = moment(dateTimeObject).locale('en').format(timeFormat)
    switch (type) {
      case 'date':
        return date
      case 'time':
        return time
      case 'dateTime':
        return `${date} ${time}`
      default:
        return ''
    }
  }

  const getDateTimeFormatsForSpreadsheet =
    function getDateTimeFormatsForSpreadsheet (editDialog = false) {
      let dateFormat = 'dd/MM/yyyy'
      let timeFormat = 'Hhh:mm'
      const dateTimeFormats = getDateTimeFormats()
      if (dateTimeFormats) {
        if (dateTimeFormats.date) {
          dateFormat = dateTimeFormats.date
        }
        if (dateTimeFormats.time) {
          timeFormat = dateTimeFormats.time
        }
        if (editDialog) {
          dateFormat = dateFormat.replaceAll('D', 'd').replaceAll('Y', 'y')
          timeFormat = timeFormat.replace('A', 'tt')
        } else {
          timeFormat = timeFormat.replace('A', 'AM/PM')
        }
        return {
          date: dateFormat,
          time: timeFormat
        }
      }
    }

  const getDateTimeFormatsForKendoGrid =
    function getDateTimeFormatsForKendoGrid (isFilter = false) {
      let dateFormat = 'YYYY-MM-DD'
      if (isFilter) {
        dateFormat = 'dd/MM/yyyy'
      }
      let timeFormat = 'HH:mm'
      const dateTimeFormats = getDateTimeFormats()
      if (dateTimeFormats) {
        if (dateTimeFormats.date) {
          dateFormat = isFilter
            ? dateTimeFormats.date.replaceAll('D', 'd').replaceAll('Y', 'y')
            : dateTimeFormats.date
        }
        if (dateTimeFormats.time) {
          timeFormat = dateTimeFormats.time
        }
      }
      return {
        date: dateFormat,
        time: timeFormat
      }
    }
  const getDateTimeFormatsForField = function getDateTimeFormatsForField () {
    let dateFormat = 'd/m/Y'
    let timeFormat = 'H:i'
    const dateTimeFormats = getDateTimeFormats()
    if (dateTimeFormats) {
      if (dateTimeFormats.date) {
        dateFormat = dateTimeFormats.date
          .replace('DD', 'd')
          .replace('MMMM', 'F')
          .replace('MMM', 'M')
          .replace('MM', 'm')
          .replace('YYYY', 'Y')
      }
      if (dateTimeFormats.time) {
        timeFormat = dateTimeFormats.time
          .replace('HH', 'H')
          .replace('hh', 'h')
          .replace('mm', 'i')
          .replace('A', 'K')
          .replace('ss', 'S')
      }
    }
    return {
      date: dateFormat,
      time: timeFormat
    }
  }

  const getDateTimeFormats = function getDateTimeFormats () {
    let dateFormat = null
    let timeFormat = null
    if ($rootScope?.currentUser) {
      dateFormat = $rootScope.currentUser.dateTimeFormats.date
      timeFormat = $rootScope.currentUser.dateTimeFormats.time
    } else if ($rootScope?.appSettings) {
      dateFormat = $rootScope.appSettings.dateTimeFormats.date
      timeFormat = $rootScope.appSettings.dateTimeFormats.time
    }
    return {
      date: dateFormat,
      time: timeFormat
    }
  }

  return {
    getAvailableDateFormats,
    getAvailableTimeFormats,
    formatDateTime,
    getDateTimeFormatsForSpreadsheet,
    getDateTimeFormatsForKendoGrid,
    getDateTimeFormatsForField
  }
}

module.exports = DateTimeFormatService
